import { createI18n } from 'vue-i18n'

const messages = {
  'en-gb': {
    promotions: {
      sidebarTitle: 'Applied Promotions',
      contentTitle: 'Add Promotion',
      sidebarPlaceholder: 'Add promotions on the right',
      remove: 'Remove',
      done: 'Done',
      searchInputPlaceholder: 'Start typing',
      searchCancel: 'Cancel',
      searchPlaceholder: 'Start typing and search results will appear here.',
      matchingPromotions: 'Matching Promotions',
      apply: 'Apply',
      applied: 'Applied',
    },
    inHouse: {
      mapMove: 'Move',
    },
  },
  bg: {
    promotions: {
      sidebarTitle: 'Applied Promotions',
      contentTitle: 'Add Promotion',
      sidebarPlaceholder: 'Add promotions on the right',
      remove: 'Remove',
      done: 'Done',
      searchInputPlaceholder: 'Start typing',
      searchCancel: 'Cancel',
      searchPlaceholder: 'Start typing and search results will appear here.',
      matchingPromotions: 'Matching Promotions',
      apply: 'Apply',
      applied: 'Applied',
    },
    inHouse: {
      mapMove: 'Переместить',
    },
  },
  ru: {
    promotions: {
      sidebarTitle: 'Applied Promotions',
      contentTitle: 'Add Promotion',
      sidebarPlaceholder: 'Add promotions on the right',
      remove: 'Remove',
      done: 'Done',
      searchInputPlaceholder: 'Start typing',
      searchCancel: 'Cancel',
      searchPlaceholder: 'Start typing and search results will appear here.',
      matchingPromotions: 'Matching Promotions',
      apply: 'Apply',
      applied: 'Applied',
    },
    inHouse: {
      mapMove: 'Переместить',
    },
  },
}

function customRule(choice: any, choicesLength: any, orgRule: any) {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return choicesLength < 4 ? 2 : 3
}

export const i18n = createI18n({
  locale: 'en-gb',
  fallbackLocale: 'en-gb',
  legacy: false,
  pluralizationRules: {
    ru: customRule,
    bg: customRule,
  },
  messages,
})

export default () => {
  return { provide: { i18n } }
}
