<template>
  <ion-app>
    <ion-router-outlet ref="rootElementRef" />
    <LoginModalLayout
      v-if="loginModalIsOpen"
      v-model:is-open="loginModalIsOpen"
      @login="onLogin"
    />
    <AppShiftModal v-model:is-open="shiftModalIsOpen" />
    <OrderModalLayout
      v-if="rootElementRef"
      :order-id="orderModalActiveOrderId"
      :object-id="orderModalActiveObjectId"
      :is-open="orderModalIsOpen"
      :presenting-element="rootElementRef.$el"
      @dismiss="onOrderModalDismiss"
      @update="onOrderModalUpdate"
    />
    <IonAlert
      :is-open="reloadAlertIsOpen"
      header="New version avaliable"
      message="Update to new version?"
      :buttons="alertButtons"
    />
  </ion-app>
</template>

<script setup lang="ts">
import {
  IonApp,
  IonRouterOutlet,
  useIonRouter,
  IonAlert,
  toastController,
} from '@ionic/vue'
import { useRoute } from 'vue-router'
import { useEventBus } from '@vueuse/core'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import { ref, computed, watch, onMounted, onDeactivated } from 'vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import usePreferredColorScheme from '@restify/packages/composables/usePreferredColorScheme'
import { useProvideAppUIState } from '@restify/packages/composables/useAppUiState'
import { useShiftsLogic } from '@restify/packages/composables/useShiftsLogic'
import { reloadStoredEntities as HelpersAppReloadStoredEntities } from '@restify/packages/helpers/app'
import {
  addEventListener as HelpersVisibilityAddEventListener,
  removeEventListener as HelpersVisibilityRemoveEventListener,
} from '@restify/packages/helpers/visibility'
import AppShiftModal from '~/components/AppShiftModal.vue'
import useFeathers from '~/composables/useFeathers'
import useStores, { type Stores } from '~/composables/useStores'
import LoginModalLayout from '~/views/LoginModalLayout.vue'
import OrderModalLayout from '~/views/OrderModalLayout.vue'
import { useAuthStore } from '~/stores/auth'
import { useAppStore } from '~/stores/app'

const { io } = useFeathers()
const { apps: AppsStore } = useStores()
const AppStore = useAppStore()
const AuthStore = useAuthStore()
const router = useIonRouter()
const route = useRoute()
const { shiftShouldBeStarted, shiftsSupported, shift, shiftIsHappening } =
  useShiftsLogic(AuthStore)

io.on('disconnect', function () {
  AppStore.setNetworkConnectionStatus('reconnecting')
  bus.emit('app-hidden')
})

io.on('reconnecting', function () {
  AppStore.setNetworkConnectionStatus('reconnecting')
})

io.on('connect', function () {
  AppStore.setNetworkConnectionStatus('connected')
  bus.emit('app-visible')
})

const bus = useEventBus<
  | 'order-tap'
  | 'table-tap'
  | 'order-modal-dismiss'
  | 'app-visible'
  | 'app-hidden',
  unknown
>('app')
const onVisibilityChangeHandler = (status: boolean) => {
  if (status) {
    bus.emit('app-hidden')
  } else if (AppStore.networkConnectionStatus === 'connected') {
    bus.emit('app-visible')
  }
}

bus.on(onAppEvent)

useProvideAppUIState()

HelpersVisibilityAddEventListener(onVisibilityChangeHandler)

const { needRefresh, updateServiceWorker } = useRegisterSW()

const alertButtons = [
  {
    text: 'Reload',
    role: 'confirm',
    handler: () => {
      return updateServiceWorker()
    },
  },
]

const appColorScheme = usePreferredColorScheme()

const authInProgress = ref(false)
const loginModalIsOpen = ref(false)
const shiftModalIsOpen = ref(false)
const reloadAlertIsOpen = ref(false)

const orderModalIsOpen = ref(false)
const orderModalActiveOrderId = ref<string | null>(null)
const orderModalActiveObjectId = ref<string | null>(null)
const rootElementRef = ref<HTMLElement | null>(null)

const mapIsEnabled = computed(() => {
  return AppStore.selectedStorefront.inHouseService.setup.map
})

const tabsIsEnabled = computed(() => {
  return AppStore.selectedStorefront.inHouseService.setup.tabs
})

const onLogin = () => {
  loginModalIsOpen.value = false
  AuthStore.lastSessionLogin = Date.now()

  router.replace({
    name: mapIsEnabled.value ? 'map' : tabsIsEnabled.value ? 'tabs' : 'user',
  })
}

const authenticate = async (data = {}) => {
  const urlToken = null
  const token = window.localStorage.getItem('accessToken') || urlToken

  if (token) {
    authInProgress.value = true

    await AuthStore.authenticate({
      strategy: 'jwt',
      accessToken: token,
      ...data,
    })

    authInProgress.value = false
  }
}

const reloadStoredEntities = () => {
  return HelpersAppReloadStoredEntities(AuthStore, AppsStore).catch(
    async (error) => {
      const toast = await toastController.create({
        message: `Error: ${error.name}. ${error.message}`,
        duration: 3000,
        cssClass: 'toast text-lg-7-semibold',
        position: 'bottom',
        mode: 'ios',
      })

      await toast.present()
    },
  )
}

function onAppEvent(
  event: 'order-tap' | 'table-tap' | 'order-modal-dismiss' | 'app-visible',
  payload: Stores['orders']['Result'] | unknown,
) {
  if (event === 'order-modal-dismiss') {
    return onOrderModalDismiss()
  }

  if (event === 'order-tap') {
    const order = payload as Stores['orders']['Result']

    orderModalIsOpen.value = true
    orderModalActiveOrderId.value = orderModalIsOpen.value ? order._id : null
  } else if (event === 'table-tap') {
    const {
      object: { object },
    } = payload as {
      object: { object: Stores['storefrontPlaces']['Result'] }
    }

    orderModalIsOpen.value = true
    orderModalActiveObjectId.value = object._id
    orderModalActiveOrderId.value = null
  } else if (event === 'app-visible') {
    reloadStoredEntities()
  }
}

const onOrderModalDismiss = () => {
  orderModalIsOpen.value = false
  orderModalActiveOrderId.value = null
  orderModalActiveObjectId.value = null
}

const onOrderModalUpdate = ({ orderId, objectId }) => {
  if (orderId) {
    orderModalActiveOrderId.value = orderId
  }

  if (objectId) {
    orderModalActiveObjectId.value = objectId
  }
}

watch(
  computed(() => appColorScheme.value),
  (newValue) => {
    if (!Capacitor.isNativePlatform()) return

    if (newValue === 'light') {
      StatusBar.setStyle({ style: Style.Light })
    } else {
      StatusBar.setStyle({ style: Style.Dark })
    }
  },
  { immediate: true },
)

watch(
  computed(() => AuthStore.isUserAuthenticated),
  (newValue) => {
    if (!newValue) {
      router.replace({ name: 'unauthorized' })
      loginModalIsOpen.value = true
    }
  },
)

watch(
  computed(() => {
    return (
      route.fullPath ||
      shift.value ||
      shiftIsHappening.value ||
      shiftShouldBeStarted.value ||
      shiftsSupported.value
    )
  }),
  () => {
    if (route.name === 'user' || String(route.name).includes('user')) return

    if (
      (shift.value && shiftShouldBeStarted.value) ||
      (shiftsSupported.value && !shift.value)
    ) {
      shiftModalIsOpen.value = true
    } else {
      shiftModalIsOpen.value = false
    }
  },
  {
    immediate: true,
  },
)

watch(
  computed(() => needRefresh.value),
  (newVal) => newVal && (reloadAlertIsOpen.value = true),
  { immediate: true },
)

onMounted(() => {
  return authenticate().then(() => {
    if (!AuthStore.isUserAuthenticated) {
      loginModalIsOpen.value = true
    } else {
      onLogin()
    }
  })
})

onDeactivated(() =>
  HelpersVisibilityRemoveEventListener(onVisibilityChangeHandler),
)
</script>
