<template>
  <ion-list
    ref="listRef"
    v-on-click-outside="onClickOutside"
    :style="{
      '--ion-item-background': 'var(--rf-bg-elevation-2)',
    }"
  >
    <IonItemSliding
      v-for="(item, index) in props.items"
      :key="item._id"
      :ref="(data) => setItemRef(data, item._id)"
    >
      <IonItem
        :style="{
          '--background': 'var(--rf-bg-elevation-2)',
          '--border-color': 'var(--rf-border-elevation-2-secondary)',
        }"
        lines="inset"
        class="item"
      >
        <div class="w-full">
          <OrderModalHomeMenuitemsListItem
            :title="item.title"
            :subtitle="item.subtitle"
            :comment="item.comment"
            :variations="item.variations"
            :extras="item.extras"
            :removables="item.removables"
            :status="item.status"
            class="w-full"
            @click="emit('tap', item._id)"
            @double-click="onDoubleClick(item, $event)"
          />
        </div>
      </IonItem>
      <IonItemOptions
        v-if="props.quantityChangable"
        side="start"
        :style="{
          'border-bottom-color': 'var(--rf-border-elevation-2-secondary)',
        }"
        @ionSwipe="onFullSwipe(item, $event)"
      >
        <template v-if="props.quantityChangable">
          <IonItemOption
            class="bg-bg-elevation-2 relative"
            @click="emit('decrease-quantity', index)"
          >
            <div
              class="bg-bg-blue absolute top-0 left-0 w-full h-full opacity-20"
            />
            <AppIcon
              icon="minus"
              stroke-width="1.8"
              color="text-fg-blue"
              class="w-[24px] h-[24px] relative pointer-events-none"
            />
          </IonItemOption>
          <IonItemOption
            class="bg-bg-blue"
            @click="emit('increase-quantity', index)"
          >
            <AppIcon
              icon="plus"
              stroke-width="1.8"
              color="text-universal-fg-opposite"
              class="w-[24px] h-[24px] pointer-events-none"
            />
          </IonItemOption>
        </template>
        <IonItemOption
          v-if="props.quantityChangable"
          class="bg-bg-elevation-2 px-2"
          @click="emit('add-comment', index)"
        >
          <AppIcon
            icon="message-text"
            stroke-width="1.8"
            color="text-fg-elevation-2-secondary"
            class="w-[22px] h-[22px] pointer-events-none relative"
          />
        </IonItemOption>
        <!-- <IonItemOption
          v-if="isStatusChangable(item)"
          class="bg-bg-blue"
          expandable
          @click="onStatusChange(item, $event)"
        >
          <AppIcon
            icon="check"
            stroke-width="2"
            color="text-fg-white"
            class="w-[22px] h-[22px] pointer-events-none relative"
          />
        </IonItemOption> -->
      </IonItemOptions>
      <IonItemOptions
        v-if="props.deletable"
        side="end"
        :style="{
          'border-bottom-color':
            props.items.length === index + 1
              ? 'transparent'
              : 'var(--rf-border-elevation-2-secondary)',
        }"
      >
        <IonItemOption class="bg-bg-red" @click="onDelete(item, $event)">
          <AppIcon
            icon="bin-minus"
            stroke-width="1.8"
            color="text-universal-fg-opposite"
            class="w-[24px] h-[24px] pointer-events-none"
          />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  </ion-list>
</template>

<script setup lang="ts">
import {
  IonList,
  IonItemSliding,
  IonItem,
  IonItemOption,
  IonItemOptions,
} from '@ionic/vue'
import { ref, type PropType } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import OrderModalHomeMenuitemsListItem from './OrderModalHomeMenuitemsListItem.vue'

const emit = defineEmits([
  'increase-quantity',
  'decrease-quantity',
  'add-comment',
  'status-change',
  'delete',
  'tap',
])
const props = defineProps({
  items: {
    type: Array as PropType<any>,
    default: () => [],
  },
  quantityChangable: {
    type: Boolean,
    default: false,
  },
  statusChangable: {
    type: Boolean,
    default: false,
  },
  deletable: {
    type: Boolean,
    default: false,
  },
})

const listRef = ref(null)

const itemsRefs = ref({} as { [itemId: string]: any })
const setItemRef = (el: any, _id: string) => {
  if (el && _id) {
    itemsRefs.value[_id] = el
  }
}

const onDoubleClick = (item, $event) => {
  return isStatusChangable(item) ? onStatusChange(item, $event) : null
}

const isStatusChangable = (item) => {
  return props.statusChangable && item.status === 'ready'
}

const onDelete = (item: any, $event) => {
  if (!props.deletable) return

  const ionItem =
    $event.target.parentElement.parentElement.querySelector('.item')

  if (!ionItem) return

  ionItem.style.height = `${ionItem.clientHeight}px`
  ionItem.style.transition = 'height 200ms cubic-bezier(.68,.56,0,1)'
  getComputedStyle(ionItem).height

  requestAnimationFrame(() => {
    ionItem.style.height = '0px'

    setTimeout(() => emit('delete', item._id), 200)
  })
}

const onStatusChange = (item: any, $event: any) => {
  if (!isStatusChangable(item)) return

  emit('status-change', {
    status: 'served',
    id: item._id,
  })

  listRef.value?.$el?.closeSlidingItems()
}

const onClickOutside = () => {
  if (!props.quantityChangable && !props.deletable && props.statusChangable) {
    return
  }

  return listRef.value?.$el?.closeSlidingItems()
}

const onFullSwipe = (item: any, $event) => {
  return onStatusChange(item, $event)
}
</script>

<style scoped>
.item {
  overflow: hidden;
  will-change: height, transform;
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
