<template>
  <button
    :class="rootClasses"
    class="py-[15px] px-4 relative rounded-xl overflow-hidden"
  >
    <div :class="bgClasses" class="absolute top-0 left-0 w-full h-full" />
    <slot>
      <AppTypography
        :text="props.text"
        :color="textColor"
        name="text-base-6-semibold"
        class="relative"
      />
    </slot>
    <AppFadeTransition duration="duration-200">
      <div
        v-if="props.loading"
        :class="bgClasses"
        class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
      >
        <AppSpinner variant="fill-white" class="w-7 h-7" />
      </div>
    </AppFadeTransition>
  </button>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppSpinner from '@restify/packages/design-system/low-level/AppSpinner.vue'
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'

const borderSecondaryColors = {
  0: ['border-border-elevation-0-secondary'],
  1: ['border-border-elevation-1-secondary'],
  2: ['border-border-elevation-2-secondary'],
  3: ['border-border-elevation-3-secondary'],
}

const bgPrimaryColors = {
  0: ['bg-bg-elevation-0'],
  1: ['bg-bg-elevation-1'],
  2: ['bg-bg-elevation-2'],
  3: ['bg-bg-elevation-3'],
}

const bgAltColors = {
  0: ['bg-bg-elevation-0-alt'],
  1: ['bg-bg-elevation-1-alt'],
  2: ['bg-bg-elevation-2-alt'],
  3: ['bg-bg-elevation-3-alt'],
}

const textPrimaryColors = {
  0: 'text-fg-elevation-0-primary',
  1: 'text-fg-elevation-1-primary',
  2: 'text-fg-elevation-2-primary',
  3: 'text-fg-elevation-3-primary',
}

const textSecondaryColors = {
  0: 'text-fg-elevation-0-secondary',
  1: 'text-fg-elevation-1-secondary',
  2: 'text-fg-elevation-2-secondary',
  3: 'text-fg-elevation-3-secondary',
}

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
  elevation: {
    type: String as PropType<'0' | '1' | '2' | '3'>,
    default: '2',
  },
  variant: {
    type: String as PropType<
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'outline-primary'
      | 'outline-secondary'
      | 'outline-destructive'
    >,
    default: 'primary',
  },
})

const rootClasses = computed(() => {
  return [
    ...(props.loading || props.disabled ? ['pointer-events-none'] : []),
    ...{
      primary: ['border-0'],
      secondary: ['border-0'],
      tertiary: ['border-0'],
      'outline-primary': [
        'border',
        'border-solid',
        ...borderSecondaryColors[props.elevation],
      ],
      'outline-secondary': [
        'border',
        'border-solid',
        ...borderSecondaryColors[props.elevation],
      ],
      'outline-destructive': [
        'border',
        'border-solid',
        ...borderSecondaryColors[props.elevation],
      ],
    }[props.variant],
  ]
})

const bgClasses = computed(() => {
  return [
    ...{
      primary: props.disabled ? [bgAltColors[props.elevation]] : ['bg-bg-blue'],
      secondary: [bgAltColors[props.elevation]],
      tertiary: props.disabled
        ? [bgAltColors[props.elevation]]
        : [bgPrimaryColors[props.elevation]],
      'outline-primary': props.disabled
        ? [bgAltColors[props.elevation]]
        : [bgPrimaryColors[props.elevation]],
      'outline-secondary': props.disabled
        ? [bgAltColors[props.elevation]]
        : [bgPrimaryColors[props.elevation]],
      'outline-destructive': props.disabled
        ? [bgAltColors[props.elevation]]
        : [bgPrimaryColors[props.elevation]],
    }[props.variant],
  ]
})

const textColor = computed(() => {
  return {
    primary: props.disabled
      ? textSecondaryColors[props.elevation]
      : 'text-universal-fg-opposite',
    secondary: props.disabled
      ? textSecondaryColors[props.elevation]
      : textPrimaryColors[props.elevation],
    tertiary: props.disabled
      ? textSecondaryColors[props.elevation]
      : textSecondaryColors[props.elevation],
    'outline-primary': props.disabled
      ? textSecondaryColors[props.elevation]
      : 'text-universal-fg-blue',
    'outline-secondary': props.disabled
      ? textSecondaryColors[props.elevation]
      : textPrimaryColors[props.elevation],
    'outline-destructive': props.disabled
      ? textSecondaryColors[props.elevation]
      : 'text-universal-fg-red',
  }[props.variant]
})
</script>
