import { createRouter, createMemoryHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import TabsLayout from '~/views/TabsLayout.vue'
// import ToGoPage from '../views/ToGoPage.vue'
// import ScanPage from '../views/ScanPage.vue'
import { useAuthStore } from '~/stores/auth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'index',
    redirect: '/tabs/unauthorized',
  },
  {
    path: '/tabs/',
    component: TabsLayout,
    children: [
      {
        path: '',
        redirect: '/tabs/map',
      },
      {
        path: 'unauthorized',
        name: 'unauthorized',
        component: () => import('../views/UnaithorizedPage.vue'),
      },
      {
        path: 'map',
        name: 'map',
        component: () => import('../views/MapPage.vue'),
      },
      {
        path: 'tabs',
        name: 'tabs',
        component: () => import('../views/TabsPage.vue'),
      },
      {
        path: 'in-house',
        name: 'in-house',
        component: () => import('../views/InHousePage.vue'),
      },
      // {
      //   path: 'to-go',
      //   name: 'to-go',
      //   component: ToGoPage,
      // },
      // {
      //   path: 'scan',
      //   name: 'scan',
      //   component: ScanPage,
      // },
      {
        path: 'user',
        name: 'user',
        component: () => import('../views/UserPage.vue'),
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/DashboardPage.vue'),
      },
      {
        path: 'dashboard/shift/:id',
        name: 'dashboard-shift-id',
        component: () => import('../views/DashboardShiftPage.vue'),
      },
      {
        path: 'user/storefront-select',
        name: 'user-storefront-select',
        component: () => import('../views/UserStorefrontSelectPage.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createMemoryHistory('/'),
  routes,
})

router.beforeEach((to, from, next) => {
  const isUserAuthenticated = useAuthStore().isUserAuthenticated

  if (to.name !== 'unauthorized' && !isUserAuthenticated) {
    return next({ name: 'unauthorized' })
  }

  next()
})

export default router
