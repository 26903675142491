// @ts-nocheck

import { onMounted, onDeactivated, ref, Ref } from 'vue'

export function useBottomScroll(
  scrollview: Ref<null | unknown>,
  scrollviewContent: Ref<null | unknown>,
) {
  const observer = ref(null)

  const scrollToBottom = () => {
    if (!scrollview.value) return

    if (scrollview.value?.$el.tagName === 'ION-CONTENT') {
      return scrollview.value?.$el.scrollToBottom(300)
    }

    return scrollview.value?.$el.scroll({
      top: scrollview.value?.$el.scrollHeight,
      // behavior: 'smooth'
    })
  }

  onMounted(() => {
    if (!scrollview.value || !scrollviewContent.value?.$el) return

    observer.value = new ResizeObserver((value) => {
      return scrollToBottom()
    })

    if (!observer.value) return

    observer.value.observe(scrollviewContent.value.$el)
  })

  onDeactivated(() => {
    if (scrollviewContent.value?.$el) {
      observer.value.unobserve(el)
    } else {
      observer.value.disconnect()
    }
  })
}
