import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as setupActions from './setup/index'

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '~/assets/css/tailwind.css'
import '~/assets/css/app.css'
import '@restify/packages/design-system/css/colors.css'
import '@restify/packages/design-system/css/shadows.css'
import '@restify/packages/design-system/css/typography.css'
import '@restify/packages/design-system/css/fonts.css'

import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

const app = createApp(App)
  .use(IonicVue, {
    mode: 'ios',
    swipeBackEnabled: true,
  })
  .use(router)

Object.values(setupActions).forEach((action) => {
  const result = action()

  if (result?.provide) {
    Object.values(result.provide).forEach(app.use)
  }
})

router.isReady().then(() => {
  app.mount('#app')
})
