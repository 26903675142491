<template>
  <ion-header>
    <ion-toolbar :style="contentStyles">
      <ion-buttons slot="start">
        <ion-back-button />
      </ion-buttons>
      <ion-title>
        <span>Menus</span>
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content ref="scrollview" :style="contentStyles">
    <AppStack ref="scrollviewContent" direction="flex-col" class="min-h-full">
      <AppStack
        direction="flex-col"
        flex="flex-1"
        justify-content="justify-end"
      >
        <OrderModalMenuItemsCategories
          v-if="selectedCategoryId === null"
          :categories="Object.values(categories)"
          :loading="loading"
          :order="order"
          class="w-full"
          @category-tap="onCategoryTap"
        />
        <OrderModalMenuItemsCategory
          v-else
          :menu-items="selectedCategory.menuItems"
          :loading="loading"
          :order="order"
          @menu-item-tap="onMenuItemTap"
          @configurator-opened="onConfiguratorOpened"
          @configurator-closed="onConfiguratorClosed"
        />
      </AppStack>
      <div class="safe-bottom-max">
        <div class="h-[116.5px]" />
      </div>
    </AppStack>
    <div
      class="fixed w-full bottom-0 z-10 bg-bg-elevation-2 border-border-elevation-2-primary
        border-x-0 border-t-[0.5px] border-solid border-b-0 safe-bottom-max"
    >
      <AppHeaderTabs
        v-if="!selectedCategoryId || menuOptions.length"
        ref="filterRef"
        :active-tab="selectedMenuId"
        :tabs="menuOptions"
        class="flex pl-2 pr-2 pt-4"
        @update:active-tab="onSelectMenu"
      />
      <AppStack direction="flex-col" class="px-4 pt-4">
        <AppButton
          :text="selectedCategoryId === null ? 'To Batch' : 'To Menu'"
          variant="secondary"
          @click="selectedCategoryId === null ? pop() : onBackTap()"
        />
      </AppStack>
    </div>
  </ion-content>
</template>

<script lang="ts">
export default { name: 'OrderModalMenuItems' }
</script>

<script setup lang="ts">
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
} from '@ionic/vue'
import { ref, computed, inject, ComputedRef, onMounted } from 'vue'
import { useEventBus } from '@vueuse/core'
import { useIonicNavigation } from '@restify/packages/composables/useIonicNavigation'
import useReactivePagination from '@restify/packages/composables/useReactivePaginationFactory'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import useAppHelpers from '~/composables/useAppHelpers'
import useStores, { type Stores } from '~/composables/useStores'
import { useAppStore } from '~/stores/app'
import AppButton from '~/components/AppButton.vue'
import AppHeaderTabs from '~/components/AppHeaderTabs.vue'
import OrderModalMenuItemsCategories from './OrderModalMenuItemsCategories.vue'
import OrderModalMenuItemsCategory from './OrderModalMenuItemsCategory.vue'

const injectedOrderId = inject<ComputedRef<string>>('orderId')
const { pop } = useIonicNavigation('order-modal-nav')
const {
  orders: OrdersStore,
  menuItems: MenuItemsStore,
  menus: MenusStore,
  storefronts: StorefrontsStore,
} = useStores()
const AppStore = useAppStore()
const AppBus = useEventBus<string>('app')
const bus = useEventBus<string>('batch')
const { translateField } = useAppHelpers()

const scrollview = ref(null)
const scrollviewContent = ref(null)

const loading = ref(false)
const selectedCategoryId = ref<string | null>(null)
const selectedMenuId = ref<string | null>(null)

const { find: findMenus } = useReactivePagination(
  MenusStore,
  `${AppStore.selectedStorefront._id}-catalog`,
  {
    status: 'public',
    $skip: 0,
    $limit: 500,
  },
  {
    store: MenuItemsStore,
    query: (menus) => {
      return {
        _id: {
          $in: [
            ...new Set([
              ...menus.reduce((acc: string[], menu) => {
                acc.push(
                  ...(menu.value?.menuItems?.map(
                    ({ menuItemId }) => menuItemId,
                  ) || []),
                )

                return acc
              }, []),
            ]),
          ],
        },
        $limit: 500,
      }
    },
  },
)

const menuOptions = computed(() => [
  ...menus.value.map((menu) => ({
    name: translateField(menu?.name || []),
    value: menu?._id,
  })),
])

const contentStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2-breaker)',
  '--border-color': 'var(--rf-border-elevation-0-primary)',
}))

const order = computed(() => {
  if (!injectedOrderId?.value) return null

  return OrdersStore.getFromStore(injectedOrderId.value).value || null
})

const storefront = computed(() => {
  if (!AppStore.selectedStorefrontId) return null

  return StorefrontsStore.getFromStore(AppStore.selectedStorefrontId).value
})

const menuIds = computed(() => {
  if (!storefront.value) return []

  return [...(storefront.value?.inHouseService?.menuIds || [])]
})

const menuItemIds = computed(() => {
  if (!storefront.value || !menuIds.value.length || !menus.value.length) {
    return []
  }

  return [
    ...new Set([
      ...menus.value.reduce((acc: string[], menu) => {
        acc.push(...menu.menuItems.map(({ menuItemId }) => menuItemId))

        return acc
      }, []),
    ]),
  ]
})

const selectedMenu = computed(() => {
  if (!selectedMenuId.value) return null

  return menus.value.find((menu) => menu._id === selectedMenuId.value)
})

const menus = computed(() => {
  if (!menuIds.value.length) return []

  return MenusStore.findInStore({
    _id: { $in: menuIds.value },
    status: 'public',
  }).map((item) => item.value)
})

const categories = computed(() => {
  const menu = selectedMenu.value

  if (!menu) return []

  const mappedCategories = menu.categories.reduce((acc, category) => {
    acc[category.id] = {
      id: category.id,
      title: translateField(category.name),
      menuItems: [],
    }

    return acc
  }, {})
  const mappedMenuItems = menu.menuItems.reduce((acc, item) => {
    const menuItem = menuItems.value[item.menuItemId]
    const category = mappedCategories[item.categoryId]

    if (menuItem) {
      category.menuItems.push(menuItem)
    }

    return mappedCategories
  }, {})
  return mappedMenuItems
})

const selectedCategory = computed(() => {
  return categories.value[selectedCategoryId.value]
})

const menuItems = computed(() => {
  if (!menuItemIds.value.length) return []

  return MenuItemsStore.findInStore({
    _id: { $in: menuItemIds.value },
    $limit: 500,
  }).reduce((acc: Record<string, Stores['menuItems']['Result']>, item) => {
    acc[item.value._id] = item.value

    return acc
  }, {})
})

const onSelectMenu = (value) => {
  selectedCategoryId.value = null
  selectedMenuId.value = value
}

const onCategoryTap = (categoryId: string) => {
  selectedCategoryId.value = categoryId
}

const onBackTap = () => {
  selectedCategoryId.value = null
}

const onMenuItemTap = (payload: {
  menuItem: Stores['menuItems']['Result']
  onClick: () => void
}) => {
  bus.emit('batch-add', payload)

  payload.onClick()
}

const init = (silent = false, force = false) => {
  loading.value = silent ? false : true

  return findMenus(force)
    .then(() => {
      selectedMenuId.value = menus.value[0]._id
    })
    .finally(() => (loading.value = false))
}

const onConfiguratorOpened = ($event) => {
  $event?.scrollIntoView({ behavior: 'smooth' })
}

const onConfiguratorClosed = () => {
  scrollview.value?.$el?.scrollToTop(200)
}

AppBus.on((event) => {
  if (event === 'app-visible') {
    return init()
  }
})

onMounted(() => {
  return init()
})
</script>
