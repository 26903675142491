<template>
  <div>
    <AppStack
      direction="flex-col"
      class="h-full px-4 py-4 bg-bg-elevation-0-breaker gap-3 border-t border-x-0 border-b-0
        border-border-elevation-0-primary"
    >
      <AppStack class="gap-1">
        <AppTypography
          :text="props.title"
          name="text-md-4-semibold"
          color="text-fg-elevation-2-primary"
        />
        <AppTypography
          :text="`${props.currentStep}/${props.totalSteps}`"
          name="text-md-4-medium"
          color="text-fg-elevation-2-tertiary"
        />
      </AppStack>
      <AppStack class="gap-[6px]">
        <OrderModalMenuItemsItemCell
          v-for="(option, optionKey) in props.options"
          :key="optionKey"
          :title="translateField(option.name)"
          :subtitle="`${formatPrice(option.price)}`"
          class="flex-1 min-w-[32%]"
          @menu-item-tap="
            emit('menu-item-option-tap', {
              option,
              onClick: $event,
            })
          "
        />
        <div
          v-for="ghost in 3 - (options.length % 3)"
          :key="ghost"
          class="flex-1 min-w-[32%]"
        />
      </AppStack>
    </AppStack>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import OrderModalMenuItemsItemCell from './OrderModalMenuItemsItemCell.vue'
import { type Stores } from '~/composables/useStores'
import useAppHelpers from '~/composables/useAppHelpers'

const emit = defineEmits(['menu-item-option-tap'])
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  currentStep: {
    type: Number,
    required: true,
  },
  totalSteps: {
    type: Number,
    required: true,
  },
  options: {
    type: Array as PropType<
      Array<
        | Stores['menuItems']['Result']['variations'][0]['options'][0]
        | Stores['menuItems']['Result']['extras'][0]['options'][0]
      >
    >,
    default: () => [],
  },
})

const { translateField, formatPrice } = useAppHelpers()
</script>
