// @ts-nocheck

import { hashString } from './hash'
import useForcePagination from '../composables/useForcePagination'
import useStores from '~/composables/useStores'

export const reloadStoredEntities = async (AuthStore, AppsStore) => {
  const {
    menuItems,
    menus,
    orders,
    promotions,
    shifts,
    storefrontPlaces,
    storefronts,
    users,
  } = useStores()
  const stores = {
    apps: AppsStore,
    menuItems,
    menus,
    orders,
    promotions,
    shifts,
    storefrontPlaces,
    storefronts,
    users,
  }

  const promises = [
    ...(AuthStore?.reactiveUser
      ? [
          stores.users.get(AuthStore.reactiveUser._id),
          stores.shifts.find({
            query: {
              assignedStaffIds: AuthStore.reactiveUser._id,
              $or: [
                {
                  startsAt: {
                    $lte: new Date().getTime(),
                  },
                  endsAt: {
                    $gte: new Date().getTime(),
                  },
                },
                {
                  startsAt: {
                    $gte: new Date().getTime(),
                  },
                },
              ],
              $sort: { startsAt: 1 },
            },
          }),
          stores.storefronts.find({
            query: {
              appId: AuthStore?.reactiveUser.appId,
              $sort: { createdAt: -1 },
            },
          }),
        ]
      : []),
    ...Object.keys(stores).reduce((acc, storeKey) => {
      const ids = { ...stores[storeKey].itemsById }
      const idsAsKeys = Object.keys(ids)
      const params = {
        query: {
          _id: { $in: idsAsKeys },
          $limit: 200,
        },
      }

      if (idsAsKeys.length) {
        const promise = stores[storeKey]
          .find(params)
          .then((response) =>
            useForcePagination(stores[storeKey], params, response),
          )
          .then((items) => {
            const idsInStore = { ...ids }
            const idsInRequest = items.data.map((item) => item.value._id)

            idsInRequest.forEach((id) => {
              if (id in idsInStore) {
                delete idsInStore[id]
              }
            })

            if (Object.keys(idsInStore).length) {
              stores[storeKey].removeFromStore(
                Object.keys(idsInStore).reduce(
                  (acc, _id) => {
                    acc._id.$in.push(_id)

                    return acc
                  },
                  {
                    _id: {
                      $in: [],
                    },
                  },
                ),
              )
            }

            return items
          })

        acc.push(promise)
      }

      return acc
    }, []),
  ]

  return Promise.all(promises)
}
