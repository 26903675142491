import { type ClientService } from '@feathersjs/feathers'
import {
  serviceStores,
  type ServiceStores,
} from '@restify/packages/composables/useStore'
import useFeathers from './useFeathers'

const feathersClient = useFeathers()

export interface ServiceTypes
  extends Record<keyof ServiceStores, ClientService> {
  ['auth-management']: ClientService
}

let stores: null | ReturnType<typeof serviceStores> = null
export type Stores = ReturnType<typeof serviceStores>

export default () => {
  if (stores === null) stores = serviceStores(feathersClient)

  return stores
}
