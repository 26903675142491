<template>
  <AppStack
    :class="props.faded ? ['opacity-50'] : []"
    direction="flex-col"
    class="relative gap-2 bg-bg-elevation-2 px-3 pt-[10px] pb-[10px] rounded-[4px]
      shadow-elevation-1-0-xs group overflow-hidden transition-opacity duration-200
      ease-in-out"
    @click="emit('menu-item-tap', { onClick })"
  >
    <div
      :class="
        props.selected
          ? ['border-2', 'border-border-blue', 'border-solid']
          : ['opacity-0']
      "
      class="absolute top-0 left-0 w-full h-full rounded-[4px] transition-opacity
        duration-200 ease-in-out"
    />
    <div
      class="absolute top-0 left-0 w-full h-full bg-bg-elevation-2-alt opacity-0
        group-active:opacity-50"
    />
    <AppTypography
      :text="props.title"
      color="text-fg-elevation-2-primary"
      name="text-md-4-semibold"
      class="relative line-clamp-3 !tracking-tighter h-[50px]"
    />
    <AppTypography
      :text="props.subtitle"
      color="text-fg-elevation-2-secondary"
      name="text-md-4-medium"
      class="relative mt-auto"
    />
    <transition
      enter-active-class="duration-500 ease-in-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-500 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0"
    >
      <AppStack
        v-if="adding"
        direction="flex-col"
        align-items="items-center"
        justify-items="justify-center"
        class="absolute top-0 left-0 w-full h-full pointer-events-none"
      >
        <div class="absolute top-0 left-0 w-full h-full bg-bg-elevation-2" />
        <AppIcon
          size="custom"
          icon="check"
          stroke-width="2px"
          color="text-fg-blue"
          class="w-[22px] h-[22px] relative"
        />
        <AppTypography
          text="Added"
          color="text-fg-elevation-2-primary"
          name="text-sm-5-semibold"
          class="relative"
        />
      </AppStack>
    </transition>
    <div
      class="absolute top-0 left-0 w-full h-full opacity-0"
      @contextmenu="($event) => $event.preventDefault()"
    />
  </AppStack>
</template>

<script setup lang="ts">
import { alertController } from '@ionic/vue'
import { ref } from 'vue'
import { vOnLongPress } from '@vueuse/components'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const emit = defineEmits(['menu-item-tap'])
const props = defineProps({
  added: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  faded: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
})

const adding = ref(false)

const onMenuItemLongTap = async ($event) => {
  const alert = await alertController.create({
    header: 'Enter your comment',
    backdropDismiss: false,
    buttons: [
      {
        text: 'Cancel',
        handler: (value) => {
          console.log('cancel')
        },
      },
      {
        text: 'Add',
        handler: (value) => {
          emit('menu-item-tap', {
            onClick,
            comment: value[0],
          })
          console.log('added', value[0])
        },
      },
    ],
    inputs: [
      {
        type: 'textarea',
        placeholder: 'Enter text here',
        value: '',
      },
    ],
  })

  await alert.present().then(() => {
    document.querySelector('ion-alert textarea')?.focus()
  })
}

const onClick = () => {
  adding.value = true

  setTimeout(() => (adding.value = false), 1000)
}
</script>
