<template>
  <ion-modal :is-open="props.isOpen" animated>
    <ion-content>
      <AppLogin
        class="bg-bg-elevation-0 fixed top-0 left-0 w-full h-full z-10"
        @login="emit('login', $event)"
      />
    </ion-content>
  </ion-modal>
</template>

<script setup lang="ts">
import { IonContent, IonModal } from '@ionic/vue'
import { PropType } from 'vue'
import AppLogin from '~/components/AppLogin/index.vue'

const emit = defineEmits(['update:isOpen', 'login'])
const props = defineProps({
  presentingElement: {
    type: Object as PropType<HTMLElement | null>,
    default: null,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
})
</script>
