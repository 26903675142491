import { ref, computed, watch, onBeforeUnmount, type Ref } from 'vue'
import LodashOmit from 'lodash.omit'
import LodashDebounce from 'lodash.debounce'
import { useNotification } from '@restify/packages/design-system/low-level/AppNotification/useNotification'
import { type ServiceStores } from '@restify/packages/composables/useStore'
import { useRequestsStore, getPageId } from '../stores/requests'

export default function useReactivePagination<
  Store extends ServiceStores[keyof ServiceStores],
>(
  store: Store,
  requestId: string,
  initialQuery: Store['Query'] & {
    $limit: number
    $skip: number
  },
  populate?:
    | {
        store: ServiceStores[keyof ServiceStores]
        query: (result: Awaited<ReturnType<Store['find']>>['data']) => any
      }
    | {
        store: ServiceStores[keyof ServiceStores]
        query: (result: Awaited<ReturnType<Store['find']>>['data']) => any
      }[],
  noEvents?: boolean,
) {
  type Query = Store['Query'] & {
    $limit: number
    $skip: number
  }

  const incrementQueryPage = (query: Query): Query => {
    return {
      ...query,
      $limit: query.$limit,
      $skip: query.$skip + query.$limit,
    }
  }
  const decrementQueryPage = (query: Query): Query => {
    const newSkip = query.$skip - query.$limit

    return {
      ...query,
      $limit: query.$limit,
      $skip: newSkip < 0 ? 0 : newSkip,
    }
  }

  const RequestsStore = useRequestsStore()
  const { notifyWarning } = useNotification()

  const requestInProgress = ref(false)
  const mutableQuery: Ref<Query> = ref({ ...initialQuery })

  const requestInProgressComputed = computed(() => requestInProgress.value)
  const storedQuery = computed(() =>
    RequestsStore.getQuery(requestId, mutableQuery.value),
  )
  const total = computed(() => storedQuery.value?.total || 0)
  const storedPage = computed(() =>
    RequestsStore.getPage(requestId, mutableQuery.value),
  )
  const storedPages = computed(() =>
    RequestsStore.getPages(requestId, mutableQuery.value),
  )

  const pagesAreNonConsecutive = computed(() =>
    RequestsStore.requestPagesAreNonConsecutive(requestId, mutableQuery.value),
  )

  const allItems = computed(() => {
    const firstPageExists = RequestsStore.getPage(requestId, {
      ...mutableQuery.value,
      $skip: 0,
    })

    if (!firstPageExists) {
      return {
        data: [],
        limit: mutableQuery.value.$limit,
        skip: mutableQuery.value.$skip,
        total: 0,
      }
    }

    // @ts-ignore
    const result = store.findInStore({
      ...LodashOmit(mutableQuery.value, '$limit', '$skip'),
    })

    return {
      data: result,
      limit: mutableQuery.value.$limit,
      skip: mutableQuery.value.$skip,
      total: result.length,
    }
  })

  const pageItems = computed(() => {
    const pagesAreNonConsecutive = RequestsStore.requestPagesAreNonConsecutive(
      requestId,
      mutableQuery.value,
    )

    if (!storedPage.value) {
      return {
        data: [],
        limit: mutableQuery.value.$limit,
        skip: mutableQuery.value.$skip,
        total: 0,
      }
    }

    // If we have a stored page and is not first page
    // return items mapped on ids in request response
    if (pagesAreNonConsecutive) {
      // @ts-ignore
      const result = store.findInStore({
        // @ts-ignore
        _id: { $in: storedPage.value.ids },
        ...LodashOmit(mutableQuery.value, '$limit', '$skip'),
      })

      return {
        data: result,
        limit: mutableQuery.value.$limit,
        skip: mutableQuery.value.$skip,
        total: storedQuery.value?.total || result.length,
      }
    }

    // No skip, first page, normal find
    // @ts-ignore
    const result = store.findInStore({
      ...mutableQuery.value,
    })

    return {
      data: result,
      limit: mutableQuery.value.$limit,
      skip: mutableQuery.value.$skip,
      total: storedQuery.value?.total || result.length,
    }
  })
  const nextPageAvailable = computed(() => {
    return !!(
      storedQuery.value &&
      storedQuery.value.total >
        mutableQuery.value.$skip + mutableQuery.value.$limit
    )
  })
  const previousPageAvailable = computed(() => {
    return mutableQuery.value.$skip > 0
  })
  const nextPageDataAvailable = computed(() => {
    const incrementedQuery = incrementQueryPage({ ...mutableQuery.value })

    return !!(
      storedQuery.value &&
      nextPageAvailable.value &&
      !RequestsStore.getPage(requestId, incrementedQuery)
    )
  })
  const previousPageDataAvailable = computed(() => {
    const decrementedQuery = decrementQueryPage(mutableQuery.value)

    return (
      mutableQuery.value.$skip > 0 &&
      !RequestsStore.getPage(requestId, decrementedQuery)
    )
  })
  const pageCount = computed(() => {
    return storedQuery.value
      ? Math.ceil(storedQuery.value.total / mutableQuery.value.$limit)
      : 0
  })
  const currentPage = computed(() => {
    return Math.ceil(mutableQuery.value.$skip / mutableQuery.value.$limit + 1)
  })

  const reactToEvent =
    (action: 'created' | 'removed') => (record: Store['Result']) => {
      if (pagesAreNonConsecutive.value) {
        // Invalidate requests for all other pages
        RequestsStore.removeAllRequestPagesButCurrent(
          requestId,
          mutableQuery.value,
        )

        // Refresh current page
        find(true)
      } else if (action == 'created' && !pagesAreNonConsecutive.value) {
        // TODO: populate records once populate is supported
        populateRecord({
          data: [ref(record)],
          total: 1,
          limit: mutableQuery.value.$limit,
          skip: mutableQuery.value.$skip,
        } as Awaited<ReturnType<Store['find']>>)
      } else if (action === 'removed' && !pagesAreNonConsecutive.value) {
        // Find last page in query
        const pages = storedPages.value

        // No pages, something is off
        if (!pages) return

        const currentPageId = getPageId(mutableQuery.value)
        const lastPageId = getPageId(pages[0].pageParams)

        // Refresh current page
        if (lastPageId === currentPageId) {
          find(true)
        } else {
          // Invalidate last page
          RequestsStore.removeRequestPage(
            requestId,
            mutableQuery.value,
            lastPageId,
          )
        }
      }
    }

  const reactToCreated = LodashDebounce(reactToEvent('created'), 500)
  const reactToRemoved = LodashDebounce(reactToEvent('removed'), 500)

  // Listen to server created and removed events
  if (!noEvents) {
    store.on('created', reactToCreated)
    store.on('removed', reactToRemoved)

    onBeforeUnmount(() => {
      store.removeListener('created', reactToCreated)
      store.removeListener('removed', reactToRemoved)
    })
  }

  watch(allItems, (newVal, oldVal) => {
    const shouldUpdateTotal =
      !isNaN(newVal.total) &&
      !isNaN(oldVal.total) &&
      storedQuery.value &&
      storedQuery.value.total < newVal.total
    if (shouldUpdateTotal) {
      return RequestsStore.updateQueryTotal(
        requestId,
        mutableQuery.value,
        newVal.total,
      )
    }
  })

  const reset = () => {
    mutableQuery.value = { ...initialQuery }
  }

  const find = (force?: boolean): Promise<unknown> => {
    return generalFind(mutableQuery.value, force)
  }

  const next = (): Promise<unknown> => {
    if (!nextPageAvailable.value) return Promise.resolve()

    const incrementedQuery = incrementQueryPage(mutableQuery.value)

    return generalFind(incrementedQuery)
  }

  const previous = (): Promise<unknown> => {
    if (!previousPageAvailable.value) return Promise.resolve()

    const decrementedQuery = decrementQueryPage(mutableQuery.value)

    return generalFind(decrementedQuery)
  }

  const toPage = (page: number): Promise<unknown> => {
    const newQuery = {
      ...mutableQuery.value,
      $skip: mutableQuery.value.$limit * (page - 1),
    }

    return generalFind(newQuery)
  }

  const search = (searchQuery: Partial<Query>, force?: boolean) => {
    return generalFind({ ...mutableQuery.value, ...searchQuery }, force)
  }

  const populateRecord = (record: Awaited<ReturnType<Store['find']>>) => {
    if (!populate || !storedQuery.value) {
      return record
    }

    const populates = Array.isArray(populate) ? populate : [populate]
    const promises = populates
      .filter((pop) => !!pop)
      .map((pop) => {
        const data = useReactivePagination(
          pop.store,
          `${requestId}-${pop.store.$id}-populate`,
          {
            ...pop.query(record['data']),
            $skip: 0,
            $limit: initialQuery.$limit,
          },
          undefined,
          true,
        )

        return record.data.length ? data.find() : Promise.resolve()
      })

    return Promise.all(promises).then(() => record)
  }

  const generalFind = (query: Query, force = false) => {
    if (RequestsStore.getPage(requestId, query) && !force) {
      return Promise.resolve().then(() => (mutableQuery.value = { ...query }))
    }

    requestInProgress.value = true

    return (
      store
        // @ts-ignore
        .find({ query })
        .then((result) => {
          // TODO: check if that doesn't break anything
          // if (!result.data.length) {
          //   RequestsStore.updateQueryTotal(requestId, query, result.total)

          //   return mutableQuery.value = { ...query }
          // }

          RequestsStore.addRequest(requestId, {
            total: result.total,
            query: query,
            ids: result.data.map((item) => item.value?._id).filter((id) => id),
          }) && (mutableQuery.value = { ...query })

          return result
        })
        // @ts-ignore
        .then((result) => populateRecord(result))
        .catch((error) => {
          console.log({ error })

          notifyWarning('Error happened', error.message)

          throw error
        })
        .finally(() => {
          requestInProgress.value = false
        })
    )
  }

  return {
    allItems,
    pageItems,
    nextPageAvailable,
    previousPageAvailable,
    nextPageDataAvailable,
    previousPageDataAvailable,
    pageCount,
    currentPage,
    total,
    requestInProgress: requestInProgressComputed,
    search,
    find,
    generalFind,
    next,
    previous,
    reset,
    toPage,
  }
}
