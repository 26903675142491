<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet />
      <ion-tab-bar
        slot="bottom"
        :class="tabBarClasses"
        :style="tabBarStyles"
        class="absolute bottom-0 left-0 w-full z-10 transition-transform duration-300
          ease-in-out"
      >
        <ion-tab-button
          v-for="(tab, index) in nagivationConfig"
          :key="index"
          :tab="tab.icon"
          :tag="tab.tab"
          @click="tab.onClick"
        >
          <AppIcon
            :icon="tab.icon"
            :class="[
              ...tab.iconClasses,
              tab.active
                ? [AppStore.isOffline ? 'text-fg-yellow' : 'text-fg-blue']
                : [],
            ]"
            :stroke-width="tab.active ? '2px' : '1.8px'"
            size="custom"
            class="transition-[stroke-width] duration-200"
          />
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup lang="ts">
import { computed, watch, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAppStore } from '~/stores/app'
import { useAuthStore } from '~/stores/auth'
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonPage,
  IonRouterOutlet,
  useIonRouter,
} from '@ionic/vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import { useInjectAppUIState } from '@restify/packages/composables/useAppUiState'

const ionRouter = inject('navManager')
const router = useIonRouter()
const vueRouter = useRouter()
const route = useRoute()
const AppStore = useAppStore()
const AuthStore = useAuthStore()
const { appUiTabBarIsVisible } = useInjectAppUIState()

const nagivationConfig = computed(() => {
  const storefront = AppStore.selectedStorefront

  if (!storefront) return []

  return [
    ...(storefront.inHouseService?.enabled &&
    storefront.inHouseService?.setup?.map
      ? [
          {
            to: {
              name: 'map',
            },
            icon: 'map',
            tab: 'map',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
    ...(storefront.inHouseService?.enabled &&
    storefront.inHouseService?.setup?.tabs
      ? [
          {
            to: {
              name: 'tabs',
            },
            icon: 'type',
            tab: 'tabs',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
    {
      to: {
        name: 'in-house',
      },
      icon: 'list',
      tab: 'in-house',
      iconClasses: ['w-[28px]', 'h-[28px]'],
    },
    ...(AuthStore.cachedUser?.value?.profile?.role === 'manager'
      ? [
          {
            to: {
              name: 'dashboard',
            },
            icon: 'coins',
            tab: 'dashboard',
            iconClasses: ['w-[28px]', 'h-[28px]'],
          },
        ]
      : []),
    // {
    //   to: {
    //     name: 'scan'
    //   },
    //   icon: 'scan-qr-code',
    //   tab: 'scan',
    //   iconClasses: ['w-[28px]', 'h-[28px]']
    // },
    // {
    //   to: {
    //     name: 'to-go'
    //   },
    //   icon: 'shopping-bag',
    //   tab: 'to-go',
    //   iconClasses: ['w-[30px]', 'h-[30px]']
    // },
    {
      to: {
        name: 'user',
      },
      icon: 'user',
      tab: 'user',
      iconClasses: ['w-[28px]', 'h-[28px]'],
    },
  ].map((tab) => {
    const href = vueRouter.resolve(tab.to).href

    return {
      ...tab,
      href,
      active: route.fullPath.includes(href),
      onClick: () => {
        if (
          route.name === 'dashboard-shift-id' ||
          route.name === 'user-storefront-select'
        ) {
          // @ts-ignore
          router.navigate(href, 'back', 'pop')
        } else {
          // @ts-ignore
          ionRouter.changeTab(tab.tab, href)
        }
      },
    }
  })
})

const tabBarClasses = computed(() => {
  return {
    unauthorized: ['shadow-elevation-1-0-lg'],
    map: ['shadow-elevation-1-0-lg'],
    'in-house': ['shadow-elevation-1-1-lg'],
    scan: ['shadow-elevation-1-0-lg'],
    'to-go': ['shadow-elevation-1-0-lg'],
    dashboard: ['shadow-elevation-1-0-lg'],
    'dashboard-shift-id': ['shadow-elevation-1-0-lg'],
    user: ['shadow-elevation-1-0-lg'],
    'user-storefront-select': ['shadow-elevation-1-0-lg'],
    tabs: ['shadow-elevation-1-1-lg'],
  }[String(route.name)]
})

const tabBarStyles = computed(() => {
  return {
    ...{
      unauthorized: {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      map: {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      'in-house': {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      scan: {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      'to-go': {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      dashboard: {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      'dashboard-shift-id': {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      user: {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      'user-storefront-select': {
        '--background': 'var(--rf-bg-elevation-1)',
        '--border': '0.5px solid var(--rf-border-elevation-1-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
      tabs: {
        '--background': 'var(--rf-bg-elevation-1-ios)',
        '--border': '0.5px solid var(--rf-border-elevation-1-ios-primary)',
        '--color': 'var(--rf-fg-elevation-1-tertiary)',
      },
    }[String(route.name || 'user')],
    transform: appUiTabBarIsVisible.value ? null : 'translateY(100%)',
  }
})

watch(
  computed(() => route.fullPath),
  () => {
    navigator.serviceWorker.ready.then((registration) => {
      console.log('Trying to update SW')
      registration.update()
    })
  },
)
</script>
