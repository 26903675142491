import LodashDebounce from 'lodash.debounce'
import { useAuthStore } from '../stores/auth'

export default () => {
  const AuthStore = useAuthStore()
  const onAction = LodashDebounce(() => {
    if (!AuthStore.isUserAuthenticated) return

    AuthStore.lastActivity = Date.now()
  }, 1000)

  window.addEventListener('mousemove', onAction)
  window.addEventListener('touchstart', onAction)
}
