<template>
  <AppStack
    justify-content="justify-between"
    align-items="items-start"
    class="w-full px-5 pt-2 pb-3 gap-6"
  >
    <AppStack direction="flex-col" class="overflow-hidden gap-[2px]">
      <AppTypography
        name="text-md-4-medium"
        color="text-fg-elevation-3-primary"
        :text="props.title"
      />
      <AppTypography
        :text="props.description"
        name="text-sm-5-normal"
        color="text-fg-elevation-3-secondary"
        class="truncate"
      />
    </AppStack>
    <AppTypography
      v-if="removable"
      :text="$t('promotions.remove')"
      :class="props.disabled ? 'opacity-40' : ''"
      color="text-fg-red"
      name="text-md-4-medium"
      @click="emit('remove')"
    />
    <AppTypography
      v-else
      :class="props.disabled ? 'opacity-40' : ''"
      :text="applied ? $t('promotions.applied') : $t('promotions.apply')"
      :color="applied ? 'text-fg-elevation-3-tertiary' : 'text-fg-blue'"
      name="text-md-4-medium"
      class="active:opacity-50"
      @click="!applied && emit('apply')"
    />
  </AppStack>
</template>

<script setup lang="ts">
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const emit = defineEmits(['apply', 'remove'])

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  applied: {
    type: Boolean,
    required: false,
  },
  removable: {
    type: Boolean,
    required: false,
  },
})
</script>
