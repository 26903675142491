import { useAuthStore } from '~/stores/auth'
import {
  mapMenuItem as HelpersEntitiesMapMenuItem,
  countMenuItemTotal as HelpersEntitiesCountMenuItemTotal,
  countPromotionTotal as HelpersEntitiesCountPromotionTotal,
  translateField as HelpersEntitiesTranslateField,
} from '@restify/packages/helpers/entities'

export default () => {
  const AuthStore = useAuthStore()

  return {
    mapMenuItem: (
      menuItem: Parameters<typeof HelpersEntitiesMapMenuItem>[0],
      options?: Parameters<typeof HelpersEntitiesMapMenuItem>[2],
    ) => {
      if (!AuthStore.userApp) return null

      return HelpersEntitiesMapMenuItem(menuItem, AuthStore.userApp, options)
    },
    countMenuItemTotal: HelpersEntitiesCountMenuItemTotal,
    countPromotionTotal: HelpersEntitiesCountPromotionTotal,
    translateField: (
      field: Parameters<typeof HelpersEntitiesTranslateField>[1],
      fallback?: Parameters<typeof HelpersEntitiesTranslateField>[2],
    ) => {
      if (!AuthStore.userApp) return null

      return HelpersEntitiesTranslateField(AuthStore.userApp, field, fallback)
    },
    formatPrice: (value?: number | string, includeSigned = true) => {
      const sign = includeSigned ? ` ${AuthStore.userApp?.currency.sign}` : ''

      if (value === undefined) {
        return `?${sign}`
      } else if (typeof value === 'number') {
        return `${value.toFixed(2)}${sign}`
      } else {
        return `${value}${sign}`
      }
    },
  }
}
