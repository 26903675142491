import { onMounted, onDeactivated, ref } from 'vue'

export default function usePreferredColorScheme(autoInit = true) {
  const preferredColorScheme = ref<'light' | 'dark'>('light')

  const detectColorScheme = () => {
    preferredColorScheme.value = window.matchMedia(
      '(prefers-color-scheme: dark)',
    ).matches
      ? 'dark'
      : 'light'
  }

  const onColorSchemeChange = (event: MediaQueryListEvent) => {
    preferredColorScheme.value = event.matches ? 'dark' : 'light'
  }

  detectColorScheme()

  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', onColorSchemeChange)

  onDeactivated(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', onColorSchemeChange)
  })

  return preferredColorScheme
}
