// @ts-nocheck
import { defineStore } from 'pinia'
import Stringify from 'fast-json-stable-stringify'
import LodashOmit from 'lodash.omit'
import LodashPick from 'lodash.pick'

type Query = {
  $limit: number
  $skip: number
  [key: string]: any
}
type Request = {
  query: Query
  total: number
  ids: unknown[]
}

export const getPageId = (query: Query) => {
  const pageParams = LodashPick(query, '$limit', '$skip')

  return Stringify(pageParams)
}

export const getRequestIds = (query: Query) => {
  const queryParams = LodashOmit(query, '$limit', '$skip')
  const pageParams = LodashPick(query, '$limit', '$skip')

  return {
    queryId: Stringify(queryParams),
    pageId: Stringify(pageParams),
    queryParams,
    pageParams,
  }
}

type RequestId = string
type QueryId = string
type PageId = string
type Requests = Record<
  RequestId,
  Record<
    QueryId,
    {
      total: number
      queryParams: any
      pages: Record<
        PageId,
        {
          pageParams: any
          ids: string[]
        }
      >
    }
  >
>

export const useRequestsStore = defineStore('requests', {
  state: () => ({
    requests: {} as Requests,
  }),
  getters: {
    getPage() {
      return (requestId: string, query: Query) =>
        this.pageExists(requestId, query) || null
    },
    getPages() {
      return (requestId: string, query: Query) => {
        const storedQuery = this.getQuery(requestId, query) || null

        if (!storedQuery) return null

        const pages = Object.values(storedQuery.pages).sort(
          (a, b) => b.pageParams.$skip - a.pageParams.$skip,
        )

        return pages
      }
    },
    getQuery() {
      return (requestId: string, query: Query) =>
        this.queryExists(requestId, query) || null
    },
    requestPagesAreNonConsecutive() {
      return (requestId: string, query: Query) => {
        const storedQuery = this.queryExists(requestId, query)

        if (!storedQuery) return false

        const pages = Object.values(storedQuery.pages).sort(
          (a, b) => b.pageParams.$skip - a.pageParams.$skip,
        )
        const lastPageParams = pages[pages.length - 1].pageParams

        // If first page in list is not first — not consecutive
        if (lastPageParams.$skip !== 0) {
          return true
        }

        const firstPage = pages[0].pageParams
        let remainder = firstPage.$skip - firstPage.$limit

        for (let index = 1; index < pages.length; index++) {
          const pageParams = pages[index].pageParams

          if (remainder !== pageParams.$skip) {
            // Not consecutive
            return true
          } else {
            remainder = pageParams.$skip - pageParams.$limit
          }
        }

        // Consecutive
        return false
      }
    },
    queryExists() {
      return (requestId: string, query: Query) => {
        const { queryId } = getRequestIds(query)

        return requestId in this.requests && this.requests[requestId][queryId]
      }
    },
    pageExists() {
      return (requestId: string, query: Query) => {
        const { queryId, pageId } = getRequestIds(query)

        return (
          requestId in this.requests &&
          this.requests[requestId][queryId] &&
          this.requests[requestId][queryId].pages[pageId]
        )
      }
    },
  },
  actions: {
    reset() {
      this.requests = {}
    },
    addRequest(requestId: string, request: Request) {
      const { queryParams, pageParams, queryId, pageId } = getRequestIds(
        request.query,
      )

      // Request exists
      if (this.requests[requestId]) {
        // Query exists
        if (this.requests[requestId][queryId]) {
          // Update total
          this.requests[requestId][queryId].total = request.total

          // Create/update page
          return (this.requests[requestId][queryId].pages[pageId] = {
            pageParams,
            ids: request.ids,
          })
        }

        // Create query
        return (this.requests[requestId][queryId] = {
          total: request.total,
          queryParams: { ...queryParams },
          pages: {
            [pageId]: {
              pageParams,
              ids: request.ids,
            },
          },
        })
      }

      // Create request
      return (this.requests[requestId] = {
        [queryId]: {
          total: request.total,
          queryParams: { ...queryParams },
          pages: {
            [pageId]: {
              pageParams,
              ids: request.ids,
            },
          },
        },
      })
    },
    updateQueryTotal(requestId: string, query: Query, total) {
      if (!this.queryExists(requestId, query)) return

      const { queryId } = getRequestIds(query)

      this.requests[requestId][queryId].total = total
    },
    removeRequest(requestId: string) {
      delete this.requests[requestId]
    },
    removeRequestQuery(requestId: string, queryId: string) {
      delete this.requests[requestId][queryId]
    },
    removeRequestPage(requestId: string, query: Query, pageId: string) {
      const { queryId } = getRequestIds(query)

      delete this.requests[requestId][queryId].pages[pageId]
    },
    removeAllRequestPagesButCurrent(requestId: string, query: Query) {
      const { queryId, pageId } = getRequestIds(query)
      const pages = this.requests[requestId][queryId].pages

      for (const id in pages) {
        if (id === pageId) continue

        delete this.requests[requestId][queryId].pages[id]
      }
    },
  },
})
