<template>
  <ion-header>
    <ion-toolbar :style="contentStyles">
      <ion-buttons slot="start">
        <ion-back-button />
      </ion-buttons>
      <ion-title>Promotions</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content :fullscreen="true" :style="contentStyles">
    <AppStack
      v-if="loading"
      direction="flex-col"
      flex="flex-1"
      align-items="center"
      justify-content="center"
      class="py-10 w-full absolute h-full left-0 top-0"
    >
      <AppSpinner
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </AppStack>
    <template v-else>
      <AppStack direction="flex-col" flex="flex-1" class="py-6 gap-8">
        <AppStack
          v-if="addedPromotionsList.length"
          direction="flex-col"
          class="gap-3"
        >
          <AppTypography
            text="Added"
            name="text-md-4-medium"
            color="text-fg-elevation-2-secondary"
            class="px-5"
          />
          <AppStack direction="flex-col">
            <template
              v-for="promotion in addedPromotionsList"
              :key="promotion._id"
            >
              <OrderModalPromotionsItem
                :title="promotion.title"
                :disabled="orderUpdateInProgress"
                :description="promotion.description"
                :applied="
                  promotion._id in (order?.appliedPromotions?.promotions || {})
                "
                removable
                @remove="onAppliedPromotionRemove(promotion._id)"
              />
              <div class="pl-5">
                <div class="h-[0.5px] bg-border-elevation-2-secondary" />
              </div>
            </template>
          </AppStack>
        </AppStack>
        <AppStack direction="flex-col" class="gap-3">
          <AppTypography
            text="Matching"
            name="text-md-4-medium"
            color="text-fg-elevation-2-secondary"
            class="px-5"
          />
          <AppStack direction="flex-col">
            <template v-for="promotion in promotionsList" :key="promotion._id">
              <OrderModalPromotionsItem
                :title="promotion.name"
                :disabled="orderUpdateInProgress"
                :description="promotion.description"
                :applied="
                  promotion._id in (order?.appliedPromotions?.promotions || {})
                "
                @apply="onPromotionApply(promotion._id)"
              />
              <div class="pl-5">
                <div class="h-[0.5px] bg-border-elevation-2-secondary" />
              </div>
            </template>
          </AppStack>
        </AppStack>
      </AppStack>
      <div
        :style="{
          height: `${addedPromotionsList.length * 18 + 154 + 90}px`,
        }"
      />
      <AppStack
        direction="flex-col"
        class="fixed bottom-0 left-0 w-full safe-bottom-max px-5 bg-bg-elevation-2"
      >
        <div
          class="absolute top-[-90px] left-0 w-full h-[90px] pointer-events-none bg-gradient-to-b
            to-bg-elevation-2 from-transparent"
        />
        <AppStack direction="flex-col" class="py-5 gap-3">
          <AppStack align-items="items-baseline">
            <AppTypography
              name="text-xl-7-semibold"
              color="text-fg-elevation-2-primary"
              :text="formatPrice(orderTotal)"
            />
            <AppTypography
              v-if="orderSecondaryTotal"
              name="text-lg-7-semibold"
              color="text-fg-elevation-2-secondary"
              class="ml-3 line-through"
              :text="formatPrice(orderSecondaryTotal)"
            />
          </AppStack>
          <template v-if="addedPromotionsList.length">
            <AppStack
              v-for="promotion in addedPromotionsList"
              :key="promotion._id"
              justify-content="justify-between"
            >
              <AppTypography
                name="text-md-4-medium"
                color="text-fg-elevation-2-secondary"
                :text="promotion.title"
              />
              <AppTypography
                name="text-md-4-medium"
                color="text-fg-elevation-2-secondary"
                :text="promotion.discount"
              />
            </AppStack>
          </template>
        </AppStack>
        <AppButton
          variant="secondary"
          :loading="orderUpdateInProgress"
          :text="$t('promotions.done')"
          @click="pop()"
        />
      </AppStack>
    </template>
  </ion-content>
</template>

<script lang="ts">
export default { name: 'OrderModalPromotions' }
</script>

<script setup lang="ts">
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
} from '@ionic/vue'
import { ref, computed, watch, inject, ComputedRef, reactive } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppSpinner from '@restify/packages/design-system/low-level/AppSpinner.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import { getUniqueValues as HelpersArrayGetUniqueValues } from '@restify/packages/helpers/array'
import { useIonicNavigation } from '@restify/packages/composables/useIonicNavigation'
import AppButton from '~/components/AppButton.vue'
import OrderModalPromotionsItem from './OrderModalPromotionsItem.vue'
import useAppHelpers from '~/composables/useAppHelpers'
import useStores from '~/composables/useStores'

const { pop } = useIonicNavigation('order-modal-nav')
const { orders: OrdersStore, promotions: PromotionsStore } = useStores()
const { formatPrice } = useAppHelpers()

const injectedIsOpen = inject<ComputedRef<boolean>>('isOpen')
const injectedOrderId = inject<ComputedRef<string>>('orderId')

const loading = ref(false)
const orderUpdateInProgress = ref(false)

const contentStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
  '--border-color': 'var(--rf-border-elevation-2-primary)',
}))

const order = computed(() => {
  if (!injectedOrderId?.value) return null

  return OrdersStore.getFromStore(injectedOrderId.value).value
})

const orderTotal = computed(() => {
  return typeof order.value?.appliedPromotions?.total === 'number'
    ? order.value?.appliedPromotions?.total || 0
    : order.value?.total || 0
})

const orderSecondaryTotal = computed(() => {
  return typeof order.value?.appliedPromotions?.total === 'number'
    ? order.value?.total || 0
    : null
})

const addedPromotionsList = computed(() => {
  const ids = [...Object.keys(order.value?.appliedPromotions?.promotions || {})]

  if (!ids.length) return []

  return PromotionsStore.findInStore({
    _id: { $in: ids },
  }).map((promotion) => {
    const discount =
      order.value?.appliedPromotions?.promotions?.[
        promotion.value?._id
      ]?.discount.toFixed(2)

    return {
      _id: promotion.value?._id,
      title: promotion.value?.name,
      description: formatPrice(discount ? -discount : 0),
      discount: formatPrice(
        order.value?.appliedPromotions?.promotions?.[promotion.value?._id]
          ?.discount
          ? -order.value?.appliedPromotions?.promotions?.[promotion.value?._id]
              ?.discount
          : 0,
      ),
    }
  })
})

const promotionsList = computed(() => {
  if (!order.value) return []

  return PromotionsStore.findInStore({
    orderType: order.value.type,
    status: 'public',
  }).map((item) => item.value)
})

const onPromotionApply = (id: string) => {
  if (!order.value?._id) return

  orderUpdateInProgress.value = true

  return OrdersStore.patch(order.value._id, {
    ...(!order.value.promotionIds.includes(id)
      ? { $push: { promotionIds: id } }
      : {}),
  }).finally(() => {
    orderUpdateInProgress.value = false
  })
}

const onAppliedPromotionRemove = (id) => {
  if (!order.value) return

  orderUpdateInProgress.value = true

  return OrdersStore.patch(order.value._id, {
    $pull: { promotionIds: id },
  })
    .catch(console.log)
    .finally(() => {
      orderUpdateInProgress.value = false
    })
}

const getPromotions = () => {
  const ids = HelpersArrayGetUniqueValues([
    ...(order?.value?.promotionIds || []),
    ...(Object.keys(order?.value?.appliedPromotions?.promotions || {}) || []),
  ])

  if (!order.value) return

  return Promise.all([
    PromotionsStore.find({
      query: {
        _id: { $in: ids },
        $limit: 100,
      },
    }),
    PromotionsStore.find({
      query: {
        orderType: order.value.type,
        status: 'public',
        startDate: { $lt: new Date().getTime() },
        endDate: { $gt: new Date().getTime() },
        $limit: 100,
      },
    }),
  ])
}

const getOrder = () => {
  if (injectedOrderId?.value === 'new' || !injectedOrderId?.value)
    return Promise.resolve()

  return OrdersStore.get(injectedOrderId.value)
}

const fetchData = () => {
  loading.value = true

  return getOrder()
    .then(() => Promise.all([getPromotions()]))
    .finally(() => (loading.value = false))
}

watch(
  computed(() => injectedIsOpen?.value),
  (newVal, oldVal) => newVal && fetchData(),
  { immediate: true },
)
</script>
