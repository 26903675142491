export const getUniqueValues = (array: any[]) => {
  return [...new Set([...array])]
}

export function uniqueIdsReducer<T>(
  array: T[],
  field: T extends T ? keyof T : never,
): string[] {
  const ids = array.reduce((acc: string[], item: T) => {
    const value = item[field] as string | string[]

    if (value) acc.push(...(Array.isArray(value) ? value : [value]))

    return acc
  }, [])

  return getUniqueValues(ids)
}

export function uniqueIdsReducers(
  array: {
    array: unknown[]
    field: string
  }[],
): string[] {
  const ids = array
    // @ts-ignore
    .map(({ array, field }) => uniqueIdsReducer(array, field))
    .reduce((acc: string[], items) => {
      acc.push(...getUniqueValues(items))

      return acc
    }, [])

  return getUniqueValues(ids)
}
