<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 28"
    class="app-logo-short"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="m3.589 19.65 3.893 3.885c1.982 1.979 2.972 2.968 4.115 3.338a5.013 5.013 0 0 0 3.093 0c1.063-.344 1.995-1.224 3.715-2.938a10.728 10.728 0 0 1-3.517-2.342 10.727 10.727 0 0 1 0-15.189 10.729 10.729 0 0 1 3.518-2.342c-1.72-1.714-2.653-2.594-3.716-2.94a5.013 5.013 0 0 0-3.093 0c-1.143.371-2.133 1.36-4.115 3.339L3.589 8.346C1.607 10.325.616 11.314.245 12.454a4.987 4.987 0 0 0 0 3.088c.371 1.14 1.362 2.13 3.344 4.108Z"
      fill="var(--app-logo-full-accent)"
    />
    <path
      d="M17.197 19.302A7.5 7.5 0 1 1 27.804 8.695a7.5 7.5 0 0 1-10.607 10.607Z"
      fill="var(--app-logo-full-base)"
    />
  </svg>
</template>

<style scoped>
.app-logo-short {
  --app-logo-full-accent: var(--rf-fg-blue);
  --app-logo-full-base: var(--rf-fg-elevation-0-primary);
}
</style>
