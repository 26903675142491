<template>
  <AppStack
    ref="scrollview"
    tag="nav"
    class="gap-3 snap-x snap-mandatory overflow-x-auto"
  >
    <div class="mr-auto" />
    <AppTypography
      v-for="(tab, tabIdx) in props.tabs"
      ref="tabRefs"
      :key="tab.name"
      name="text-sm-5-semibold"
      :class="[
        props.activeTab === tab.value
          ? ['bg-bg-blue']
          : ['bg-bg-elevation-2-alt'],
      ]"
      :color="
        props.activeTab === tab.value
          ? 'text-universal-fg-opposite'
          : 'text-fg-elevation-1-primary'
      "
      class="flex flex-shrink-0 group relative overflow-hidden py-[5px] text-center
        snap-center px-3 cursor-pointer transition-colors focus:z-10 rounded-full
        whitespace-nowrap snap-x"
      @click="emit('update:active-tab', tab.value || tabIdx)"
    >
      {{ tab.name }}
    </AppTypography>
    <div class="ml-auto" />
  </AppStack>
</template>

<script setup lang="ts">
import { PropType, watch, ref, computed } from 'vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'

const emit = defineEmits(['update:active-tab'])
const props = defineProps({
  activeTab: {
    type: String,
    default: null,
  },
  tabs: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
})

const scrollview = ref(null)
const tabRefs = ref([])

watch(
  computed(() => props.activeTab),
  (newVal, oldVal) => {
    if (!oldVal) return

    const index = props.tabs.findIndex((tab) => tab.value === newVal)

    if (tabRefs.value[index].$el) {
      const element = tabRefs.value[index].$el

      if (!element) return

      tabRefs.value[index].$el.scrollIntoView({
        inline: 'center',
        behavior: 'smooth',
      })
    }
  },
)
</script>
