import { ref, inject, provide, computed, ComputedRef } from 'vue'

export const useProvideAppUIState = () => {
  const tabBarIsVisible = ref(true)

  const setTabBarIsVisible = (value: boolean) => {
    tabBarIsVisible.value = value
  }

  provide('appUiTabBarIsVisible', tabBarIsVisible)
  provide('appUiSetTabBarIsVisible', setTabBarIsVisible)

  return {
    appUiTabBarIsVisible: tabBarIsVisible,
    appUiSetTabBarIsVisible: setTabBarIsVisible,
  }
}

export const useInjectAppUIState = () => {
  const injectedTabBarIsVisible = inject<ComputedRef<boolean>>(
    'appUiTabBarIsVisible',
  )
  const injectedSetTabBarIsVisible = inject<(value: boolean) => unknown>(
    'appUiSetTabBarIsVisible',
  )

  const computedTabBarIsVisible = computed(() => {
    return injectedTabBarIsVisible?.value || false
  })

  const setTabBarIsVisible = (value: boolean) => {
    return injectedSetTabBarIsVisible && injectedSetTabBarIsVisible(value)
  }

  return {
    appUiTabBarIsVisible: computedTabBarIsVisible,
    appUiSetTabBarIsVisible: setTabBarIsVisible,
  }
}
