import { defineStore } from 'pinia'
import { toastController } from '@ionic/vue'
import { AuthenticationRequest } from '@feathersjs/authentication'
import { useLocalStorage } from '@vueuse/core'
import useStores, { type Stores } from '~/composables/useStores'
import useFeathers from '~/composables/useFeathers'
import { useAppStore } from '~/stores/app'
import { parse as HelpersJwtParse } from '@restify/packages/helpers/jwt'

const cachedUser = useLocalStorage<Partial<Stores['users']['Result']>>(
  'auth/cachedUser',
  {},
)

export const useInternalAuthStore = defineStore('authentication', () => {
  const api = useFeathers()

  return api
})

export const useAuthStore = defineStore('auth', {
  state: (): {
    accessToken: null | string
    user: null | Stores['users']['Result']
    authentication: unknown
    preferredEmail: string | null
    preferredAppId: string | null
    preferredStorefrontId: string | null
    lastSessionLogin: null | number
    lastActivity: number
    // cachedUser: Ref<null | Stores['users']['Result']>
  } => ({
    accessToken: null,
    user: null,
    authentication: {},
    preferredEmail: null,
    preferredAppId: null,
    preferredStorefrontId:
      window.localStorage.getItem('preferredStorefrontId') || null,
    lastSessionLogin: null,
    lastActivity: Date.now(),
    // cachedUser: useLocalStorage('auth/cachedUser', null),
  }),
  getters: {
    isUserAuthenticated(): boolean {
      return !!this.accessToken
    },
    parsedAccessToken(): ReturnType<typeof HelpersJwtParse> | null {
      return (this.accessToken && HelpersJwtParse(this.accessToken)) || null
    },
    cachedUser(): Stores['users']['Result'] | null {
      const { users: UsersStore } = useStores()
      const value = this.user ? UsersStore.itemsById[this.user._id] : null

      if (value) {
        cachedUser.value = value
      }

      return cachedUser.value as Stores['users']['Result']
    },
    reactiveUser(): Stores['users']['Result'] | null {
      const { users: UsersStore } = useStores()

      return this.user ? UsersStore.itemsById[this.user._id].value : null
    },
    userApp(): Stores['apps']['Result'] | null {
      const { apps: AppsStore } = useStores()
      const appId = this.preferredAppId || this.reactiveUser?.appId

      if (!appId) return null

      return AppsStore.itemsById[appId].value || null
    },
    userPermittedStorefrontId(): string | undefined {
      if (!this.reactiveUser) return undefined

      if (this.reactiveUser?.permissions?.storefrontIds?.[0]) {
        return this.reactiveUser.permissions.storefrontIds[0]
      }

      const { storefronts: StorefrontsStore } = useStores()

      const storefronts = StorefrontsStore.findInStore({
        appId: this.reactiveUser.appId,
        $sort: { createdAt: -1 },
      })

      return storefronts?.[0]?.value?._id
    },
  },
  actions: {
    async authenticate(authData: AuthenticationRequest) {
      const { apps: AppsStore } = useStores()

      return this.authenticateBase({
        ...authData,
        ...(this.preferredAppId ? { preferredAppId: this.preferredAppId } : {}),
        ...(this.preferredStorefrontId
          ? { preferredStorefrontId: this.preferredStorefrontId }
          : {}),
        ...(authData.preferredAppId
          ? { preferredAppId: authData.preferredAppId }
          : {}),
        ...(authData.preferredStorefrontId
          ? { preferredStorefrontId: authData.preferredStorefrontId }
          : {}),
      }).then(() => {
        if (!this.parsedAccessToken) return

        const parsedToken = this.parsedAccessToken

        // Get his permitted app and redirect to appropriate alias
        return (
          parsedToken.appId &&
          AppsStore.get(parsedToken.appId)
            .then(() => {
              this.setPreferredStorefrontId(parsedToken.storefrontId)

              if (parsedToken.isSuperadmin) {
                this.setPreferredAppId(parsedToken.appId)

                return
              }

              this.removePreferredAppId()
            })
            .catch(async (error) => {
              this.logout()

              const toast = await toastController.create({
                message: `Error: ${error.name}. ${error.message}`,
                duration: 3000,
                cssClass: 'toast text-lg-7-semibold',
                position: 'bottom',
                mode: 'ios',
              })

              await toast.present()

              throw error
            })
        )
      })
    },
    async authenticateBase(authData: AuthenticationRequest) {
      const authUtils = useInternalAuthStore()
      const {
        users: UsersStore,
        storefronts: StorefrontsStore,
        shifts: ShiftsStore,
      } = useStores()

      try {
        this.$state.isLoading = true

        const response = await authUtils.authenticate(authData)

        await Promise.all([
          UsersStore.get(response.user._id),
          ShiftsStore.find({
            query: {
              assignedStaffIds: response.user._id,
              $or: [
                {
                  startsAt: {
                    $lte: new Date().getTime(),
                  },
                  endsAt: {
                    $gte: new Date().getTime(),
                  },
                },
                {
                  startsAt: {
                    $gte: new Date().getTime(),
                  },
                },
              ],
              $sort: { startsAt: 1 },
            },
          }),
          StorefrontsStore.find({
            query: {
              appId: response.user.appId,
              $sort: { createdAt: -1 },
            },
          }),
        ])

        this.$state.accessToken = response.accessToken
        this.$state.authentication = response.authentication
        this.$state.user = { ...response.user }
        this.$state.isLoading = false
        this.$state.lastSessionLogin = Date.now()

        window.localStorage.setItem('preferredEmail', response.user.email)

        return response
      } catch (error) {
        const toast = await toastController.create({
          message: `Error: ${error.name}. ${error.message}`,
          duration: 3000,
          cssClass: 'toast text-lg-7-semibold',
          position: 'bottom',
          mode: 'ios',
        })

        await toast.present()

        if (error.name === 'NotAuthenticated') {
          this.logout()
        }

        throw error
      }
    },
    async logout() {
      const authUtils = useInternalAuthStore()
      this.$state.isLoading = true

      window.localStorage.removeItem('preferredEmail')
      window.localStorage.removeItem('preferredAppId')
      this.removePreferredAppId()

      return (
        authUtils.authentication.removeAccessToken() &&
        authUtils.authentication
          .reset()
          .then(() => {
            this.$state.accessToken = null
            this.$state.authentication = {}
            this.$state.user = null
            this.$state.lastSessionLogin = null
            this.$state.lastActivity = Date.now()
            this.$state.isLoading = false
          })
          .catch(async (error) => {
            const toast = await toastController.create({
              message: `Error: ${error.name}. ${error.message}`,
              duration: 3000,
              cssClass: 'toast text-lg-7-semibold',
              position: 'bottom',
              mode: 'ios',
            })

            await toast.present()
          })
      )
    },
    setPreferredAppId(id: string | null) {
      if (!this.reactiveUser?.isSuperadmin || !id) return

      this.preferredAppId = id
      window.localStorage.setItem('preferredAppId', id)
    },
    setPreferredStorefrontId(id: string | null) {
      this.preferredStorefrontId = id

      if (id) window.localStorage.setItem('preferredStorefrontId', id)

      useAppStore().setSelectedStorefrontId(id)
    },
    removePreferredAppId() {
      this.preferredAppId = null
      window.localStorage.removeItem('preferredAppId')
    },
  },
})
