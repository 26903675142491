// @ts-nocheck

import { ref } from 'vue'

export const useCallbackOnClick = (
  timeout: number,
  finalClick: number,
  callback: () => void,
) => {
  const counter = ref({
    clicks: 0,
    time: null,
  })

  const handler = ($event: MouseEvent) => {
    if (!counter.value.clicks) {
      counter.value = {
        clicks: 1,
        time: Date.now(),
      }

      setTimeout(() => {
        counter.value = {
          clicks: 0,
          time: null,
        }
      }, timeout)

      return
    }

    if (
      counter.value.clicks < finalClick &&
      counter.value.time !== null &&
      Date.now() - counter.value.time < timeout
    ) {
      counter.value = {
        clicks: counter.value.clicks + 1,
        time: Date.now(),
      }

      if (counter.value.clicks === finalClick) {
        counter.value = {
          clicks: 0,
          time: null,
        }

        callback()
      }

      return
    }
  }

  return {
    handler,
  }
}
