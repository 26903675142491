<template>
  <component :is="tag" :class="rootClasses">
    <slot />
  </component>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue'

const GeneralPropType = [String, Array] as PropType<string | string[]>

export default defineComponent({
  name: 'AppStack',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    flexType: {
      type: GeneralPropType,
      default: 'flex',
      validator: (val: string) => ['flex', 'inline-flex'].includes(val),
    },
    direction: {
      type: GeneralPropType,
      default: '',
    },
    wrap: {
      type: GeneralPropType,
      default: '',
    },
    flex: {
      type: GeneralPropType,
      default: '',
    },
    // grow: {
    //   type: GeneralPropType,
    //   default: 'grow-1'
    // },
    // shrink: {
    //   type: GeneralPropType,
    //   default: 'shrink-1'
    // },
    justifyContent: {
      type: GeneralPropType,
      default: '',
    },
    justifyItems: {
      type: GeneralPropType,
      default: '',
    },
    alignContent: {
      type: GeneralPropType,
      default: '',
    },
    alignItems: {
      type: GeneralPropType,
      default: '',
    },
    placeContent: {
      type: GeneralPropType,
      default: '',
    },
    placeItems: {
      type: GeneralPropType,
      default: '',
    },
  },
  setup(props) {
    const rootClasses = computed(() => [
      props.flexType,
      props.direction,
      props.wrap,
      props.flex,
      // props.grow,
      // props.shrink,
      props.justifyContent,
      props.justifyItems,
      props.alignContent,
      props.alignItems,
      props.placeContent,
      props.placeItems,
    ])

    return {
      rootClasses,
    }
  },
})
</script>
