<template>
  <ion-item
    :button="props.button"
    :style="rootStyles"
    @click="props.to && router.push(props.to)"
  >
    <ion-radio v-if="props.value !== null" slot="end" :value="props.value" />
    <AppStack direction="flex-col" class="group w-full">
      <AppStack
        justify-content="justify-center"
        direction="flex-col"
        class="relative py-3 min-h-[48px] gap-[2px]"
      >
        <AppTypography
          :text="props.title"
          :color="accentClass || colorClasses?.fgPrimary"
          :class="props.accent ? ['active:opacity-50'] : []"
          name="text-md-4-medium"
        />
        <AppTypography
          v-if="props.subtitle"
          :text="props.subtitle"
          :color="colorClasses?.fgSecondary"
          name="text-sm-5-normal"
        />
      </AppStack>
    </AppStack>
    <div v-if="props.caption" slot="end">
      <AppTypography
        :text="props.caption"
        :color="colorClasses?.fgSecondary"
        name="text-md-4-normal"
      />
    </div>
  </ion-item>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue'
import { IonItem, IonRadio, useIonRouter } from '@ionic/vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

type Elevation = '0' | '1' | '1-ios'
type To = {
  name: string
}

const props = defineProps({
  button: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: null,
  },
  caption: {
    type: String,
    default: null,
  },
  to: {
    type: Object as PropType<To>,
    default: null,
  },
  elevation: {
    type: String as PropType<Elevation>,
    required: true,
  },
  accent: {
    type: String as PropType<'blue' | 'red' | null>,
    default: null,
  },
})

const router = useIonRouter()

const rootStyles = computed(
  () =>
    ({
      0: {
        '--background': 'var(--rf-bg-elevation-0)',
        '--background-activated': 'var(--rf-bg-elevation-0-alt)',
        '--background-activated-opacity': '0.5',
        '--border-color': 'var(--rf-border-elevation-0-secondary)',
      },
      1: {
        '--background': 'var(--rf-bg-elevation-1)',
        '--background-activated': 'var(--rf-bg-elevation-1-alt)',
        '--background-activated-opacity': '0.5',
        '--border-color': 'var(--rf-border-elevation-1-secondary)',
      },
      '1-ios': {
        '--background': 'var(--rf-bg-elevation-1-ios)',
        '--background-activated': 'var(--rf-bg-elevation-1-ios-alt)',
        '--background-activated-opacity': '0.5',
        '--border-color': 'var(--rf-border-elevation-1-ios-secondary)',
      },
    })[String(props.elevation)],
)

const accentClass = computed(
  () =>
    ({
      blue: 'text-fg-blue',
      red: 'text-fg-red',
    })[String(props.accent)],
)

const colorClasses = computed(
  () =>
    ({
      0: {
        fgPrimary: 'text-fg-elevation-0-primary',
        fgSecondary: 'text-fg-elevation-0-secondary',
        fgTertiary: 'text-fg-elevation-0-tertiary',
      },
      1: {
        fgPrimary: 'text-fg-elevation-1-primary',
        fgSecondary: 'text-fg-elevation-1-secondary',
        fgTertiary: 'text-fg-elevation-1-tertiary',
      },
      '1-ios': {
        fgPrimary: 'text-fg-elevation-1-ios-primary',
        fgSecondary: 'text-fg-elevation-1-ios-secondary',
        fgTertiary: 'text-fg-elevation-1-ios-tertiary',
      },
    })[String(props.elevation)],
)
</script>
