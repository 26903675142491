<template>
  <ion-header>
    <ion-toolbar :style="contentStyles">
      <ion-buttons slot="start">
        <div class="active:opacity-50" @click="onBackButtonClick">
          <ion-back-button class="pointer-events-none" />
        </div>
      </ion-buttons>
      <ion-title>New batch</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content ref="scrollview" :fullscreen="true" :style="contentStyles">
    <AppStack direction="flex-col" flex="flex-1" class="h-full w-full">
      <AppStack ref="scrollviewContent" direction="flex-col" flex="flex-1">
        <OrderModalHomeMenuitemsList
          v-if="order && menuItems.length"
          :items="menuItems"
          quantity-changable
          deletable
          @increase-quantity="onIncreaseQuantity"
          @decrease-quantity="onDecreaseQuantity"
          @delete="onDelete"
          @add-comment="onComment"
        />
        <OrderModalHomeMenuitemsListButton
          text="Add Menu Item"
          @click="push(OrderModalMenuItems)"
        />
        <div class="h-[180px]" />
      </AppStack>
      <AppStack
        direction="flex-col"
        class="px-4 z-[10] fixed bottom-0 left-0 w-full safe-bottom-max bg-bg-elevation-2"
      >
        <div
          class="absolute top-[-90px] left-0 w-full h-[90px] pointer-events-none bg-gradient-to-b
            to-bg-elevation-2 from-transparent"
        />
        <AppStack class="gap-2">
          <AppButton
            :loading="sendToWorkInProgress"
            :disabled="checkoutIsDisabled"
            text="Send to work"
            class="flex-1"
            @click="onSendToWorkTap"
          />
          <AppButton variant="outline-secondary" @click="onClearBatchTap">
            <AppIcon
              color="text-fg-elevation-3-secondary"
              icon="bin-minus"
              size="custom"
              class="w-[30px] h-[30px] relative"
            />
          </AppButton>
        </AppStack>
      </AppStack>
    </AppStack>
  </ion-content>
</template>

<script lang="ts">
export default { name: 'OrderModalBatch' }
</script>

<script setup lang="ts">
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  alertController,
  toastController,
} from '@ionic/vue'
import { ref, reactive, computed, inject, ComputedRef, onMounted } from 'vue'
import { useEventBus } from '@vueuse/core'
import { useBottomScroll } from '@restify/packages/composables/useBottomScroll'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import { cleanCopy as HelpersObjectCleanCopy } from '@restify/packages/helpers/object'
import { useIonicNavigation } from '@restify/packages/composables/useIonicNavigation'
import useStores, { type Stores } from '~/composables/useStores'
import AppButton from '~/components/AppButton.vue'
import useAppHelpers from '~/composables/useAppHelpers'
import OrderModalHomeMenuitemsListButton from '~/views/OrderModalHome/OrderModalHomeMenuitemsListButton.vue'
import OrderModalHomeMenuitemsList from '~/views/OrderModalHome/OrderModalHomeMenuitemsList.vue'
import OrderModalMenuItems from '~/views/OrderModalMenuItems/index.vue'
import { nanoid } from 'nanoid'

type OrderMenuItem = Stores['orders']['Result']['menuItems'][number]
const { pop, push } = useIonicNavigation('order-modal-nav')

const injectedOrderId = inject<ComputedRef<string>>('orderId')

const bus = useEventBus<'batch-add'>('batch')
bus.on(onBatchEvent)
const { orders: OrdersStore } = useStores()
const batchMenuItems = reactive<Record<string, OrderMenuItem>>({})
const batchId = ref(nanoid())

const scrollview = ref(null)
const scrollviewContent = ref(null)
const sendToWorkInProgress = ref(false)

const { mapMenuItem } = useAppHelpers()
useBottomScroll(scrollview, scrollviewContent)

const contentStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
  '--border-color': 'var(--rf-border-elevation-2-primary)',
}))

const order = computed(() => {
  if (!injectedOrderId?.value) return null

  return OrdersStore.getFromStore(injectedOrderId.value).value
})

const checkoutIsDisabled = computed(() => !Object.keys(batchMenuItems).length)

const menuItems = computed(() =>
  Object.values(batchMenuItems).map((item) => ({
    ...mapMenuItem(item),
    status: null,
    _id: item._id,
  })),
)

function onBatchEvent(
  eventName: 'batch-add',
  payload: { menuItem: OrderMenuItem },
) {
  if (eventName === 'batch-add') {
    batchMenuItems[payload.menuItem._id] = {
      ...payload.menuItem,
      batchId: batchId.value,
    }
  }
}

const onIncreaseQuantity = (index: number) => {
  const id = menuItems.value[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  item.quantity++

  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onDecreaseQuantity = (index: number) => {
  const id = menuItems.value[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  if (item.quantity === 1) {
    delete batchMenuItems[id]

    return
  }

  item.quantity--

  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onDelete = (id: string) => {
  delete batchMenuItems[id]
}

const onClearBatchTap = (index: number) => {
  if (!menuItems.value.length) return

  Object.keys(batchMenuItems).forEach((id) => {
    delete batchMenuItems[id]
  })
}

const onSendToWorkTap = () => {
  if (!Object.keys(batchMenuItems).length || !injectedOrderId?.value) return

  sendToWorkInProgress.value = true

  const update: any = {
    $push: {
      batches: {
        id: batchId.value,
        createdAt: new Date().getTime(),
      },
      menuItems: {
        $each: [],
      },
    },
  }

  Object.keys(batchMenuItems).forEach((id) => {
    update.$push.menuItems.$each.push({
      ...batchMenuItems[id],
      status: {
        ...batchMenuItems[id].status,
        name: 'idle',
        idleAt: new Date().getTime(),
      },
    })
  })

  return OrdersStore.patch(injectedOrderId.value, update)
    .then(() => {
      sendToWorkInProgress.value = false

      return pop()
    })
    .catch(async (error) => {
      const toast = await toastController.create({
        message: `Error: ${error.name}. ${error.message}`,
        duration: 3000,
        cssClass: 'toast text-lg-7-semibold',
        position: 'bottom',
        mode: 'ios',
      })

      await toast.present()
    })
}

const onComment = async (index: number, callback: any) => {
  const id = Object.values(batchMenuItems)[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  const alert = await alertController.create({
    header: 'Enter your comment',
    backdropDismiss: false,
    buttons: [
      {
        text: 'Cancel',
      },
      {
        text: item.comment ? 'Update' : 'Add',
        handler: (value) => {
          if (value[0] === '') {
            delete item.comment
          } else {
            item.comment = value[0]
          }

          batchMenuItems[id] = HelpersObjectCleanCopy(item)

          if (callback) callback()
        },
      },
    ],
    inputs: [
      {
        type: 'textarea',
        placeholder: 'Enter text here',
        value: item.comment,
      },
    ],
  })

  await alert.present().then(() => {
    const textarea = document.querySelector('ion-alert textarea')

    if (textarea instanceof HTMLElement) textarea.focus()
  })
}

const onBackButtonClick = async () => {
  if (!Object.keys(batchMenuItems).length) {
    return pop()
  }

  const alert = await alertController.create({
    header: 'Are you sure?',
    message: 'There are non-sent items in the batch.',
    backdropDismiss: false,
    buttons: [
      {
        text: 'Cancel',
      },
      {
        text: 'Go back',
        handler: (value) => {
          pop()
        },
      },
    ],
  })

  await alert.present()
}

onMounted(() => {
  setTimeout(() => {
    if (order.value && !menuItems.value.length) {
      push(OrderModalMenuItems)
    }
  }, 300)
})
</script>
