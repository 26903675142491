<template>
  <AppStack direction="flex-col">
    <template v-if="displayBatchedUi">
      <OrderModalHomeMenuitemsBatchedList
        v-for="(batch, index) in Object.values(batches)"
        :key="batch.batch.id + batch.batch.createdAt + index"
        :name="`BATCH #${index + 1}`"
        :batch="batch"
        :status-changable="props.statusChangable"
        @tap="emit('tap', $event)"
        @status-change="emit('status-change', $event)"
      />
      <OrderModalHomeMenuitemsListButton
        v-if="props.order.status.name === 'created'"
        text="Start Batch"
        @click="emit('start-batch-tap')"
      />
    </template>
    <AppStack v-else direction="flex-col">
      <OrderModalHomeMenuitemsList
        :items="menuItems"
        :status-changable="props.statusChangable"
        class="flex-1"
        @tap="emit('tap', $event)"
        @status-change="emit('status-change', $event)"
      />
      <OrderModalHomeMenuitemsBatchedList
        v-if="props.batchItems.length"
        :batch="{ menuItems: props.batchItems }"
        name="DRAFT BATCH"
        quantity-changable
        :status-changable="props.statusChangable"
        deletable
        @increase-quantity="emit('increase-quantity', $event)"
        @decrease-quantity="emit('decrease-quantity', $event)"
        @delete="emit('delete', $event)"
        @add-comment="emit('add-comment', $event)"
        @tap="emit('tap', $event)"
        @status-change="emit('status-change', $event)"
      />
      <OrderModalHomeMenuitemsListButton
        text="Add Menu Item"
        @click="emit('add-menu-item-tap')"
      />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import { type Stores } from '~/composables/useStores'
import useAppHelpers from '~/composables/useAppHelpers'
import { useAppStore } from '~/stores/app'

import OrderModalHomeMenuitemsBatchedList from './OrderModalHomeMenuitemsBatchedList.vue'
import OrderModalHomeMenuitemsList from './OrderModalHomeMenuitemsList.vue'
import OrderModalHomeMenuitemsListButton from './OrderModalHomeMenuitemsListButton.vue'

const emit = defineEmits([
  'add-menu-item-tap',
  'start-batch-tap',
  'increase-quantity',
  'decrease-quantity',
  'delete',
  'add-comment',
  'status-change',
  'tap',
])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result']>,
    required: true,
  },
  batchItems: {
    type: Array,
    default: () => [],
  },
  statusChangable: {
    type: Boolean,
    default: false,
  },
})

const { mapMenuItem } = useAppHelpers()
const AppStore = useAppStore()

const displayBatchedUi = computed(() => {
  if (props.order.menuItems.length && !props.order.batches?.length) {
    return false
  }

  return AppStore?.selectedStorefront.inHouseService.setup.batches
})

const batches = computed(() => {
  const batchesAsMap = props.order.batches?.reduce(
    (acc: Record<string, Stores['orders']['Result']['batches'][0]>, batch) => {
      if (!acc[batch.id]) acc[batch.id] = batch

      return acc
    },
    {},
  )

  const menuItemsByBatch = props.order.menuItems.reduce(
    (
      acc: Record<
        string,
        {
          batch: any
          menuItems: any[]
        }
      >,
      menuItem,
    ) => {
      if (!menuItem || !menuItem?.batchId) return acc

      if (!acc[menuItem?.batchId]) {
        acc[menuItem?.batchId] = {
          batch: null,
          menuItems: [],
        }
      }

      acc[menuItem?.batchId].batch = batchesAsMap?.[menuItem.batchId]
      acc[menuItem?.batchId].menuItems.push(mapMenuItem(menuItem))

      return acc
    },
    {},
  )

  return menuItemsByBatch
})

const menuItems = computed(() => props.order.menuItems.map(mapMenuItem))
</script>
