<template>
  <ion-modal
    ref="modal"
    :is-open="props.isOpen"
    :presenting-element="props.presentingElement"
    :style="{
      '--backdrop-opacity': 0.9,
    }"
    class="will-change-transform"
    can-dismiss
    @didDismiss="emit('dismiss', false)"
    @willPresent="onModalWillPresent"
    @willDismiss="onModalWillDismiss"
  >
    <ion-nav id="order-modal-nav" ref="navRef" :root="OrderModalHome" />
  </ion-modal>
</template>

<script setup lang="ts">
import { IonModal, IonNav } from '@ionic/vue'
import { PropType, provide, computed, ref } from 'vue'
import { useEventBus } from '@vueuse/core'
import OrderModalHome from './OrderModalHome/index.vue'

const emit = defineEmits(['dismiss', 'update'])
const props = defineProps({
  presentingElement: {
    type: Object as PropType<HTMLElement | null>,
    default: null,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  orderId: {
    type: String as PropType<string | null>,
    default: null,
  },
  objectId: {
    type: String as PropType<string | null>,
    default: null,
  },
})

provide(
  'isOpen',
  computed(() => props.isOpen),
)
provide(
  'orderId',
  computed(() => props.orderId),
)
provide(
  'objectId',
  computed(() => props.objectId),
)
provide(
  'navRef',
  computed(() => navRef?.value?.$el || null),
)

const navRef = ref<InstanceType<typeof IonNav> | null>(null)
const bus = useEventBus<'order-modal-dismiss' | 'order-modal-update', unknown>(
  'app',
)
bus.on(onAppEvent)

function onAppEvent(
  event: 'order-modal-dismiss' | 'order-modal-update',
  payload: unknown,
) {
  if (event === 'order-modal-dismiss') {
    return emit('dismiss', false)
  } else if (event === 'order-modal-update') {
    return emit('update', payload)
  }
}

const onModalWillPresent = ($event: unknown) => {
  $event.target.presentingElement.classList.add('pushed-back')
}

const onModalWillDismiss = ($event: unknown) => {
  $event.target.presentingElement.classList.remove('pushed-back')
}
</script>
