<template>
  <AppStack
    align-items="items-center"
    class="py-[10px] pl-4 pr-3 relative active:opacity-50"
  >
    <AppIcon
      icon="plus"
      size="custom"
      color="text-fg-blue"
      stroke-width="1.8px"
      class="w-[26px] h-[26px]"
    />
    <AppTypography
      :text="props.text"
      name="text-base-5-medium"
      color="text-fg-blue"
      class="ml-2"
    />
  </AppStack>
</template>

<script setup lang="ts">
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
})
</script>
