<template>
  <transition
    name="expand"
    mode="out-in"
    @enter="onConfiguratorEnter"
    @after-enter="onConfiguratorAfterEnter"
    @leave="onConfiguratorLeave"
  >
    <slot
      :styles="{
        'will-change': 'height',
        transform: 'translateZ(0)',
        'backface-visibility': 'hidden',
        perspective: '1000px',
        '--app-animate-height__duration': props.duration,
      }"
    />
  </transition>
</template>

<script setup lang="ts">
const props = defineProps({
  duration: {
    type: String,
    default: '300ms',
  },
})

const onConfiguratorEnter = (element) => {
  const width = getComputedStyle(element).width

  element.style.width = width
  element.style.position = 'absolute'
  element.style.visibility = 'hidden'
  element.style.height = 'auto'

  const height = getComputedStyle(element).height

  element.style.width = null
  element.style.position = null
  element.style.visibility = null
  element.style.height = 0

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = `max(${height}, 260px)`
  })
}

const onConfiguratorAfterEnter = (element) => {
  element.style.height = 'max(auto, 260px)'
}

const onConfiguratorLeave = (element) => {
  const height = getComputedStyle(element).height

  element.style.height = `max(${height}, 260px)`

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height

  requestAnimationFrame(() => {
    element.style.height = 0
  })
}
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: height var(--app-animate-height__duration, 300ms)
    cubic-bezier(0.68, 0.56, 0, 1);
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
