type Handler = (hidden: typeof document.hidden) => any

function handleVisibilityChange(handler: Handler) {
  return () => handler(document.hidden)
}

export const addEventListener = (handler: Handler) => {
  document.addEventListener(
    'visibilitychange',
    handleVisibilityChange(handler),
    false,
  )
}

export const removeEventListener = (handler: Handler) => {
  document.removeEventListener(
    'visibilitychange',
    handleVisibilityChange(handler),
    false,
  )
}
