// Pinia Store
import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import useStores, { type Stores } from '~/composables/useStores'

type activeBreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
// type viewportHeightType = number | null

interface State {
  activeBreakpoint: activeBreakpointType
  selectedStorefrontId: null | string
  loginSheetIsOpen: boolean
  locale: 'en-gb' | 'bg' | 'ru'
  networkConnectionStatus: 'disconnected' | 'reconnecting' | 'connected'
  // viewportHeight: viewportHeightType
}

const cachedSelectedStorefront = useLocalStorage<Partial<StorefrontModel>>(
  'auth/cachedSelectedStorefront',
  {},
)

export const useAppStore = defineStore('app', {
  state: (): State => ({
    activeBreakpoint: 'xs',
    selectedStorefrontId: null,
    loginSheetIsOpen: true,
    locale: 'en-gb',
    networkConnectionStatus: 'connected',
    // viewportHeight: null
  }),
  getters: {
    selectedStorefront(): Stores['storefronts']['Result'] {
      const { storefronts: StorefrontsStore } = useStores()
      const value = StorefrontsStore.getFromStore(
        this.selectedStorefrontId,
      ).value

      if (value) {
        cachedSelectedStorefront.value = value
      }

      return cachedSelectedStorefront.value as Stores['storefronts']['Result']
    },
    loginSheetVisibility(): boolean {
      return this.loginSheetIsOpen
    },
    isOffline(): boolean {
      return ['disconnected', 'reconnecting'].includes(
        this.networkConnectionStatus,
      )
    },
  },
  actions: {
    setActiveBreakpoint(value: activeBreakpointType) {
      this.activeBreakpoint = value
    },
    setLoginSheetVisibility(value: boolean) {
      this.loginSheetIsOpen = value
    },
    setSelectedStorefrontId(storefrontId: string) {
      this.$state.selectedStorefrontId = storefrontId
    },
    setLocale(locale: 'en-gb' | 'bg' | 'ru') {
      this.$state.locale = locale
      this.i18n.locale = locale
    },
    setNetworkConnectionStatus(
      value: 'disconnected' | 'reconnecting' | 'connected',
    ) {
      this.$state.networkConnectionStatus = value
    },
    // setViewportHeight (value: viewportHeightType) {
    //   this.viewportHeight = value
    // },
  },
})
