<template>
  <ion-page>
    <ion-header>
      <ion-toolbar :style="contentStyles">
        <ion-title>
          <AppTypography
            :text="headerTitle"
            name="text-base-6-semibold"
            color="text-fg-elevation-2-primary"
          />
          <AppTypography
            v-if="headerSubTitle"
            :text="headerSubTitle"
            name="text-base-6-semibold"
            color="text-fg-elevation-2-secondary"
            class="ml-[6px]"
          />
        </ion-title>
        <ion-buttons slot="end">
          <ion-button
            v-if="order?.status?.name === 'created'"
            @click="push(OrderModalPromotions)"
          >
            <AppIcon icon="percentage" size="24" color="text-fg-blue" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
      :style="{
        ...contentStyles,
        ...(actionsAreShown
          ? {
              '--overflow': 'hidden',
            }
          : {}),
      }"
      :scroll-y="false"
      class="relative"
    >
      <AppStack direction="flex-col" class="min-h-full h-full">
        <AppStack
          v-if="loading"
          key="loading"
          align-items="items-center"
          justify-content="justify-center"
          flex="flex-1"
          class="h-full"
        >
          <ion-spinner
            :style="{ '--color': 'var(--rf-fg-elevation-0-primary)' }"
          />
        </AppStack>
        <OrderModalHomeCreateOrder
          v-else-if="!order"
          :orderCreationInProgress="orderCreationInProgress"
          class="h-full"
          @create-tap="onCreateTap"
        />
        <template v-else-if="ordersToView.length" key="order">
          <AppStack
            :class="
              actionsAreShown || menuItems.length
                ? ['overflow-hidden']
                : ['overflow-x-auto overflow-y-hidden']
            "
            ref="scrollview"
            direction="flex-row"
            class="scrollview flex-1 h-full snap-x snap-mandatory"
          >
            <OrderModalHomeOrder
              v-for="order in ordersToView"
              :key="order?._id"
              :style="actionsStyles"
              :order="order"
              :actionsAreShown="actionsAreShown"
              :data-id="order?._id"
              :has-multiple-orders="ordersToView.length > 1"
              :menu-items="menuItems"
              ref="slides"
              class="transition-transform duration-300 ease-in-out safe-bottom flex-1 w-full
                min-w-full snap-center snap-always"
              @send-tap="onSendTap"
              @clear-tap="onClearTap"
              @show-actions="onActionsShow"
              @dismiss-actions="onActionsDismiss"
              @increase-quantity="onIncreaseQuantity"
              @decrease-quantity="onDecreaseQuantity"
              @delete="onDelete"
              @add-comment="onComment"
            />
          </AppStack>
          <AppStack
            v-if="ordersToView.length > 1"
            :style="actionsStyles"
            :class="actionsAreShown ? ['opacity-40'] : []"
            justify-items="justify-center"
            class="transition-[transform,opacity] duration-300 pb-5 gap-2 absolute bottom-0 left-0
              z-10 w-full will-change-[transform,opacity] overflow-hidden"
          >
            <div
              v-for="order in ordersToView"
              :key="order?._id"
              :class="
                order?._id === currentlyPreviewedOrderId ? [''] : ['opacity-30']
              "
              class="w-2 h-2 bg-bg-opposite rounded-full transition-opacity"
            />
          </AppStack>
        </template>
        <AppStack
          v-else
          key="else"
          align-items="items-center"
          justify-content="justify-center"
          flex="flex-1"
          class="h-full"
        >
          Could not load order
        </AppStack>
      </AppStack>
      <template v-if="order">
        <div
          :style="actionsStyles"
          class="z-[2] absolute bottom-0 left-0 w-full pointer-events-none
            transition-[transform,opacity] duration-300 ease-in-out"
        >
          <div
            ref="actionsRef"
            class="relative translate-y-full safe-bottom-max bg-bg-elevation-2"
          >
            <OrderModalHomeActions
              :order="order"
              class="pointer-events-auto safe-top-max border-t-[0.5px] border-solid border-b-0
                border-x-0 border-border-elevation-2-secondary"
              @dismiss="onActionsDismiss"
              @order-cancelled="onOrderCancelled"
            />
          </div>
        </div>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
export default { name: 'OrderModalHome' }
</script>

<script setup lang="ts">
import {
  IonPage,
  IonSpinner,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonButtons,
  alertController,
  toastController,
} from '@ionic/vue'
import {
  ref,
  computed,
  watch,
  inject,
  onMounted,
  type ComputedRef,
  reactive,
} from 'vue'
import { useEventBus } from '@vueuse/core'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import { getUniqueValues as HelpersArrayGetUniqueValues } from '@restify/packages/helpers/array'
import useStores, { type Stores } from '~/composables/useStores'
import { useAuthStore } from '~/stores/auth'
import { useIonicNavigation } from '@restify/packages/composables/useIonicNavigation'
import { useShiftsLogic } from '@restify/packages/composables/useShiftsLogic'
import { cleanCopy as HelpersObjectCleanCopy } from '@restify/packages/helpers/object'
import useAppHelpers from '~/composables/useAppHelpers'
import OrderModalPromotions from '~/views/OrderModalPromotions/index.vue'
import OrderModalHomeActions from './OrderModalHomeActions.vue'
import OrderModalHomeCreateOrder from './OrderModalHomeCreateOrder.vue'
import OrderModalHomeOrder from './OrderModalHomeOrder.vue'

const { push } = useIonicNavigation('order-modal-nav')
const bus = useEventBus<
  'order-modal-dismiss' | 'order-modal-update' | 'app-visible',
  unknown
>('app')
const batchBus = useEventBus<'batch-add'>('batch')

batchBus.on(onBatchEvent)

type OrderMenuItem = Stores['orders']['Result']['menuItems'][number]
const {
  users: UsersStore,
  storefrontPlaces: StorefrontPlacesStore,
  orders: OrdersStore,
  promotions: PromotionsStore,
} = useStores()
const AuthStore = useAuthStore()
const { shift, shiftIsHappening } = useShiftsLogic(AuthStore)
const { mapMenuItem } = useAppHelpers()

const observer = ref<IntersectionObserver | null>(null)
const scrollview = ref<HTMLElement | null>(null)
const slides = ref<(HTMLElement | null)[]>([])
const loading = ref(false)
const actionsAreShown = ref(false)
const orderCreationInProgress = ref(false)
const injectedIsOpen = inject<ComputedRef<boolean>>('isOpen')
const injectedOrderId = inject<ComputedRef<string>>('orderId')
const injectedObjectId = inject<ComputedRef<string>>('objectId')
const actionsRef = ref<HTMLElement | null>(null)
const actionsStyles = ref({})
const sendToWorkInProgress = ref(false)
let batchMenuItems = reactive<Record<string, OrderMenuItem>>({})

const ordersToView = computed(() => {
  return injectedObjectId?.value
    ? OrdersStore.findInStore({
        'status.name': {
          $in: ['created', 'checking-out'],
        },
        storefrontPlaceIds: injectedObjectId.value,
        $sort: {
          createdAt: 1,
        },
      })
        .map((item) => item.value)
        .filter((item) => item)
    : OrdersStore.findInStore({
        _id: injectedOrderId?.value,
        $sort: {
          createdAt: 1,
        },
      })
        .map((item) => item.value)
        .filter((item) => item)
})

const currentlyPreviewedOrderId = ref(ordersToView.value[0]?._id)

const order = computed(() => {
  if (loading.value) return null

  return OrdersStore.getFromStore(currentlyPreviewedOrderId.value).value
})

const contentStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
  '--border-color': 'var(--rf-border-elevation-2-primary)',
}))

const headerTitle = computed(() => {
  if (!order?.value) {
    return 'New Order'
  }

  if (injectedObjectId?.value) {
    return storefrontPlace.value
      ? `Table ${storefrontPlace.value?.config.name}`
      : 'Uknown table'
  }

  return order.value ? `Order #${order.value.publicId}` : 'Unknown order'
})

const headerSubTitle = computed(() => {
  if (!order?.value) return false

  return staff.value?.profile.firstName || null
})

const storefrontPlace = computed(() => {
  if (!injectedObjectId?.value) return null

  return StorefrontPlacesStore.getFromStore(injectedObjectId.value).value
})

const staff = computed(() => {
  if (!Array.isArray(order.value?.staff)) return null

  const waiter = order.value?.staff.find((staff) => staff.role === 'waiter')

  return waiter ? UsersStore.getFromStore(waiter.staffId).value : null
})

const menuItems = computed(() =>
  Object.values(batchMenuItems).map((item) => ({
    ...mapMenuItem(item),
    comment: item.comment,
    status: null,
  })),
)

const onSendTap = () => {
  if (!Object.keys(batchMenuItems).length || !currentlyPreviewedOrderId.value)
    return

  sendToWorkInProgress.value = true

  const update: any = {
    $push: {
      menuItems: {
        $each: [],
      },
    },
  }

  Object.keys(batchMenuItems).forEach((id) => {
    update.$push.menuItems.$each.push({
      ...batchMenuItems[id],
      status: {
        ...batchMenuItems[id].status,
        name: 'idle',
        idleAt: new Date().getTime(),
      },
    })
  })

  return OrdersStore.patch(currentlyPreviewedOrderId.value, update)
    .then(onClearTap)
    .finally(() => (sendToWorkInProgress.value = false))
}

const onClearTap = () => {
  Object.keys(batchMenuItems).forEach((key) => delete batchMenuItems[key])
}

const onCreateTap = () => {
  if (!AuthStore.reactiveUser?._id || !injectedObjectId?.value) return

  orderCreationInProgress.value = true

  return OrdersStore.create({
    status: {
      name: 'created',
      createdAt: new Date().getTime(),
    },
    menuItems: [],
    batches: [],
    tabName: '',
    fees: [],
    promotionIds: [],
    type: 'inHouseService',
    storefrontPlaceIds: [storefrontPlace.value?._id],
    staff: [
      {
        shiftId:
          shift.value && shiftIsHappening.value ? shift.value._id : undefined,
        staffId: AuthStore.reactiveUser?._id,
        role: AuthStore.reactiveUser.profile.role,
        processed: false,
      },
    ],
  })
    .then((newOrder) => {
      bus.emit('order-modal-update', {
        orderId: newOrder.value._id,
        ...(injectedObjectId.value
          ? {
              objectId: injectedObjectId.value,
            }
          : {}),
      })
    })
    .catch(async (error) => {
      const toast = await toastController.create({
        message: `Error: ${error.name}. ${error.message}`,
        duration: 3000,
        cssClass: 'toast text-lg-7-semibold',
        position: 'bottom',
        mode: 'ios',
      })

      await toast.present()
    })
    .finally(() => {
      orderCreationInProgress.value = false
    })
}

const onActionsShow = () => {
  if (actionsAreShown.value) return onActionsDismiss()

  const height = actionsRef.value?.clientHeight

  actionsAreShown.value = true
  actionsStyles.value = {
    transform: `translate3d(0, ${-height}px, 0)`,
  }
}

const onActionsDismiss = () => {
  actionsAreShown.value = false
  actionsStyles.value = {}
}

const onOrderCancelled = (orderId) => {
  if (!ordersToView.value.length) {
    bus.emit('order-modal-dismiss')
  } else {
    setTimeout(() => {
      onActionsDismiss()
    }, 200)
  }
}

function onBatchEvent(
  eventName: 'batch-add',
  payload: { menuItem: OrderMenuItem },
) {
  if (eventName === 'batch-add') {
    batchMenuItems[payload.menuItem._id] = { ...payload.menuItem }
  }
}

const onIncreaseQuantity = (index: number) => {
  const id = Object.values(batchMenuItems)[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  item.quantity++

  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onDecreaseQuantity = (index: number) => {
  const id = Object.values(batchMenuItems)[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  if (item.quantity === 1) {
    delete batchMenuItems[id]

    return
  }

  item.quantity--

  batchMenuItems[id] = HelpersObjectCleanCopy(item)
}

const onDelete = (id: string) => {
  delete batchMenuItems[id]
}

const onComment = async (index: number, callback: any) => {
  const id = Object.values(batchMenuItems)[index]._id
  const item = HelpersObjectCleanCopy(batchMenuItems[id])

  const alert = await alertController.create({
    header: 'Enter your comment',
    backdropDismiss: false,
    buttons: [
      {
        text: 'Cancel',
      },
      {
        text: item.comment ? 'Update' : 'Add',
        handler: (value) => {
          if (value[0] === '') {
            delete item.comment
          } else {
            item.comment = value[0]
          }

          batchMenuItems[id] = HelpersObjectCleanCopy(item)

          if (callback) callback()
        },
      },
    ],
    inputs: [
      {
        type: 'textarea',
        placeholder: 'Enter text here',
        value: item.comment,
      },
    ],
  })

  await alert.present().then(() => {
    document.querySelector('ion-alert textarea')?.focus()
  })
}

const fetchData = () => {
  const promises = []

  // Promotions ids and Applied promotions
  const nonLoadedPromotions =
    ordersToView.value
      .reduce((acc: string[], order) => {
        acc.push(...(order?.promotionIds || []))

        return acc
      }, [])
      .filter((id) => !PromotionsStore.getFromStore(id).value) || []
  const nonLoadedAppliedPromotions = ordersToView.value
    .reduce((acc: string[], order) => {
      acc.push(...Object.keys(order?.appliedPromotions || {}))

      return acc
    }, [])
    .filter((id) => !PromotionsStore.getFromStore(id).value)

  if (nonLoadedPromotions.length || nonLoadedAppliedPromotions.length) {
    const promotionIdsToFetch = [
      ...nonLoadedPromotions,
      ...nonLoadedAppliedPromotions,
    ]

    if (promotionIdsToFetch.length) {
      promises.push(
        PromotionsStore.find({
          query: {
            _id: { $in: HelpersArrayGetUniqueValues(promotionIdsToFetch) },
          },
        }),
      )
    }
  }

  // Staff users
  const nonLoadedStaff =
    ordersToView.value
      .reduce((acc: string[], order) => {
        acc.push(...(order?.staff.map((staff) => staff.staffId) || []))

        return acc
      }, [])
      .filter((id) => !UsersStore.getFromStore(id).value) || []

  if (nonLoadedStaff.length) {
    promises.push(
      UsersStore.find({
        query: { _id: { $in: nonLoadedStaff } },
      }),
    )
  }

  // Storefront places
  const nonLoadedStorefrontPlaces =
    ordersToView.value
      .reduce((acc: string[], order) => {
        acc.push(...(order?.storefrontPlaceIds || []))

        return acc
      }, [])
      .filter((id) => !StorefrontPlacesStore.getFromStore(id).value) || []

  if (nonLoadedStorefrontPlaces.length) {
    promises.push(
      StorefrontPlacesStore.find({
        query: { _id: { $in: nonLoadedStorefrontPlaces } },
      }),
    )
  }

  if (!promises.length) return

  loading.value = true

  return Promise.all(promises).finally(() => {
    loading.value = false
  })
}

const onLoadElementCallback: IntersectionObserverCallback = (entries) => {
  requestAnimationFrame(() => {
    entries.map((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
        const id = entry.target.getAttribute('data-id')

        if (id) {
          currentlyPreviewedOrderId.value = id
        }
      }
    })
  })
}

const unobserve = () => {
  if (observer.value) {
    observer.value?.disconnect()
    observer.value = null
  }
}

const observe = () => {
  const scrollviewEl = scrollview.value?.$el || null

  if (!scrollviewEl) return

  if (!observer.value) {
    const options = {
      root: scrollviewEl,
      rootMargin: '0px',
      threshold: 0.8,
    }

    observer.value = new IntersectionObserver(onLoadElementCallback, options)
  }

  ;[...scrollviewEl.children].forEach(observePageEl)
}

const observePageEl = (element: Element) => {
  if (element) observer.value?.observe(element)
}

onMounted(() => {
  observe()

  if (scrollview.value && currentlyPreviewedOrderId.value) {
    const slide = [...slides.value].find((slide) => {
      const slideId = slide?.$el.getAttribute('data-id')

      return slideId === currentlyPreviewedOrderId.value
    })

    if (slide?.$el) {
      const offset = slide?.$el?.offsetLeft

      if (
        scrollview.value?.$el.scrollLeft !== undefined &&
        offset !== undefined
      ) {
        scrollview.value.$el.scrollLeft = offset
      }
    }
  }
})

watch(
  computed(() => loading.value || ordersToView.value),
  () => {
    unobserve()
    observe()
  },
  {
    flush: 'post',
  },
)

watch(
  computed(() => injectedIsOpen?.value),
  (newVal) => newVal && fetchData(),
  { immediate: true },
)

watch(
  ordersToView,
  (newVal) => {
    const id = newVal[0]?._id

    if (id && !currentlyPreviewedOrderId.value) {
      currentlyPreviewedOrderId.value = id
    }
  },
  { immediate: true },
)

watch(
  currentlyPreviewedOrderId,
  (newVal) => {
    bus.emit('order-modal-update', {
      orderId: newVal,
    })
  },
  { immediate: true },
)
</script>

<style scoped>
.scrollview {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollview::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
