<template>
  <AppStack direction="flex-col" class="px-5 gap-3">
    <AppStack direction="flex-col" class="gap-2">
      <AppButton
        v-for="(action, index) in actions"
        :key="index"
        :text="action.text"
        :variant="action.variant || 'outline-secondary'"
        @click="action?.onClick()"
      />
    </AppStack>
    <AppButton text="Close" variant="tertiary" @click="emit('dismiss')" />
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import { type ExtractProps } from '@restify/packages/types/global'
import useStores, { type Stores } from '~/composables/useStores'
import AppButton from '~/components/AppButton.vue'
import { useAuthStore } from '~/stores/auth'
import { useShiftsLogic } from '@restify/packages/composables/useShiftsLogic'

const emit = defineEmits(['dismiss', 'order-cancelled'])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
})

const { orders: OrdersStore } = useStores()
const AuthStore = useAuthStore()
const { shift, shiftIsHappening } = useShiftsLogic(AuthStore)

const currentWaiterId = computed(() => {
  return props.order?.staff.find((staff) => staff.role === 'waiter')?.staffId
})

const actions = computed<
  {
    text: string
    variant?: ExtractProps<typeof AppButton>['variant']
    onClick: () => void
  }[]
>(() => {
  return [
    {
      text: 'Add order to table',
      onClick: () => {
        if (!props.order?.storefrontPlaceIds?.[0]) {
          console.warn('No storefront place to create order')

          return
        }

        if (!AuthStore.reactiveUser) {
          console.warn('No active user')

          return
        }

        OrdersStore.create({
          status: {
            name: 'created',
            createdAt: new Date().getTime(),
          },
          menuItems: [],
          batches: [],
          tabName: '',
          fees: [],
          promotionIds: [],
          type: 'inHouseService',
          storefrontPlaceIds: [props.order?.storefrontPlaceIds[0]],
          staff: [
            {
              shiftId:
                shift.value && shiftIsHappening.value
                  ? shift.value._id
                  : undefined,
              staffId: AuthStore.reactiveUser?._id,
              role: AuthStore.reactiveUser.profile.role as 'waiter',
              processed: false,
            },
          ],
        }).then(() => emit('dismiss'))
      },
    },
    ...(AuthStore.reactiveUser?.profile.role === 'waiter' &&
    currentWaiterId.value !== AuthStore.reactiveUser?._id
      ? [
          {
            text: 'Assign to me',
            onClick: () => {
              if (!props.order || !AuthStore.reactiveUser) return

              const newStaff = {
                shiftId:
                  shift.value && shiftIsHappening.value
                    ? shift.value._id
                    : undefined,
                staffId: AuthStore.reactiveUser._id,
                role: AuthStore.reactiveUser.profile.role,
                processed: false,
              }

              OrdersStore.patch(
                props.order._id,
                currentWaiterId.value
                  ? {
                      $set: {
                        'staff.$': newStaff,
                      },
                    }
                  : {
                      $push: {
                        staff: newStaff,
                      },
                    },
                currentWaiterId.value
                  ? {
                      query: {
                        'staff.staffId': currentWaiterId.value,
                      },
                    }
                  : {},
              )

              emit('dismiss')
            },
          },
        ]
      : []),
    {
      text: 'Cancel Order',
      variant: 'outline-destructive',
      onClick: () => {
        const orderId = props.order?._id

        if (!orderId) {
          return console.warn('No order to cancel')
        }

        OrdersStore.patch(orderId, {
          'status.name': 'cancelled',
          'status.cancelledAt': new Date().getTime(),
        }).then(() => emit('order-cancelled', orderId))
      },
    },
  ]
})
</script>
