<template>
  <component :is="props.tag" v-bind="rootProps">
    <slot>
      <template v-if="props.text">
        {{ props.text }}
      </template>
      <span v-else v-html="props.html" />
    </slot>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  tag: {
    type: String,
    default: 'span',
  },
  html: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  weight: {
    type: String,
    default: 'font-normal',
    // validator: (val: string) => ['font-normal', 'font-semibold', 'font-medium', 'font-bold'].includes(val)
  },
  size: {
    type: String,
    // validator: (val: string) => ['text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl'].includes(val)
  },
  leading: {
    type: String,
    default: 'leading-snug',
    // validator: (val: string) => ['leading-none', 'leading-tight', 'leading-snug', 'leading-normal', 'leading-relaxed', 'leading-loose'].includes(val)
  },
  tracking: {
    type: String,
    default: 'tracking-normal',
    // validator: (val: string) =>
    //   ['tracking-tighter',
    //     'tracking-tight',
    //     'tracking-normal',
    //     'tracking-wide',
    //     'tracking-wider',
    //     'tracking-wides',].includes(val)
  },
  color: {
    type: String,
    default: 'text-inherit',
  },
  name: {
    type: String,
    default: '',
  },
})

const rootProps = computed(() => ({
  class: [
    props.size,
    props.weight,
    props.leading,
    props.color,
    props.type,
    props.name,
    props.size && (props.size.includes('xl') || props.size.includes('lg'))
      ? ['font-brand tracking-tight']
      : [props.tracking],
  ],
}))
</script>
