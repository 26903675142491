import useEmit from '../../../composables/useEmit'

const emitter = useEmit()

export default { name: 'AppNotification' }

export function useNotification() {
  function notifySuccess(heading: string, text?: string, options: any = {}) {
    emitter.emit('notify', {
      heading,
      ...(text ? { text } : {}),
      variant: 'success',
      icon: 'CheckCircleIcon',
      ...options,
    })
  }

  function notifyWarning(heading: string, text?: string, options: any = {}) {
    emitter.emit('notify', {
      heading,
      ...(text ? { text } : {}),
      variant: 'warning',
      icon: 'ExclamationCircleIcon',
      ...options,
    })
  }

  function notifyInfo(heading: string, text?: string, options: any = {}) {
    emitter.emit('notify', {
      heading,
      ...(text ? { text } : {}),
      variant: 'info',
      icon: 'InformationCircleIcon',
      ...options,
    })
  }

  return {
    notifySuccess,
    notifyWarning,
    notifyInfo,
  }
}
