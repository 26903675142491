<template>
  <AppStack
    direction="flex-col"
    align-items="items-stretch"
    justify-content="justify-center"
    flex="flex-1"
  >
    <AppStack
      direction="flex-col"
      align-items="items-center"
      justify-content="justify-center"
      flex="flex-1"
      class="gap-[2px]"
    >
      <AppTypography
        color="text-fg-elevation-2-primary"
        text="No order yet"
        name="text-lg-6-semibold"
        class="relative"
      />
      <AppTypography
        color="text-fg-elevation-2-secondary"
        text="Create order by tapping the button."
        name="text-sm-5-normal"
        class="relative"
      />
    </AppStack>
    <AppStack direction="flex-col" class="safe-bottom-max px-5 mt-auto">
      <AppButton
        text="Create Order"
        :loading="props.orderCreationInProgress"
        class="flex-1"
        @click="emit('create-tap')"
      />
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppButton from '~/components/AppButton.vue'

const emit = defineEmits(['create-tap'])

const props = defineProps({
  orderCreationInProgress: {
    type: Boolean,
    default: false,
  },
})
</script>
