<template>
  <AppStack
    direction="flex-col"
    class="relative gap-2 bg-bg-elevation-2 px-3 pt-[10px] pb-[10px] rounded-[4px]
      shadow-elevation-1-0-xs group"
  >
    <div
      class="absolute top-0 left-0 w-full h-full bg-bg-elevation-2-alt opacity-0
        group-active:opacity-50"
    />
    <AppTypography
      :text="props.title"
      color="text-fg-elevation-2-primary"
      name="text-md-4-semibold"
      class="relative tracking-tighter h-[50px] !leading-4"
    />
    <AppTypography
      :text="props.subtitle"
      color="text-fg-elevation-2-secondary"
      name="text-md-4-medium"
      class="relative mt-auto"
    />
  </AppStack>
</template>

<script setup lang="ts">
import { ref, computed, watch, inject, ComputedRef, onMounted } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
})
</script>
