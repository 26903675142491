export default function useForcePagination(
  model: any,
  params: any,
  response: any,
) {
  const limit = response.limit
  const total = response.total
  const count = response.data.length

  if (total <= count) return response

  const numberOfRequests = Math.ceil((total - count) / limit)
  const requests = Array.from(Array(numberOfRequests).keys()).map((index) =>
    model.find({
      ...params,
      query: { ...params.query, $skip: limit * (index + 1) },
    }),
  )

  return Promise.all(requests).then((paginationResponses) => {
    const result = paginationResponses.reduce((acc, paginationResponse) => {
      if (!acc.data) {
        acc.data = [...response.data]
        acc.total = acc.data.length
      }

      acc.data = acc.data.concat(paginationResponse.data)
      acc.total = acc.data.length

      return acc
    }, {})

    return result
  })
}
