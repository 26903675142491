<template>
  <AppStack
    direction="flex-col"
    class="px-4"
    :class="props.hasMultipleOrders ? ['pb-6'] : []"
  >
    <AppStack v-if="!props.batchItems.length" direction="flex-col">
      <AppStack align-items="items-baseline" class="mb-[10px]">
        <AppStack align-items="items-baseline">
          <AppTypography
            :text="formatPrice(orderTotal.toFixed(2))"
            name="heading-2xl-8-bold"
            color="text-fg-elevation-2-primary"
          />
        </AppStack>
        <AppStack
          v-if="
            Object.keys(props.order?.appliedPromotions?.promotions || {}).length
          "
          align-items="items-center"
          class="ml-auto"
        >
          <AppTypography
            :text="formatPrice(-promotionsTotalDiscount)"
            name="text-lg-7-medium"
            color="text-fg-elevation-2-secondary"
          />
          <AppIcon
            icon="percentage"
            size="custom"
            color="text-fg-elevation-2-secondary"
            stroke-width="1.8px"
            class="w-[22px] h-[22px] ml-1"
          />
        </AppStack>
      </AppStack>
    </AppStack>
    <AppStack v-if="!props.batchItems.length" class="gap-2">
      <AppButton
        :disabled="checkoutIsDisabled"
        :loading="sendToWorkInProgress"
        text="Checkout"
        variant="outline-secondary"
        class="flex-1"
      />
      <AppButton variant="outline-secondary" @click="emit('actions-tap')">
        <AppIcon
          color="text-fg-elevation-3-secondary"
          icon="more-horiz"
          size="custom"
          stroke-width="2px"
          class="w-[30px] h-[30px] relative"
        />
      </AppButton>
    </AppStack>
    <AppStack v-else class="gap-2">
      <AppButton text="Send to work" class="flex-1" @click="emit('send-tap')" />
      <AppButton variant="outline-secondary" @click="emit('clear-tap')">
        <AppIcon
          color="text-fg-elevation-3-secondary"
          icon="bin-minus"
          size="custom"
          class="w-[30px] h-[30px] relative"
        />
      </AppButton>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppButton from '~/components/AppButton.vue'
import useAppHelpers from '~/composables/useAppHelpers'
import { type Stores } from '~/composables/useStores'

const emit = defineEmits(['actions-tap', 'clear-tap', 'send-tap'])
const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    default: null,
  },
  batchItems: {
    type: Array,
    default: () => [],
  },
  sendToWorkInProgress: {
    type: Boolean,
    default: false,
  },
  hasMultipleOrders: {
    type: Boolean,
    default: false,
  },
})

const { formatPrice, countPromotionTotal } = useAppHelpers()

const checkoutIsDisabled = computed(() => {
  return true
})

const orderTotal = computed(() => {
  return typeof props?.order?.appliedPromotions?.total === 'number'
    ? props.order?.appliedPromotions?.total || 0
    : props.order?.total || 0
})

const promotionsTotalDiscount = computed(() => {
  return props.order ? countPromotionTotal(props.order) : 0
})
</script>
