<template>
  <AppStack
    v-if="props.loading"
    align-items="items-center"
    justify-content="justify-center"
    flex="flex-1"
  >
    <ion-spinner :style="{ '--color': 'var(--rf-fg-elevation-0-primary)' }" />
  </AppStack>
  <AppStack
    v-else-if="props.order && props.categories.length"
    direction="flex-col"
    justify-items="justify-end"
    class="min-h-full"
  >
    <AppStack
      wrap="flex-wrap"
      align-items="items-stretch"
      class="px-4 pb-4 gap-2"
    >
      <OrderModalMenuItemsCategoryCell
        v-for="category in props.categories"
        :key="category.id"
        :title="category.title"
        :subtitle="`${category.menuItems.length || 0} items`"
        class="flex-1 min-w-[45%]"
        @click="emit('category-tap', category.id)"
      />
    </AppStack>
  </AppStack>
  <AppStack
    v-else
    align-items="items-center"
    justify-content="justify-center"
    flex="flex-1"
    class="h-full"
  >
    Could not load order
  </AppStack>
</template>

<script setup lang="ts">
import { IonSpinner } from '@ionic/vue'
import { PropType } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import { useIonicNavigation } from '@restify/packages/composables/useIonicNavigation'
import { type Stores } from '~/composables/useStores'
import OrderModalMenuItemsCategoryCell from './OrderModalMenuItemsCategoryCell.vue'

const emit = defineEmits(['category-tap'])
const props = defineProps({
  categories: {
    type: Array as PropType<
      {
        id: string
        title: string
        subtitle: string
      }[]
    >,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    required: true,
  },
})

const { pop } = useIonicNavigation('order-modal-nav')
</script>
