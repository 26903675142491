<template>
  <AppStack ref="root" direction="flex-col" class="h-full">
    <AppStack
      v-if="props.loading"
      align-items="items-center"
      justify-content="justify-center"
      flex="flex-1"
    >
      <ion-spinner :style="{ '--color': 'var(--rf-fg-elevation-0-primary)' }" />
    </AppStack>
    <AppStack
      v-else
      direction="flex-col"
      justify-items="justify-end"
      class="flex-1 relative"
    >
      <AppStack
        v-if="props.order && props.menuItems.length"
        wrap="flex-wrap"
        direction="flex-row-reverse"
        align-items="items-stretch"
        class="px-[13px] pb-3"
      >
        <AppAnimateHeight duration="150ms">
          <template #default="{ styles }">
            <div
              v-if="configuratorIsOpen"
              ref="configuratorRef"
              :key="String(configurableMenuItemId + configurableMenuItemIndex)"
              :style="{
                ...styles,
                order:
                  Math.ceil(configurableMenuItemIndex / numberOfColumns) * 100 +
                  1,
              }"
              class="ml-[-13px] mr-[-13px] w-[100vw] relative z-10 overflow-hidden"
            >
              <OrderModalMenuItemsConfigurator
                :current-step="configuratorProps.currentStep"
                :total-steps="configuratorProps.totalSteps"
                :options="configuratorProps.options"
                :title="configuratorProps.title"
                class="h-full py-[17px] overflow-hidden"
                @menu-item-option-tap="onMenuItemOptionTap($event)"
              />
            </div>
          </template>
        </AppAnimateHeight>
        <div
          v-for="(menuItem, index) in props.menuItems"
          :key="String(menuItem._id) + index"
          :style="{ order: Math.ceil((index + 1) / numberOfColumns) * 100 }"
          :class="columnWidthClass"
          :data-item-id="String(menuItem._id) + index"
          class="p-[4px] flex-1 flex flex-col scroll-mt-[116px]"
        >
          <OrderModalMenuItemsItemCell
            :title="translateField(menuItem.name)"
            :subtitle="`${formatPrice(menuItem.consumerPrice)}`"
            :selected="
              configurableMenuItemId === menuItem._id &&
              configurableMenuItemIndex === index + 2
            "
            :faded="
              configurableMenuItemId
                ? configurableMenuItemId !== menuItem._id ||
                  configurableMenuItemIndex !== index + 2
                : false
            "
            class="z-10 flex-1"
            @menu-item-tap="onMenuItemTap(menuItem, index, $event)"
            @menu-item-long-tap="onMenuItemTap(menuItem, index, $event)"
          />
        </div>
        <!-- <div
          v-for="index in numberOfColumns - props.menuItems.length % numberOfColumns"
          :key="index"
          :style="{ order: index }"
          :class="columnWidthClass"
          class="flex-1 p-[4px]"
        /> -->
        <div
          v-if="
            configurableMenuItem &&
            configurableMenuItemId !== null &&
            configurableMenuItemIndex !== null
          "
          class="absolute top-0 left-0 w-full h-full"
          @click="onCloseConfigurator"
        />
      </AppStack>
      <AppStack
        v-else
        align-items="items-center"
        justify-content="justify-center"
        flex="flex-1"
        class="h-full"
      >
        No items in category
      </AppStack>
    </AppStack>
  </AppStack>
</template>

<script setup lang="ts">
import { IonSpinner } from '@ionic/vue'
import { ref, computed, PropType, reactive, watch, nextTick } from 'vue'
import { nanoid } from 'nanoid'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppAnimateHeight from '@restify/packages/design-system/mid-level/AppAnimateHeight.vue'
import { cleanCopy as HelpersObjectCleanCopy } from '@restify/packages/helpers/object'
import useAppHelpers from '~/composables/useAppHelpers'
import { type Stores } from '~/composables/useStores'
import { useAuthStore } from '~/stores/auth'
import OrderModalMenuItemsItemCell from './OrderModalMenuItemsItemCell.vue'
import OrderModalMenuItemsConfigurator from './OrderModalMenuItemsConfigurator.vue'

const emit = defineEmits([
  'back-tap',
  'menu-item-tap',
  'configurator-opened',
  'configurator-closed',
])
const props = defineProps({
  menuItems: {
    type: Array as PropType<Stores['menuItems']['Result'][]>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    required: true,
  },
})

const AuthStore = useAuthStore()
const { translateField, formatPrice } = useAppHelpers()

const root = ref(null)
const configuratorRef = ref(null)
const configurableMenuItemId = ref<string | null>(null)
const configurableMenuItemIndex = ref<number | null>(null)
let configuratorNavigation = reactive<{
  totalStepsVariations: number
  currentStepVariations: number
  totalStepsExtras: number
  currentStepExtras: number
  stepType: 'variations' | 'extras'
  menuItem: Stores['orders']['Result'] | null
  comment: string | null
  onClick: (() => void) | null
}>({
  totalStepsVariations: 0,
  currentStepVariations: 0,
  totalStepsExtras: 0,
  currentStepExtras: 0,
  stepType: 'variations',
  menuItem: null,
  comment: null,
  onClick: null,
})

const numberOfColumns = computed(() => {
  return props.menuItems.length > 12 ? 3 : 2
})

const columnWidthClass = computed(() => {
  return props.menuItems.length > 12 ? ['min-w-[32%]'] : ['min-w-[50%]']
})

const configurableMenuItem = computed(() => {
  return props.menuItems.find(
    (item) => item._id === configurableMenuItemId.value,
  )
})

const configuratorIsOpen = computed(() => {
  return (
    configurableMenuItem.value &&
    configurableMenuItemId.value !== null &&
    configurableMenuItemIndex.value !== null
  )
})

const configuratorProps = computed(() => {
  const totalSteps =
    configuratorNavigation.totalStepsVariations +
    configuratorNavigation.totalStepsExtras
  const currentStep =
    configuratorNavigation.currentStepVariations +
    configuratorNavigation.currentStepExtras
  const title =
    configuratorNavigation.stepType === 'variations'
      ? translateField(
          configurableMenuItem.value?.variations[
            configuratorNavigation.currentStepVariations - 1
          ].name,
        )
      : translateField(
          configurableMenuItem.value?.extras[
            configuratorNavigation.currentStepExtras - 1
          ].name,
        )

  return {
    title,
    totalSteps,
    currentStep,
    options:
      configurableMenuItem.value?.[configuratorNavigation.stepType]?.[
        {
          variations: configuratorNavigation.currentStepVariations - 1,
          extras: configuratorNavigation.currentStepExtras - 1,
        }[configuratorNavigation.stepType]
      ]?.options || [],
  }
})

const onCloseConfigurator = () => {
  configurableMenuItemId.value = null
  configurableMenuItemIndex.value = null
  configuratorNavigation = Object.assign(configuratorNavigation, {
    totalStepsVariations: 0,
    currentStepVariations: 0,
    totalStepsExtras: 0,
    currentStepExtras: 0,
    stepType: 'variations',
    menuItem: null,
    comment: null,
    onClick: null,
  })
}

const onMenuItemOptionTap = ({
  option,
  onClick,
}: {
  option:
    | Stores['menuItems']['Result']['variations'][0]['options'][0]
    | Stores['menuItems']['Result']['extras'][0]['options'][0]
  onClick: unknown
}) => {
  const menuItem = configurableMenuItem.value
  const type = configuratorNavigation.stepType
  const variationOrExtra =
    configurableMenuItem.value?.[configuratorNavigation.stepType]?.[
      {
        variations: configuratorNavigation.currentStepVariations - 1,
        extras: configuratorNavigation.currentStepExtras - 1,
      }[configuratorNavigation.stepType]
    ]

  if (!variationOrExtra || !menuItem) return

  const configuration = {
    id: variationOrExtra._id,
    optionId: String(option._id),
    name: variationOrExtra.name,
    optionName: option.name,
    price: option.price,
  }

  const item: OrderMenuItem = {
    _id: nanoid(),
    menuItemId: menuItem._id,
    name: menuItem.name,
    quantity: 1,
    price: menuItem.consumerPrice,
    status: {
      name: 'idle',
    },
    removables: [],
    variations: [...(configuratorNavigation.menuItem?.variations || [])],
    extras: [...(configuratorNavigation.menuItem?.extras || [])],
    ...(configuratorNavigation?.comment
      ? {
          comment: configuratorNavigation.comment,
        }
      : {}),
    ...getMenuItemTaxInfo(menuItem),
  }

  item[type].push(configuration)

  configuratorNavigation.menuItem = HelpersObjectCleanCopy(item)

  if (
    configuratorProps.value.currentStep === configuratorProps.value.totalSteps
  ) {
    const onClick = configuratorNavigation.onClick

    onCloseConfigurator()

    return emit('menu-item-tap', {
      menuItem: item,
      onClick,
    })
  }

  if (
    configuratorNavigation.currentStepVariations ===
    configuratorNavigation.totalStepsVariations
  ) {
    configuratorNavigation.stepType = 'extras'
    configuratorNavigation.currentStepExtras =
      configuratorNavigation.currentStepExtras + 1

    return
  }

  if (configuratorNavigation.stepType === 'variations') {
    configuratorNavigation.currentStepVariations = Math.min(
      configuratorNavigation.currentStepVariations + 1,
      configuratorNavigation.totalStepsVariations,
    )
  }

  if (configuratorNavigation.stepType === 'extras') {
    configuratorNavigation.currentStepExtras = Math.min(
      configuratorNavigation.currentStepExtras + 1,
      configuratorNavigation.totalStepsExtras,
    )
  }
}

const getMenuItemTaxInfo = (menuItem: any) => {
  if (!AuthStore.userApp || !menuItem?.taxId) return {}

  const tax = AuthStore.userApp?.taxes.find((t) => t._id === menuItem.taxId)

  if (!tax) return {}

  return {
    taxGroup: tax.taxGroup,
    taxPercentage: tax.percentage,
  }
}

const onMenuItemTap = (
  menuItem: Stores['menuItems']['Result'],
  index: number,
  $event: () => void,
) => {
  if (configurableMenuItemId.value) {
    return onCloseConfigurator()
  }

  if (
    menuItem.variations.length ||
    menuItem.extras.length
    // menuItem.extras.length ||
    // menuItem.products.some(product => product.removable)
  ) {
    configurableMenuItemId.value = menuItem._id
    configurableMenuItemIndex.value = index + 2

    configuratorNavigation.totalStepsVariations = menuItem.variations.length
    configuratorNavigation.totalStepsExtras = menuItem.extras.length
    configuratorNavigation.onClick = $event.onClick
    configuratorNavigation.comment = $event?.comment || null

    if (menuItem.variations.length) {
      configuratorNavigation.stepType = 'variations'
      configuratorNavigation.currentStepVariations = 1
    } else if (menuItem.extras.length) {
      configuratorNavigation.stepType = 'extras'
      configuratorNavigation.currentStepExtras = 1
    }

    return
  }

  const item: OrderMenuItem = {
    _id: nanoid(),
    menuItemId: menuItem._id,
    name: menuItem.name,
    internalName: menuItem.internalName,
    quantity: 1,
    price: menuItem.consumerPrice,
    status: {
      name: 'idle',
    },
    removables: [],
    variations: [],
    extras: [],
    ...($event?.comment
      ? {
          comment: $event.comment,
        }
      : {}),
    ...getMenuItemTaxInfo(menuItem),
  }

  onCloseConfigurator()

  return emit('menu-item-tap', { menuItem: item, onClick: $event.onClick })
}

watch(
  computed(() => configuratorIsOpen.value),
  (newVal, oldVal) => {
    if (
      newVal &&
      !oldVal &&
      configurableMenuItemId.value &&
      configurableMenuItemIndex.value &&
      root.value?.$el
    ) {
      const menuItemElement = root.value?.$el.querySelector(
        `[data-item-id="${String(configurableMenuItemId.value) + (configurableMenuItemIndex.value - 2)}"]`,
      )

      // got opened
      nextTick(() => {
        setTimeout(() => {
          emit('configurator-opened', menuItemElement)
        }, 100)
      })
    }

    if (oldVal && !newVal) {
      nextTick(() => {
        setTimeout(() => {
          emit('configurator-closed')
        }, 100)
      })
    }
  },
)
</script>
