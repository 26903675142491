<template>
  <IonContent
    :scroll-x="false"
    :scroll-y="true"
    :style="contentStyles"
    ref="scrollview"
    class="flex-1"
  >
    <AppStack
      ref="scrollviewContent"
      direction="flex-col"
      class="flex-1 relative"
    >
      <OrderModalHomeMenuItemsWrapper
        :order="props.order"
        :batch-items="menuItems"
        status-changable
        class="flex-1 transition-opacity duration-200 ease-in-out"
        @add-menu-item-tap="push(OrderModalMenuItems)"
        @start-batch-tap="push(OrderModalBatch)"
        @increase-quantity="emit('increase-quantity', $event)"
        @decrease-quantity="emit('decrease-quantity', $event)"
        @delete="emit('delete', $event)"
        @tap="onMenuItemTap"
        @status-change="onStatusChange"
        @add-comment="emit('add-comment', $event)"
      />
      <div class="h-[180px] min-h-[180px]" />
      <div
        :class="
          props.actionsAreShown
            ? ['opacity-60']
            : ['opacity-0', 'pointer-events-none']
        "
        class="absolute top-0 left-0 w-full h-full z-[10] bg-bg-elevation-2 transition-opacity
          duration-200 ease-in-out"
        @click="props.actionsAreShown && emit('dismiss-actions')"
      />
      <div
        :style="{ transform: 'translate3d(0, 0, 0)', ...props.actionsStyles }"
        class="transition-transform duration-300 ease-in-out z-[10] sticky bottom-0 left-0
          w-full safe-bottom-max bg-bg-elevation-2"
      >
        <div
          class="absolute top-[-90px] left-0 w-full h-[90px] pointer-events-none bg-gradient-to-b
            to-bg-elevation-2 from-transparent"
        />
        <OrderModalHomeFooter
          :order="order"
          :has-multiple-orders="props.hasMultipleOrders"
          :batch-items="(!displayBatchedUi && menuItems) || []"
          :send-to-work-in-progress="sendToWorkInProgress"
          @actions-tap="emit('show-actions')"
          @clear-tap="emit('clear-tap')"
          @send-tap="emit('send-tap')"
        />
        <div
          :class="
            props.actionsAreShown
              ? ['opacity-60']
              : ['opacity-0', 'pointer-events-none']
          "
          class="absolute top-0 left-0 w-full h-full z-[10] bg-bg-elevation-2 transition-opacity
            duration-200 ease-in-out"
          @click="props.actionsAreShown && emit('dismiss-actions')"
        />
      </div>
    </AppStack>
  </IonContent>
</template>

<script lang="ts">
export default { name: 'OrderModalHomeOrder' }
</script>

<script setup lang="ts">
import { ref, computed, type PropType } from 'vue'
import { useBottomScroll } from '@restify/packages/composables/useBottomScroll'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import useStores, { type Stores } from '~/composables/useStores'
import { useAuthStore } from '~/stores/auth'
import { IonContent } from '@ionic/vue'
import { useIonicNavigation } from '@restify/packages/composables/useIonicNavigation'
import OrderModalMenuItemControls from '~/views/OrderModalMenuItemControls/index.vue'
import OrderModalMenuItems from '~/views/OrderModalMenuItems/index.vue'
import OrderModalHomeFooter from './OrderModalHomeFooter.vue'
import OrderModalBatch from '~/views/OrderModalBatch/index.vue'
import OrderModalHomeMenuItemsWrapper from './OrderModalHomeMenuItemsWrapper.vue'
import { useAppStore } from '~/stores/app'

const { push } = useIonicNavigation('order-modal-nav')

const emit = defineEmits([
  'dismiss-actions',
  'show-actions',
  'clear-tap',
  'send-tap',
  'increase-quantity',
  'decrease-quantity',
  'delete',
  'add-comment',
])

const props = defineProps({
  order: {
    type: Object as PropType<Stores['orders']['Result'] | null>,
    required: true,
  },
  sendToWorkInProgress: {
    type: Boolean,
    default: false,
  },
  actionsAreShown: {
    type: Boolean,
    default: false,
  },
  hasMultipleOrders: {
    type: Boolean,
    default: false,
  },
  actionsStyles: {
    type: Object,
    default: () => ({}),
  },
  menuItems: {
    type: Array,
    default: () => [],
  },
})

const { orders: OrdersStore } = useStores()
const AppStore = useAppStore()
const AuthStore = useAuthStore()

const scrollview = ref(null)
const scrollviewContent = ref(null)

useBottomScroll(scrollview, scrollviewContent)

const contentStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-2)',
}))

const displayBatchedUi = computed(() => {
  if (!props.order) return false

  if (props.order.menuItems.length && !props.order?.batches?.length) {
    return false
  }

  return AppStore?.selectedStorefront.inHouseService.setup.batches
})

const onMenuItemTap = (id: string) => {
  if (AuthStore.reactiveUser?.profile.role !== 'manager') return

  const menuItemId = props.order?.menuItems.find(
    (item) => item._id === id,
  )?.menuItemId

  if (!menuItemId) return

  push(OrderModalMenuItemControls, {
    id: id,
    menuItemId: menuItemId,
  })
}

const onStatusChange = (payload: { status: string; id: string }) => {
  if (!props.order?._id) return

  OrdersStore.patch(
    props.order?._id,
    {
      $set: {
        [`menuItems.$.status.name`]: payload.status,
        [`menuItems.$.status.${payload.status}At`]: Date.now(),
      },
    },
    {
      query: {
        'menuItems._id': payload.id,
      },
    },
  )
}
</script>
