<template>
  <AppStack
    tag="section"
    direction="flex-col"
    flex="flex-1"
    class="relative safe-top"
  >
    <div class="pt-8">
      <AppStack
        justify-content="justify-between"
        align-items="items-center"
        class="relative h-[37px]"
      >
        <AppFadeTransition>
          <AppStack
            v-if="screen === 'numpad'"
            tag="button"
            align-items="items-center"
            class="absolute left-5 top-1/2 -translate-y-1/2"
            @click="onLogoutClick"
          >
            <AppIcon
              icon="log-out"
              stroke-width="1.8"
              color="text-fg-elevation-0-tertiary scale-x-[-1]"
              class="w-[28px] h-[28px]"
            />
          </AppStack>
        </AppFadeTransition>
        <span
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          @click="onLogoClick"
        >
          <AppLogoShort class="w-10" />
        </span>
        <AppFadeTransition>
          <AppStack
            v-if="screen === 'email'"
            tag="button"
            align-items="items-center"
            class="absolute right-5 top-1/2 -translate-y-1/2"
            @click="onLogInClick"
          >
            <AppIcon
              icon="arrow-right"
              stroke-width="1.8"
              :color="
                form.login ? 'text-fg-blue' : 'text-fg-elevation-0-tertiary'
              "
              class="w-[28px] h-[28px]"
            />
          </AppStack>
        </AppFadeTransition>
      </AppStack>
    </div>
    <AppStack
      direction="flex-col"
      justify-content="justify-center"
      flex="flex-1"
    >
      <AppViewAdjustedToKeyboard position="center">
        <AppFadeTransition>
          <AppStack
            v-if="screen === 'numpad'"
            direction="flex-col"
            align-items="items-center"
            justify-content="justify-center"
            class="text-center"
          >
            <AppStack class="gap-4 mb-14">
              <div
                v-for="(circle, index) in 6"
                :key="circle"
                :class="
                  form.pincode[index] === undefined
                    ? ['bg-fg-elevation-0-tertiary', 'opacity-50']
                    : ['bg-fg-blue']
                "
                class="w-[14px] h-[14px] rounded-full transition-all duration-300"
              />
            </AppStack>
            <AppLoginNumpad
              :loading="isLoading"
              @key="onNumpadKey"
              @delete="onNumpadDelete"
            />
          </AppStack>
          <AppLoginEmail v-else @input="onEmailInput" @submit="onEmailSubmit" />
        </AppFadeTransition>
      </AppViewAdjustedToKeyboard>
    </AppStack>
  </AppStack>
</template>

<script lang="ts">
export default { name: 'Login' }
</script>

<script setup lang="ts">
import { toastController, alertController } from '@ionic/vue'
import { ref, reactive, watch, computed } from 'vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppFadeTransition from '@restify/packages/design-system/mid-level/AppFadeTransition.vue'
import AppLogoShort from '@restify/packages/design-system/mid-level/AppLogoShort.vue'
import AppViewAdjustedToKeyboard from '@restify/packages/components/AppViewAdjustedToKeyboard.vue'
import AppLoginNumpad from '~/components/AppLogin/AppLoginNumpad.vue'
import AppLoginEmail from '~/components/AppLogin/AppLoginEmail.vue'
import { useAuthStore } from '~/stores/auth'
import { useAppStore } from '~/stores/app'
import startupDataSetup from '~/setup/startupDataSetup'
import packageJson from '../../../package.json'

const emit = defineEmits(['login'])

let cachedUsers = []

try {
  const value = window.localStorage.getItem('preferredEmails')

  cachedUsers = value
    ? JSON.parse(window.localStorage.getItem('preferredEmails')) || []
    : []
} catch (error) {
  console.log(error)
}

const preferredUsers = ref(cachedUsers || [])

const AppStore = useAppStore()

const isLoading = ref(false)
const screen = ref<'email' | 'users' | 'numpad'>(
  preferredUsers.value.length ? 'users' : 'email',
)
const form = reactive({
  strategy: 'local',
  login: '',
  pincode: '',
})

watch(
  computed(() => form.pincode),
  (newVal, oldVal) => {
    if (newVal.length !== 6) return

    doLogin()
  },
)

const doLogin = () => {
  isLoading.value = true

  return useAuthStore()
    .authenticate({
      email: form.login.includes('@')
        ? form.login
        : form.login + '@restify.app',
      password: form.pincode,
      strategy: 'local',
    })
    .then(() => startupDataSetup())
    .then(() => emit('login'))
    .finally(() => (isLoading.value = false))
    .catch(async (error) => {
      const toast = await toastController.create({
        message: `Error: ${error.name}. ${error.message}`,
        duration: 3000,
        cssClass: 'toast text-lg-7-semibold',
        position: 'bottom',
        mode: 'ios',
      })

      await toast.present()

      form.pincode = ''
    })
}

const onLogInClick = ($event?) => {
  screen.value = 'numpad'
}

const onLogoutClick = ($event) => {
  screen.value = 'users'
  form.login = ''
  form.pincode = ''
}

const onEmailInput = ($event) => {
  form.login = $event
}

const onEmailSubmit = ($event) => {
  return onSubmitClick()
}

const onSubmitClick = ($event?) => {
  if (form.login.length >= 3) {
    return onLogInClick()
  }
}

const onNumpadKey = ($event) => {
  form.pincode.length < 6 && (form.pincode += $event)
}

const onNumpadDelete = ($event) => {
  form.pincode = ''
}

const onLogoClick = async () => {
  const alert = await alertController.create({
    header: 'Version',
    message: `${packageJson.version}`,
    buttons: ['Close'],
  })

  await alert.present()
}
</script>

<style>
.toast {
  --border-radius: 10px;
  --background: var(--rf-bg-elevation-3);
  --box-shadow: var(--rf-shadow-elevation-2-0-lg);
}
</style>
