import { type ClientService } from '@feathersjs/feathers'
import { defineStore } from 'pinia'
import sift from 'sift'
import { computed, ref, type Ref } from 'vue'
import type { FeathersService } from '@feathersjs/feathers'
import type { Params as FeathersParams } from 'feathers-pinia/dist'
import { cleanCopy } from '../helpers/object'

export interface Paginated<T> {
  total: number
  limit: number
  skip: number
  data: Array<T>
}

export interface FeathersQuery {
  $sort?: {
    [prop: string]: number
  }
  $limit?: number
  $skip?: number
  $select?: string[]
  [key: string]: any
}

export const useServiceStoreFactory = <
  Result,
  Data,
  PatchData,
  Query extends FeathersQuery,
>(
  service: FeathersService,
  name: string,
  idField: '_id',
  modelDefaults: () => Data,
) => {
  return defineStore(`feathers:${name}`, () => {
    type R = {
      _id: string
      [key: string]: any
    }

    const Result = {} as Result
    const Data = {} as Data
    const PatchData = {} as PatchData
    const Query = {} as Query

    const prepareQueryForSift = (query: Query) => {
      const queryCopy = {
        ...query,
      }

      delete queryCopy.$limit
      delete queryCopy.$skip
      delete queryCopy.$sort

      return queryCopy
    }

    const _items = ref<Ref<R>[]>([])

    service.on('created', (result: R) => {
      const item = _itemsById.value[result[idField]]

      if (!item?.value) {
        _items.value.push(ref(cleanCopy(result)))
      }
    })

    service.on('removed', (result: R) => {
      removeFromStore(result[idField])
    })

    service.on('patched', (result: R) => {
      const item = _itemsById.value[result[idField]]

      if (item?.value) item.value = cleanCopy(result)
    })

    const _itemsById = computed(() =>
      _items.value.reduce<Record<string, Ref<R>>>(
        (acc, item) => {
          const i = item as unknown as Ref<R>
          const id = i.value?.[idField]

          if (i?.value && id) acc[id] = i

          return acc
        },
        {} as Record<R['_id'], Ref<R>>,
      ),
    )

    const itemsById = computed(
      () => _itemsById.value as Record<R['_id'], Ref<Result>>,
    )

    function _updateStoreItem(payload: any) {
      if (payload[idField] in _itemsById.value) {
        const item = _itemsById.value[payload[idField]]

        item.value = cleanCopy(payload)

        return item as Ref<Result>
      } else {
        const item = ref(payload)

        _items.value.push(item)

        return item as Ref<Result>
      }
    }

    function find(
      params?: FeathersParams<Query>,
    ): Promise<Paginated<Ref<Result | undefined>>> {
      return (service as ClientService).find(params).then((result) => {
        const items: Ref<R>[] = []

        result.data.forEach((entry: R) => {
          const element = _updateStoreItem(entry)

          items.push(element as Ref<R>)
        })

        return {
          ...result,
          data: items as Ref<Result | undefined>[],
        }
      })
    }

    function get(
      id: string | null,
      params?: FeathersParams<Query>,
    ): Promise<Ref<Result | undefined>> {
      if (id && !params) {
        const item = getFromStore(id)

        if (item.value) return Promise.resolve(item)
      }

      return (service as ClientService)
        .get(id as string, params)
        .then((result) => {
          // TODO: support multi get

          if (id) {
            _updateStoreItem(result)
          }

          return getFromStore(id, params?.query)
        })
    }

    function create<
      T extends Data | Data[],
      R = T extends Array<Data> ? Promise<Ref<Result>> : Promise<Ref<Result>[]>,
    >(data: T, params?: FeathersParams<Query>): R {
      return (service as ClientService)
        .create(data as Partial<Result> | Partial<Result>[], params)
        .then((result) => {
          if (Array.isArray(result)) {
            return result.map(_updateStoreItem)
          } else {
            return _updateStoreItem(result)
          }
        }) as R
    }

    function patch<
      T extends null | string,
      R = T extends string ? Promise<Ref<Result>> : Promise<Ref<Result>[]>,
    >(
      id: T,
      data?: PatchData,
      params?: FeathersParams<Query>,
      inStoreFirst?: boolean,
    ): R {
      const item = id && _itemsById.value[id].value
      const originalItem = cleanCopy(item)

      if (id && data && item && inStoreFirst) {
        if (item) {
          Object.keys(data).forEach((key: string) => {
            // @ts-ignore
            item[key] = data[key]
          })
        }
      }

      return (service as ClientService)
        .patch(id, data as Partial<Result>, params)
        .catch((error) => {
          if (id && originalItem && inStoreFirst) {
            // @ts-ignore
            const item = _itemsById.value[id]
            item.value = cleanCopy(originalItem)
            console.warn('Reverting local state change: API returned an error')
          }

          throw error
        })
        .then((result) => {
          if (Array.isArray(result)) {
            return result.map(_updateStoreItem)
          } else {
            return _updateStoreItem(result)
          }
        }) as R
    }

    function remove<
      T extends null | string,
      R = T extends string ? Promise<Result> : Promise<Result[]>,
    >(id: T, params?: FeathersParams<Query>): R {
      return (service as ClientService).remove(id, params) as R
    }

    function findInStore(query?: Query): Ref<Result | undefined>[] {
      const q = query || ({} as Query)
      const siftQuery = prepareQueryForSift(q)
      const defaultSkip = (q?.$skip || 0) as number
      // @ts-ignore
      const defaultLimit = '$limit' in q ? defaultSkip + q?.$limit : undefined
      const items = _items.value.filter((item, index) => {
        return sift(siftQuery)(item.value, index)
      })

      if ('$sort' in q) {
        const field = Object.keys(q.$sort || {})[0]
        const sortType = q.$sort?.[field]

        items.sort((a, b) =>
          sortType === -1
            ? b.value[field] - a.value[field]
            : a.value[field] - b.value[field],
        )
      }

      return (
        items
          // @ts-ignore
          .slice(defaultSkip, defaultLimit) as Ref<Result | undefined>[]
      )
    }

    function getFromStore(
      id: string | null,
      query?: FeathersParams<Query>['query'],
    ): Ref<Result | undefined> {
      const q = query || ({} as Query)
      const siftQuery = prepareQueryForSift(q)

      if (id) {
        return ([
          _items.value.find((item) => {
            return (item as unknown as Ref<R>).value[idField] === id
          }),
        ].filter((item, index) => {
          return sift(siftQuery)(item?.value, index)
        })[0] || ref()) as Ref<Result | undefined>
      }

      return query
        ? ((_items.value.filter((item, index) => {
            return sift(siftQuery)(item.value, index)
          })[0] || ref()) as Ref<Result | undefined>)
        : ref(undefined)
    }

    function removeFromStore(
      id: string | null,
      query?: FeathersParams<Query>['query'],
    ) {
      const remove = (id: string) => {
        const index = _items.value.findIndex(
          (item) => item.value[idField] === id,
        )

        if (index < 0) return

        _items.value.splice(index, 1)
      }

      if (id && id in _itemsById.value) {
        remove(id)

        return
      }

      const q = query || ({} as Query)
      const siftQuery = prepareQueryForSift(q)

      if (query) {
        const result = _items.value.filter((item, index) => {
          return sift(siftQuery)(item.value, index)
        })

        result.forEach((item) => remove(item.value[idField]))

        return
      }
    }

    function createNewItem() {
      return create(modelDefaults())
    }

    function createLocal(data?: Data) {
      return ref(
        cleanCopy({
          ...modelDefaults(),
          ...(data || {}),
        }),
      ) as Ref<Result>
    }

    function on(
      eventName: 'created' | 'removed',
      callback: (r: Result) => any,
    ) {
      return service.on(eventName, callback)
    }

    function removeListener(
      eventName: 'created' | 'removed',
      callback: (r: Result) => any,
    ) {
      return service.removeListener(eventName, callback)
    }

    return {
      Result,
      Data,
      PatchData,
      Query,
      _items,
      itemsById,
      find,
      get,
      create,
      patch,
      remove,
      findInStore,
      getFromStore,
      removeFromStore,
      createNewItem,
      createLocal,
      on,
      removeListener,
    }
  })
}

export type ServiceStore = ReturnType<ReturnType<typeof useServiceStoreFactory>>
