import type { FeathersService } from '@feathersjs/feathers'
import { nanoid } from 'nanoid'
import type {
  ClientProducts,
  ClientProductsData,
  ClientProductsPatch,
  ClientProductsQuery,
} from '../types/typebox/products.schema'
import type {
  ClientApps,
  ClientAppsData,
  ClientAppsPatch,
  ClientAppsQuery,
} from '../types/typebox/apps.schema'
import type {
  ClientUsers,
  ClientUsersData,
  ClientUsersPatch,
  ClientUsersQuery,
} from '../types/typebox/users.schema'
import type {
  ClientStorefronts,
  ClientStorefrontsData,
  ClientStorefrontsPatch,
  ClientStorefrontsQuery,
} from '../types/typebox/storefronts.schema'
import type {
  ClientGoods,
  ClientGoodsData,
  ClientGoodsPatch,
  ClientGoodsQuery,
} from '../types/typebox/goods.schema'
import type {
  ClientTransactions,
  ClientTransactionsData,
  ClientTransactionsPatch,
  ClientTransactionsQuery,
} from '../types/typebox/transactions.schema'
import type {
  ClientStockTransactions,
  ClientStockTransactionsData,
  ClientStockTransactionsPatch,
  ClientStockTransactionsQuery,
} from '../types/typebox/stock-transactions.schema'
import type {
  ClientMenuItems,
  ClientMenuItemsData,
  ClientMenuItemsPatch,
  ClientMenuItemsQuery,
} from '../types/typebox/menu-items.schema'
import type {
  ClientOrders,
  ClientOrdersData,
  ClientOrdersPatch,
  ClientOrdersQuery,
} from '../types/typebox/orders.schema'
import type {
  ClientStorefrontPlaces,
  ClientStorefrontPlacesData,
  ClientStorefrontPlacesPatch,
  ClientStorefrontPlacesQuery,
} from '../types/typebox/storefront-places.schema'
import type {
  ClientMenus,
  ClientMenusData,
  ClientMenusPatch,
  ClientMenusQuery,
} from '../types/typebox/menus.schema'
import type {
  ClientShifts,
  ClientShiftsData,
  ClientShiftsPatch,
  ClientShiftsQuery,
} from '../types/typebox/shifts.schema'
import type {
  ClientPromotions,
  ClientPromotionsData,
  ClientPromotionsPatch,
  ClientPromotionsQuery,
} from '../types/typebox/promotions.schema'
import type {
  ClientProcurementOrders,
  ClientProcurementOrdersData,
  ClientProcurementOrdersPatch,
  ClientProcurementOrdersQuery,
} from '../types/typebox/procurement-orders.schema'
import {
  useServiceStoreFactory,
  type FeathersQuery,
} from './useServiceStoreFactory'

export type ServiceQueries = {
  products: Omit<ClientProductsQuery, '$select' | '$limit' | '$skip'>
  apps: Omit<ClientAppsQuery, '$select' | '$limit' | '$skip'>
  users: Omit<ClientUsersQuery, '$select' | '$limit' | '$skip'>
  storefronts: Omit<ClientStorefrontsQuery, '$select' | '$limit' | '$skip'>
}

export default function useFeathersStore<
  Result,
  Data,
  PatchData,
  Query extends FeathersQuery,
>({
  service,
  serviceName,
  idField,
  modelDefaults,
}: {
  service: FeathersService
  serviceName:
    | 'products'
    | 'apps'
    | 'users'
    | 'storefronts'
    | 'goods'
    | 'transactions'
    | 'stock-transactions'
    | 'storefront-places'
    | 'menu-items'
    | 'orders'
    | 'menus'
    | 'transactions'
    | 'shifts'
    | 'promotions'
    | 'procurement-orders'
  idField: '_id'
  modelDefaults: () => Data
}) {
  const storeParams: {
    name: string
  } = {
    name: serviceName,
  }

  const useStore = useServiceStoreFactory<Result, Data, PatchData, Query>(
    service,
    storeParams.name,
    idField,
    modelDefaults,
  )

  return useStore()
}

export const serviceStores = (api: any) => {
  return {
    products: useFeathersStore<
      ClientProducts,
      ClientProductsData,
      ClientProductsPatch,
      ClientProductsQuery
    >({
      service: api.service('products'),
      serviceName: 'products',
      idField: '_id',
      modelDefaults: () => {
        return {
          name: 'New Product',
          unitOfMeasure: 'gr',
          normalStockLevel: 0,
          averageExpirationTime: 0,
          isAllergic: false,
        }
      },
    }),
    apps: useFeathersStore<
      ClientApps,
      ClientAppsData,
      ClientAppsPatch,
      ClientAppsQuery
    >({
      service: api.service('apps'),
      serviceName: 'apps',
      idField: '_id',
      modelDefaults: () => {
        return {
          name: 'New App',
          taxes: [],
          menuLanguages: [
            {
              _id: nanoid(),
              code: 'en-gb',
              name: 'English',
              fallback: true,
            },
          ],
          currency: {
            sign: '€',
            code: 'euro',
            name: 'euro',
          },
        }
      },
    }),
    users: useFeathersStore<
      ClientUsers,
      ClientUsersData,
      ClientUsersPatch,
      ClientUsersQuery
    >({
      service: api.service('users'),
      serviceName: 'users',
      idField: '_id',
      modelDefaults: () => {
        return {
          email: '',
          password: '',
          status: 'public',
          profile: {
            useShifts: false,
            role: 'waiter',
            firstName: '',
            lastName: '',
          },
          permissions: {
            type: 'customer',
            appIds: [],
          },
        }
      },
    }),
    storefronts: useFeathersStore<
      ClientStorefronts,
      ClientStorefrontsData,
      ClientStorefrontsPatch,
      ClientStorefrontsQuery
    >({
      service: api.service('storefronts'),
      serviceName: 'storefronts',
      idField: '_id',
      modelDefaults: () => {
        return {
          name: 'New Storefront',
          inHouseService: {
            enabled: false,
            menuIds: [],
            fees: [],
            storefrontPlaceIds: [],
            setup: {
              batches: false,
              map: false,
              tabs: false,
              cashier: false,
              shifts: false,
            },
            unitMapping: {
              categories: {},
              menuItems: {},
            },
            storefrontPlaceAreas: [],
          },
          inHouseDelivery: {
            enabled: false,
            menuIds: [],
            fees: [],
            storefrontPlaceIds: [],
            map: false,
            unitMapping: {
              categories: {},
              menuItems: {},
            },
            storefrontPlaceAreas: [],
          },
          delivery: {
            enabled: false,
            menuIds: [],
            fees: [],
            map: null,
            unitMapping: {
              categories: {},
              menuItems: {},
            },
          },
          pickup: {
            enabled: false,
            menuIds: [],
            fees: [],
            unitMapping: {
              categories: {},
              menuItems: {},
            },
          },
          units: [],
        }
      },
    }),
    goods: useFeathersStore<
      ClientGoods,
      ClientGoodsData,
      ClientGoodsPatch,
      ClientGoodsQuery
    >({
      service: api.service('goods'),
      serviceName: 'goods',
      idField: '_id',
      modelDefaults: () => {
        return {
          exitAmount: 0,
          name: '',
          averageExpirationTime: 0,
          unitOfMeasure: 'gr',
          isAllergic: false,
          products: [],
          normalStockLevel: 0,
        }
      },
    }),
    transactions: useFeathersStore<
      ClientTransactions,
      ClientTransactionsData,
      ClientTransactionsPatch,
      ClientTransactionsQuery
    >({
      service: api.service('transactions'),
      serviceName: 'transactions',
      idField: '_id',
      modelDefaults: () => {
        return {
          storefrontId: '',
          paid: false,
          orderId: '',
          items: [],
          direction: 'incoming',
          type: 'card',
          currency: 'EUR',
        }
      },
    }),
    stockTransactions: useFeathersStore<
      ClientStockTransactions,
      ClientStockTransactionsData,
      ClientStockTransactionsPatch,
      ClientStockTransactionsQuery
    >({
      service: api.service('stock-transactions'),
      serviceName: 'stock-transactions',
      idField: '_id',
      modelDefaults: () => {
        return {
          storefrontId: '',
          type: 'write-off',
          products: {},
        }
      },
    }),
    menuItems: useFeathersStore<
      ClientMenuItems,
      ClientMenuItemsData,
      ClientMenuItemsPatch,
      ClientMenuItemsQuery
    >({
      service: api.service('menu-items'),
      serviceName: 'menu-items',
      idField: '_id',
      modelDefaults: () => {
        return {
          internalName: '',
          storefrontId: '',
          taxId: '',
          status: 'draft',
          name: [],
          description: [],
          products: [],
          variations: [],
          unitOfMeasure: 'gr',
          amount: 0,
          extras: [],
          consumerPrice: 0,
          cookingTime: 0,
        }
      },
    }),
    orders: useFeathersStore<
      ClientOrders,
      ClientOrdersData,
      ClientOrdersPatch,
      ClientOrdersQuery
    >({
      service: api.service('orders'),
      serviceName: 'orders',
      idField: '_id',
      modelDefaults: () => {
        return {
          staff: [],
          status: {
            name: 'created',
            createdAt: new Date().getTime(),
          },
          storefrontId: '',
          menuItems: [],
          batches: [],
          publicId: '',
          tabName: '',
          fees: [],
          promotionIds: [],
          type: 'inHouseService',
          storefrontPlaceIds: [],
        }
      },
    }),
    storefrontPlaces: useFeathersStore<
      ClientStorefrontPlaces,
      ClientStorefrontPlacesData,
      ClientStorefrontPlacesPatch,
      ClientStorefrontPlacesQuery
    >({
      service: api.service('storefront-places'),
      serviceName: 'storefront-places',
      idField: '_id',
      modelDefaults: () => {
        return {
          _id: nanoid(),
          storefrontId: '',
          storefrontAreaId: '',
          enabled: true,
          orderType: 'inHouseService',
          coords: [],
          config: {
            type: 'table',
            name: '1',
            round: false,
            shape: 'rectangle',
            seating: [1, 2, 1, 2],
            rotation: 0,
          },
        }
      },
    }),
    menus: useFeathersStore<
      ClientMenus,
      ClientMenusData,
      ClientMenusPatch,
      ClientMenusQuery
    >({
      service: api.service('menus'),
      serviceName: 'menus',
      idField: '_id',
      modelDefaults: () => {
        return {
          status: 'hidden',
          name: [],
          categories: [],
          menuItems: [],
        }
      },
    }),
    promotions: useFeathersStore<
      ClientPromotions,
      ClientPromotionsData,
      ClientPromotionsPatch,
      ClientPromotionsQuery
    >({
      service: api.service('promotions'),
      serviceName: 'promotions',
      idField: '_id',
      modelDefaults: () => {
        return {
          status: 'draft',
          name: '',
          description: '',
          storefrontId: '',
          startDate: new Date().getTime(),
          endDate: new Date().getTime(),
          orderType: 'inHouseService',
          combinable: true,
          activation: [],
          discount: {
            type: 'percentage',
            value: 0,
          },
        }
      },
    }),
    shifts: useFeathersStore<
      ClientShifts,
      ClientShiftsData,
      ClientShiftsPatch,
      ClientShiftsQuery
    >({
      service: api.service('shifts'),
      serviceName: 'shifts',
      idField: '_id',
      modelDefaults: () => {
        return {
          storefrontId: '',
          startsAt: new Date().getTime(),
          endsAt: new Date().getTime(),
          assignedStaffIds: [],
          staffLogs: {},
        }
      },
    }),
    procurementOrders: useFeathersStore<
      ClientProcurementOrders,
      ClientProcurementOrdersData,
      ClientProcurementOrdersPatch,
      ClientProcurementOrdersQuery
    >({
      service: api.service('procurement-orders'),
      serviceName: 'procurement-orders',
      idField: '_id',
      modelDefaults: () => {
        return {
          createdAt: new Date().getTime(),
          status: 'backlog',
          storefrontId: '',
          order: {},
          entry: {},
        }
      },
    }),
  }
}

export type ServiceStores = ReturnType<typeof serviceStores>
export type ServiceStore = ServiceStores[keyof ServiceStores]
