<template>
  <transition
    mode="out-in"
    :enter-active-class="`transition ease-in-out ${props.duration}`"
    :enter-from-class="`transform opacity-0 ${props.translateY || ''}`"
    enter-to-class="transform opacity-100"
    :leave-active-class="`transition ease-in-out ${props.duration}`"
    leave-from-class="transform opacity-100"
    :leave-to-class="`transform opacity-0 ${props.translateY || ''}`"
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
const props = defineProps({
  duration: {
    type: String,
    default: 'duration-100',
  },
  translateY: {
    type: String,
    default: '',
  },
})
</script>
