<template>
  <ion-modal
    :is-open="props.isOpen"
    :backdrop-dismiss="false"
    :style="{
      '--height': 'auto',
      '--background': 'var(--rf-bg-elevation-2)',
      'align-items': 'flex-end',
    }"
  >
    <AppStack direction="flex-col" class="pt-6 px-4 safe-bottom gap-5">
      <AppStack direction="flex-col" class="gap-2">
        <AppTypography
          :text="heading"
          name="heading-2xl-7-bold"
          color="text-fg-elevation-2-primary"
        />
        <AppTypography
          :text="text"
          name="text-md-4-medium"
          color="text-fg-elevation-2-secondary"
        />
      </AppStack>
      <AppStack direction="flex-col" class="gap-2 safe-bottom-max">
        <AppButton
          v-if="shift"
          :loading="isStartingShift"
          text="Start shift"
          @click="onStartShiftClick"
        />
        <AppButton
          variant="secondary"
          text="Go to Account"
          @click="onGoToAccountClick"
        />
      </AppStack>
    </AppStack>
  </ion-modal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useIonRouter, IonModal } from '@ionic/vue'
import { format as DateFnsFormat, sub as DateFnsSub } from 'date-fns'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppButton from '~/components/AppButton.vue'
import { useAuthStore } from '~/stores/auth'
import { useShiftsLogic } from '@restify/packages/composables/useShiftsLogic'
import useStores from '~/composables/useStores'

const emit = defineEmits(['update:isOpen'])
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
})

const { shifts: ShiftsStore } = useStores()
const route = useRoute()
const router = useIonRouter()
const AuthStore = useAuthStore()
const { shiftIsHappening, shift } = useShiftsLogic(AuthStore)

const isStartingShift = ref(false)

const heading = computed(
  () => `Hey, ${AuthStore.reactiveUser?.profile.firstName}`,
)

const text = computed(() => {
  if (!shift.value) return 'No shift avaliable'

  const hasStarted = new Date(shift.value.startsAt) < new Date()
  const startTime = DateFnsFormat(new Date(shift.value.startsAt), 'HH:mm')
  const startDate = DateFnsFormat(new Date(shift.value.startsAt), 'do LLLL')
  const endTime = DateFnsFormat(new Date(shift.value.endsAt), 'HH:mm')
  const endDate = DateFnsFormat(new Date(shift.value.endsAt), 'do LLLL')
  const activationTime = DateFnsFormat(
    DateFnsSub(new Date(shift.value.startsAt), { hours: 1 }),
    'HH:mm',
  )

  if (shiftIsHappening.value) {
    return hasStarted
      ? `Your shift was scheduled to start on ${startTime} until ${endTime}, ${endDate}.`
      : `Your shift is about to start on ${startDate} ${startTime}.`
  }

  return `Your next shift will start at ${startDate} ${startTime}. Activate it in the app starting from ${activationTime}.`
})

const onGoToAccountClick = () => {
  emit('update:isOpen')
  route.name !== 'user' && router.navigate({ name: 'user' }, 'none', 'replace')
}

const onStartShiftClick = () => {
  if (!shift.value || !AuthStore.reactiveUser || !shiftIsHappening.value) return

  isStartingShift.value = true

  return ShiftsStore.patch(shift.value._id, {
    $set: {
      [`staffLogs.${AuthStore.reactiveUser._id}`]: {
        staffId: AuthStore.reactiveUser._id,
        startedAt: new Date().getTime(),
      },
    },
  })
    .then(() => emit('update:isOpen'))
    .finally(() => {
      isStartingShift.value = false
    })
}
</script>
