export function round(num: number, decimalPlaces = 0) {
  const p = Math.pow(10, decimalPlaces)
  const n = num * p * (1 + Number.EPSILON)

  return Math.round(n) / p
}

export const roundValue = (value = 0, count = 2) => {
  return Math.round(value * Math.pow(10, count)) / Math.pow(10, count)
}

export const roundDownValue = (
  value: number,
  decimalPlaces: number = 2,
): number => {
  const factor = Math.pow(10, decimalPlaces)

  return Math.floor(value * factor) / factor
}

export const formatPrice = (
  value?: number | string,
  currencySign?: string,
  includeSigned = true,
) => {
  const sign = includeSigned ? ` ${currencySign}` : ''

  if (value === undefined) {
    return `?${sign}`
  } else if (typeof value === 'number') {
    return `${value.toFixed(2)}${sign}`
  } else {
    return `${value}${sign}`
  }
}
