import LodashOmit from 'lodash.omit'
import { diff as DeepObjectDiff } from 'deep-object-diff'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cleanCopy = <T>(object: T): T => {
  return JSON.parse(JSON.stringify(object))
}

export const omit = LodashOmit

export const traverse = (obj: any) => {
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        // loop through array
        for (let i = 0; i < obj[key].length; i++) {
          traverse(obj[key][i])
        }
      } else {
        // call function recursively for object
        traverse(obj[key])
      }
    } else {
      // do something with value
      console.log(key + ': ' + obj[key])
    }
  }
}

export const flattenObject = (obj: any) => {
  let toReturn: any = {}

  for (var i in obj) {
    if (i in obj === false) continue

    if (typeof obj[i] == 'object' && obj[i] !== null) {
      let flatObject = flattenObject(obj[i])

      for (let x in flatObject) {
        if (x in flatObject === false) continue

        toReturn[i + '.' + x] = flatObject[x]
      }
    } else {
      toReturn[i] = obj[i]
    }
  }

  return toReturn
}

export const diff = (originalObj: object, updatedObj: object): object => {
  const difference = DeepObjectDiff(originalObj, updatedObj)
  const flatDiff = flattenObject(difference)

  return Object.keys(flatDiff).reduce((acc: any, key: string) => {
    const newKey = key
      .split('.')
      .map((part: string, index, array) => {
        const prevPart = array[index - 1]

        return !isNaN(parseInt(part))
          ? `[${part}]`
          : `${!prevPart ? '' : '.'}${part}`
      })
      .join('')
    acc[newKey] = flatDiff[key]

    return acc
  }, {})
}

export const topLevelDiff = (
  originalObj: object,
  updatedObj: object,
): object => {
  const fullDiff = DeepObjectDiff(originalObj, updatedObj)

  return Object.keys(fullDiff).reduce((acc: any, path: string) => {
    const pathBeforeObject = path.split('.')[0] as keyof typeof updatedObj

    acc[pathBeforeObject] = updatedObj[pathBeforeObject]

    return acc
  }, {})
}
