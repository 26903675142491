import { ref, computed, onMounted, inject, ComputedRef } from 'vue'

export type Router = {
  push: (component: unknown, componentProps: unknown) => unknown
  pop: () => unknown
}

export const useIonicNavigation = (navId: string) => {
  const injectedNavRef = inject<ComputedRef<Router>>('navRef')
  const navRef = ref<HTMLElement | null>(null)

  onMounted(() => {
    if (injectedNavRef?.value) return

    navRef.value = document.getElementById(navId)
  })

  const navigatior = computed<Router>(() => {
    return (injectedNavRef?.value || navRef.value) as Router
  })

  const push = (component: unknown, componentProps?: unknown) => {
    if (!navigatior.value) return

    return navigatior.value.push(component, componentProps)
  }

  const pop = () => {
    if (!navigatior.value) return

    return navigatior.value.pop()
  }

  return {
    navRef,
    push,
    pop,
  }
}
