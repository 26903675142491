// @ts-nocheck

import { computed } from 'vue'
import { sub as DateFnsSub, add as DateFnsAdd } from 'date-fns'
import useStores from '~/composables/useStores'

export const useShiftsLogic = (AuthStore) => {
  const { shifts: ShiftsStore } = useStores()

  const shift = computed(() => {
    if (!AuthStore.reactiveUser) return null

    const shift = ShiftsStore.findInStore({
      $or: [
        {
          startsAt: {
            $lte: new Date().getTime(),
          },
          endsAt: {
            $gte: new Date().getTime(),
          },
        },
        {
          startsAt: {
            $gte: new Date().getTime(),
          },
        },
      ],
      $and: [
        { [`staffLogs.${AuthStore.reactiveUser._id}.endedAt`]: null },
        { assignedStaffIds: AuthStore.reactiveUser._id },
      ],
      $sort: { startsAt: 1 },
    })[0]

    return shift?.value
  })

  const shiftIsHappening = computed(() => {
    if (!shiftsSupported.value || !shift.value || !AuthStore.reactiveUser)
      return

    const startTime = DateFnsSub(new Date(shift.value.startsAt), { hours: 1 })
    const endTime = DateFnsAdd(new Date(shift.value.endsAt), { hours: 1 })

    return (
      shift.value.assignedStaffIds.includes(AuthStore.reactiveUser._id) &&
      !shift.value.staffLogs?.[AuthStore.reactiveUser._id]?.endedAt &&
      startTime <= new Date() &&
      endTime >= new Date()
    )
  })

  const shiftShouldBeStarted = computed(() => {
    if (!shift.value || !AuthStore.reactiveUser || !shift.value.staffLogs)
      return false

    return (
      shift.value.assignedStaffIds.includes(AuthStore.reactiveUser._id) &&
      AuthStore.reactiveUser._id in shift.value.staffLogs === false
    )
  })

  const shiftIsMissing = computed(() => {
    if (!shift.value || !AuthStore.reactiveUser || !shift.value.staffLogs)
      return false

    return !shift.value.assignedStaffIds.includes(AuthStore.reactiveUser._id)
  })

  const shiftShouldBeEnded = computed(() => {
    if (!shift.value || !AuthStore.reactiveUser || !shift.value.staffLogs)
      return false

    return (
      shift.value.assignedStaffIds.includes(AuthStore.reactiveUser._id) &&
      AuthStore.reactiveUser._id in shift.value.staffLogs &&
      shift.value.staffLogs[AuthStore.reactiveUser._id]?.startedAt &&
      !shift.value.staffLogs[AuthStore.reactiveUser._id]?.endedAt &&
      new Date(shift.value.startsAt).getTime() < new Date().getTime()
    )
  })

  const shiftsSupported = computed(() => {
    const profile = AuthStore.reactiveUser?.profile
    const correctRole =
      profile?.role &&
      (profile.role === 'cook' ||
        profile.role === 'barmen' ||
        profile.role === 'waiter' ||
        profile.role === 'deliveryman')

    if (!correctRole) return

    return correctRole && profile.useShifts
  })

  return {
    shiftsSupported,
    shiftIsMissing,
    shiftShouldBeStarted,
    shiftShouldBeEnded,
    shiftIsHappening,
    shift,
  }
}
