<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :style="rootStyles"
    :class="rootClasses"
    class="inline-block align-middle"
  >
    <use :xlink:href="href" :href="href" />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import icons from '../icons/icons.svg'

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: '',
  },
  size: {
    type: [String, Number],
    default: 'custom',
  },
  strokeWidth: {
    type: [String, Number],
    default: '',
  },
})

const rootStyles = computed(() => {
  return {
    custom: { strokeWidth: props.strokeWidth },
    36: { strokeWidth: '1.8px' },
    28: { strokeWidth: '1.8px' },
    24: { strokeWidth: '1.8px' },
    20: { strokeWidth: '1.4px' },
    18: { strokeWidth: '1px' },
  }[props.size]
})

const rootClasses = computed(() => {
  return [
    props.color,
    {
      custom: [],
      36: ['w-[36px] h-[36px]'],
      28: ['w-[28px] h-[28px]'],
      24: ['w-[24px] h-[24px]'],
      20: ['w-[20px] h-[20px]'],
      18: ['w-[18px] h-[18px]'],
    }[props.size],
  ]
})

const href = computed(() => `${icons}#${props.icon}`)
</script>
