import { createPinia, setActivePinia } from 'pinia'
import useFeathers from '~/composables/useFeathers'

export default () => {
  const feathers = useFeathers()
  const pinia = createPinia()
  setActivePinia(pinia)

  return { provide: { pinia, feathers, api: feathers } }
}
