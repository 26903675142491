import {
  roundValue as HelpersNumberRoundValue,
  formatPrice as HelpersNumberFormatPrice,
} from '../helpers/number'
import { type ServiceStores } from '../composables/useStore'

export const translateField = (
  app: ServiceStores['apps']['Result'],
  field?: {
    [key: string]: any
    menuLanguageId: string
  }[],
  fallback?: ServiceStores['apps']['Result']['menuLanguages'][number],
) => {
  const menulanguages = app.menuLanguages || []
  const fallbackLanguage =
    fallback || menulanguages.find((language) => language.fallback)

  if (fallback) {
    const translatedItemWithPassedFallback = field?.find(
      (item) => item.menuLanguageId === fallback?._id,
    )

    if (translatedItemWithPassedFallback)
      return translatedItemWithPassedFallback.text
  }

  const translatedItemWithFallback = field?.find(
    (item) => item.menuLanguageId === fallbackLanguage?._id,
  )

  return translatedItemWithFallback
    ? translatedItemWithFallback.text
    : field?.[0]?.text || 'No text'
}

export const countMenuItemTotal = (
  menuItem: ServiceStores['orders']['Result']['menuItems'][number],
) => {
  const variationsTotal = menuItem.variations.reduce((total, variation) => {
    total = total + variation.price

    return total
  }, 0)

  const extrasTotal = menuItem.extras.reduce((total, extra) => {
    total = total + extra.price

    return total
  }, 0)

  const itemTotalPrice = HelpersNumberRoundValue(
    menuItem.price + variationsTotal + extrasTotal,
  )

  return itemTotalPrice
}

export const countPromotionTotal = (
  order: ServiceStores['orders']['Result'],
) => {
  return Object.values(order.appliedPromotions.promotions || {}).reduce(
    (acc, appliedPromotion) => {
      acc = HelpersNumberRoundValue(acc + appliedPromotion.discount || 0)

      return acc
    },
    0,
  )
}

export const mapMenuItem = (
  menuItem: ServiceStores['orders']['Result']['menuItems'][number],
  app: ServiceStores['apps']['Result'],
  options?: {
    short?: Boolean
  },
) => {
  const itemTotalPrice = countMenuItemTotal(menuItem)

  return {
    menuItem: menuItem,
    _id: menuItem._id,
    title: `${menuItem?.quantity} x ${translateField(app, menuItem?.name)}`,
    subtitle: HelpersNumberFormatPrice(itemTotalPrice, app.currency.sign),
    comment: menuItem.comment || '',
    variations: menuItem?.variations.map((variation) =>
      options?.short
        ? translateField(app, variation.optionName)
        : `${translateField(app, variation.name)}: ${translateField(app, variation.optionName)}`,
    ),
    extras: menuItem?.extras?.map((extra) =>
      options?.short
        ? translateField(app, extra.optionName)
        : `${translateField(app, extra.name)}: ${translateField(app, extra.optionName)}`,
    ),
    removables: menuItem?.removables.map((removable) =>
      translateField(app, removable.name),
    ),
    status: menuItem?.status.name,
  }
}
