<template>
  <ion-header>
    <ion-toolbar :style="contentStyles">
      <ion-buttons slot="start">
        <ion-back-button />
      </ion-buttons>
      <ion-title>Controls</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content ref="scrollview" :fullscreen="true" :style="contentStyles">
    <AppStack direction="flex-col" flex="flex-1" class="min-h-full pt-[10px]">
      <AppStack
        v-if="loading"
        key="loading"
        align-items="items-center"
        justify-content="justify-center"
        flex="flex-1"
      >
        <ion-spinner
          :style="{ '--color': 'var(--rf-fg-elevation-0-primary)' }"
        />
      </AppStack>
      <AppStack
        v-else-if="order && orderMenuItem && menuItem"
        direction="flex-col pt-[10px]"
      >
        <AppStack
          v-for="(group, key) in accountNavigation"
          :key="key"
          direction="flex-col gap-1"
        >
          <AppStack class="px-4 bg-bg-elevation-0 pt-[6px] pb-[6px]">
            <AppTypography
              :text="key"
              name="text-xs-4-semibold"
              color="text-fg-elevation-1-ios-secondary"
              class="uppercase"
            />
          </AppStack>
          <ion-list inset class="!my-0">
            <AppPageViewListItem
              v-for="(item, index) in group"
              :key="key + index"
              :title="item.title"
              :caption="item.caption"
              :to="item.to"
              :button="item.button"
              :accent="item.accent || null"
              elevation="1"
              @click="item?.onClick"
            />
          </ion-list>
          <div class="pt-4" />
        </AppStack>
      </AppStack>
      <AppStack
        v-else
        align-items="items-center"
        justify-content="justify-center"
        flex="flex-1"
      >
        <AppTypography
          text="No order or menu-item loaded"
          name="text-sm-4-medium"
          color="text-fg-elevation-1-ios-secondary"
        />
      </AppStack>
    </AppStack>
  </ion-content>
</template>

<script lang="ts">
export default { name: 'OrderModalMenuItemControls' }
</script>

<script setup lang="ts">
import {
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonList,
  IonSpinner,
  toastController,
} from '@ionic/vue'
import { ref, computed, inject, ComputedRef, onMounted } from 'vue'
import { useBottomScroll } from '@restify/packages/composables/useBottomScroll'
import { useEventBus } from '@vueuse/core'
import AppIcon from '@restify/packages/design-system/low-level/AppIcon.vue'
import AppStack from '@restify/packages/design-system/low-level/AppStack.vue'
import AppTypography from '@restify/packages/design-system/low-level/AppTypography.vue'
import AppPageViewListItem from '~/components/AppPageViewListItem.vue'
import { useIonicNavigation } from '@restify/packages/composables/useIonicNavigation'
import useStores from '~/composables/useStores'

const { pop, push } = useIonicNavigation('order-modal-nav')
const bus = useEventBus<
  'order-modal-dismiss' | 'order-modal-update' | 'app-visible',
  unknown
>('app')

const injectedOrderId = inject<ComputedRef<string>>('orderId')

const props = defineProps({
  id: {
    type: String,
    default: null,
  },
  menuItemId: {
    type: String,
    default: null,
  },
})

const { orders: OrdersStore, menuItems: MenuItemsStore } = useStores()

const loading = ref(false)
const scrollview = ref(null)
const scrollviewContent = ref(null)

useBottomScroll(scrollview, scrollviewContent)

const contentStyles = computed(() => ({
  '--background': 'var(--rf-bg-elevation-0)',
  '--border-color': 'var(--rf-border-elevation-0-primary)',
}))

const order = computed(() => {
  if (!injectedOrderId?.value) return null

  return OrdersStore.getFromStore(injectedOrderId.value).value
})

const orderMenuItem = computed(() => {
  if (!order.value) return

  return order.value?.menuItems.find((item) => item._id === props.id)
})

const menuItem = computed(() => {
  if (!props.menuItemId) return

  return MenuItemsStore.getFromStore(props.menuItemId).value
})

const accountNavigation = computed(() => {
  if (!menuItem.value || !orderMenuItem.value) return {}

  const onClick = (status: string) => () => {
    OrdersStore.patch(
      order.value._id,
      {
        $set: {
          [`menuItems.$.status.name`]: status,
          [`menuItems.$.status.${status}At`]: Date.now(),
        },
      },
      {
        query: {
          'menuItems._id': orderMenuItem.value._id,
        },
      },
    )
      .then(() => pop())
      .catch(catchErrorWithToast)
  }

  const navigation = {
    Status: [
      ...(orderMenuItem.value.status.name === 'idle'
        ? [
            {
              button: false,
              title: 'Mark as "In Progress"',
              accent: 'blue',
              onClick: onClick('inProgress'),
            },
          ]
        : []),
      ...(orderMenuItem.value.status.name === 'inProgress'
        ? [
            {
              button: false,
              title: 'Mark as "Ready"',
              accent: 'blue',
              onClick: onClick('ready'),
            },
          ]
        : []),
      ...(orderMenuItem.value.status.name === 'ready'
        ? [
            {
              button: false,
              title: 'Mark as "Served"',
              accent: 'blue',
              onClick: onClick('served'),
            },
          ]
        : []),
      ...(orderMenuItem.value.status.name !== 'cancelled'
        ? [
            {
              button: false,
              title: 'Mark as "Cancelled"',
              accent: 'red',
              onClick: onClick('cancelled'),
            },
          ]
        : []),
    ],
    Actions: [
      {
        button: false,
        title: 'Remove',
        accent: 'red',
        onClick: () => {
          return OrdersStore.patch(order.value._id, {
            $pull: {
              [`menuItems`]: {
                _id: orderMenuItem.value._id,
              },
            },
          })
            .then(() => pop())
            .catch(catchErrorWithToast)
        },
      },
    ],
  }

  return Object.keys(navigation).reduce((acc, key) => {
    if (navigation[key].length) {
      acc[key] = navigation[key]
    }

    return acc
  }, {})
})

const catchErrorWithToast = async (error) => {
  const toast = await toastController.create({
    message: `Error: ${error.name}. ${error.message}`,
    duration: 3000,
    cssClass: 'toast text-lg-7-semibold',
    position: 'bottom',
    mode: 'ios',
  })

  await toast.present()
}

const getMenuItem = (silent = false) => {
  loading.value = silent ? false : true

  return MenuItemsStore.get(props.menuItemId).finally(
    () => (loading.value = false),
  )
}

bus.on((event) => {
  if (event === 'app-visible') {
    return getMenuItem(true)
  }
})

onMounted(() => {
  return getMenuItem()
})
</script>
