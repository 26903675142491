<template>
  <div
    :style="transitionStyles"
    :class="keyboardMoving ? ['view--moving'] : []"
    class="view"
  >
    <span
      class="pointer-events-none opacity-0 absolute top-[-10000px] left-[-10000px]"
      v-text="keyboardMoving"
    />
    <slot
      :keyboard-will-show="keyboardMoving"
      :keyboard-did-show="keyboardShown"
      :keyboard-height="keyboardHeight"
    />
  </div>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { ref, computed, onMounted, PropType } from 'vue'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'

const props = defineProps({
  position: {
    type: String as PropType<'bottom' | 'center'>,
    required: true,
  },
})

const keyboardWillShow = ref(false)
const keyboardWillHide = ref(false)
const keyboardMoving = ref(false)
const keyboardShown = ref(false)
const keyboardHeight = ref(0)

const onKeyboardWillShow = (info: KeyboardInfo) => {
  keyboardMoving.value = true
  keyboardWillShow.value = true
  keyboardHeight.value = info.keyboardHeight
  keyboardWillHide.value = false
}

const onKeyboardWillHide = () => {
  keyboardMoving.value = true
  keyboardWillHide.value = true
  keyboardWillShow.value = false
  keyboardHeight.value = 0
}

const onKeyboardShown = () => {
  keyboardShown.value = true
  keyboardMoving.value = false
}

const onKeyboardDidHide = () => {
  keyboardShown.value = false
  keyboardMoving.value = false
}

onMounted(() => {
  if (!Capacitor.isNativePlatform()) return

  Keyboard.addListener('keyboardWillShow', onKeyboardWillShow)
  Keyboard.addListener('keyboardWillHide', onKeyboardWillHide)
  Keyboard.addListener('keyboardDidShow', onKeyboardShown)
  Keyboard.addListener('keyboardDidHide', onKeyboardDidHide)
})

const transitionStyles = computed(() => {
  return {
    bottom: {
      transform: keyboardWillShow.value
        ? `translate3d(0px, ${-keyboardHeight.value}px, 0px)`
        : 'translate3d(0rem, 0rem, 0rem)',
    },
    center: {
      transform: keyboardWillShow.value
        ? `translate3d(0px, ${-keyboardHeight.value / 2}px, 0px)`
        : 'translate3d(0rem, 0rem, 0rem)',
    },
  }[String(props.position)]
})
</script>

<style scoped>
.view {
  will-change: transform;
  transition: transform 0.515s cubic-bezier(0.36, 0.71, 0.12, 1.01);

  &--moving {
    :deep() input,
    :deep() *[contenteditable] {
      caret-color: transparent;
    }
  }
}
</style>
