export default () => {
  document.body.setAttribute('ontouchstart', '')

  const onWindowResize = () => {
    document.documentElement.style.setProperty(
      '--viewport-height',
      `${window.innerHeight}px`,
    )
  }

  window.addEventListener('resize', onWindowResize)

  onWindowResize()
}
